import React,{ Component } from "react";

class GoodsActiveVendorList extends Component {

  state = {
    layoutName: "default",
    input: "",
    cardCode: "",
    cardName: 'NA',
    handlefindVendor: this.findVendor.bind(this, 'VendorFind')
  };

  findVendor(refName, e) {
    //alert(e.target.value.length);
    //if (event.charCode === 13) { for enter key
    const vendorName = e.target.value;
    if(vendorName.length >= 5){
              fetch('/api/v1/goodsreceived/activevendors/'+vendorName, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
               // console.log( data);
                //this.setState({ todoList: data })
                if(data.id > 0 ){
                  this.setState({ cardCode: data.CardCode })
                  this.setState({ cardName: data.CardName })
                //window.location.replace('/docdetails/'+data.id);
                }
                else{
                  this.errors = true;
                  this.setState({ cardCode: '' })
                  this.setState({ cardName: 'Invalid Document number!' })
                                 
                }
              }
              ).catch(error => {
                this.setState({ cardName: 'Invalid Document number!' })
            });  
    
      } //if
    
    };
     
    findPurchaseOrder = () => {

    //alert(this.state.cardCode);  
    if(this.state.cardCode !== ''){
      localStorage.setItem('VendorCardCode', this.state.cardCode);
      //alert(localStorage.getItem('VendorCardCode'));     
      window.location.replace('/goodsreceive');
    }
        }

  render() {
    
    return (

      <div>
        
            <div className="mb-4" >          
            <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[45]}</h1>
              <div className="mb-12 text-center jumbotron-icon">
                Card Code:&nbsp;<input type="text" name="CardCode" id="CardCode"
                onKeyUp= {this.state.handlefindVendor} ref={(VendorFind) => {VendorFind && VendorFind.focus() }} />    
                </div>  &nbsp;
                  <div className="mb-12 text-center jumbotron-icon">
                  Card Name:&nbsp; {this.state.cardName}     
                  </div>  &nbsp;
                  <div>
                <div className="mb-12 text-center jumbotron-icon">
                <button onClick={this.findPurchaseOrder}>
                { JSON.parse(sessionStorage.getItem('currentLang'))[44]}
                </button>
                </div>
                </div>
              </div>

             
       
      </div>

    );

  }

}


export default GoodsActiveVendorList;