import React, { useState } from "react";
//import Doctab from "./Doctab";
//import Doclist from "../../components/AllTabs/Doclist";

import Goodsreceiptlist from "../../../views/app/Goodsreceiptlist";
import GoodsreceiptMydoclist from "../../../components/AllTabs/GoodsReceipt/GoodsreceiptMydoclist";
import GoodsreceiptMydocSyncRuninglist from "../../../components/AllTabs/GoodsReceipt/GoodsreceiptMydocSyncRuninglist";
import GoodsreceiptMyFailedSyncList from "../../../components/AllTabs/GoodsReceipt/GoodsreceiptMyFailedSyncList";
import GoodsreceiptMySyncList from "../../../components/AllTabs/GoodsReceipt/GoodsreceiptMySyncList";

const Goodsreceipttabs = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  //const handleClick = (index) => setActiveIndex(index);
  const checkActiveGoodsreceipt = (index, className) => activeIndex === index ? className : "";



  const handleClickGoodsreceipt = (index) => {    
     setActiveIndex(index);
     //alert(index);
     //console.log(localStorage.getItem('token'));
     //const doctab = {
      //tabId: index
    //};
    

   };


  return (
    
    <>
      <div className="tabs">

        <button
          className={`tab ${checkActiveGoodsreceipt(1, "active")}`}
          onClick={() => handleClickGoodsreceipt(1)}
        >
          <i className="fa fa-edit" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActiveGoodsreceipt(2, "active")}`}
          onClick={() => handleClickGoodsreceipt(2)}
        >
          <i className="fa fa-user-o" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActiveGoodsreceipt(3, "active")}`}
          onClick={() => handleClickGoodsreceipt(3)}
        >
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActiveGoodsreceipt(4, "active")}`}
          onClick={() => handleClickGoodsreceipt(4)}
        >
          <i className="fa fa-warning" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActiveGoodsreceipt(5, "active")}`}
          onClick={() => handleClickGoodsreceipt(5)}
        >
          <i className="fa fa-check" aria-hidden="true"></i>
        </button>
      </div>
      <div className="panels">
        <div className={`panel ${checkActiveGoodsreceipt(1, "active")}`}>       
        <Goodsreceiptlist> </Goodsreceiptlist>    
        </div>
        <div className={`panel ${checkActiveGoodsreceipt(2, "active")}`}>
        <GoodsreceiptMydoclist> </GoodsreceiptMydoclist>
        </div>
        <div className={`panel ${checkActiveGoodsreceipt(3, "active")}`}>
        <GoodsreceiptMydocSyncRuninglist> </GoodsreceiptMydocSyncRuninglist>
        </div>
        <div className={`panel ${checkActiveGoodsreceipt(4, "active")}`}>
        <GoodsreceiptMyFailedSyncList> </GoodsreceiptMyFailedSyncList>
        </div>
        <div className={`panel ${checkActiveGoodsreceipt(5, "active")}`}>
        <GoodsreceiptMySyncList> </GoodsreceiptMySyncList>
        </div>

       
      </div>
    </>
  );
};

export default Goodsreceipttabs;