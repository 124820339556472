import React,{ Component } from "react";
import { Link  } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
//import NumericInput from 'react-numeric-input';
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";
//import IncDecCounter from "../../components/IncDecCounter";

import Button from 'react-bootstrap/Button';
//import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

import BarcodeScannerComponent from "react-qr-barcode-scanner";
  
const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"green"
};

const scanned_pending = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"rgb(132, 126, 126)",
  color:"#ffffff"
};
//const pageFrom = this.props.match.params.callingfrom; 
//const OptionId = this.props.match.params.id;

class WareHouse extends Component {

  state = {
    items: [],
    Fixeditems: [],
    itemsShow:{ display: 'block' },
    filterItemsShow:{ display: 'none' },
    itemDet: [],
    spinner:false,
    hasQty: false,
    upScanQty: false,
    totalOrderQty: 0,
    qtyButton: JSON.parse(sessionStorage.getItem('currentLang'))[66],
    fullScrenButton: 'Full Screen',
    handlefindItem: this.findItem.bind(this, 'DocumentItemFind'),
    //handlefindItemInput:this.findItem.bind(this, 'DocumentItemFindInput'),
    total_scanned_item:0,
    scanned_ean_code:null,
    hasFullScrn: false,
    visibility: "block",
    layoutName: "default",
    input: "",
    input3:"",
    qtyInput:1,
    keyboardVisibility:false,
    keyboardVisibilityQty:false,
    keyboardVisibilityScanQty:false,
    itemSelectedPrevId:0,
    itemSelectedPrevQty:0,
    itemSelected:false,
    itemSelectedAvailQty:0,
    itemSelectedBgColor:'tapping_bg_color',
    modalShow:false,
    incnum: 0,
    selectedBinLocQty:[],
    searchedData:[],
    selectedDistNumbers:[],
    selectedActBinLoc:[],
    selectedBinData:[],
    selectedActDistNum:[],
    webcamResult:"Not Found",
    setStopStream:false,
    setPrevPage:"",
  };

  findWareHouse() {
   
    //if (event.charCode === 13) { for enter key
    const itemId = this.props.match.params.callingfrom;    
    const pageFrom = this.props.match.params.callingfrom;  
    
    this.setState({spinner: true});
    if(itemId.length > 0){
              fetch('/api/v1/fetchWareHouse', {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                  

                  let Binrows = [];
                  /*if(pageFrom === 'goodsreceive'){
                    Binrows.push(data[1]);
                  }
                  else{*/
                  for (let i = 0; i < data.length; i++) {
                      // note: we are adding a key prop here to allow react to uniquely identify each
                      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
                      if(String(pageFrom) === 'goodsreceiveFromWH' ){
                        if(data[i]['EnableBinLocations'] === 'tYES'){
                      Binrows.push(data[i]);
                        }                          
                      }
                      else if(String(pageFrom) === 'goodsreceive'){
                        //console.log(data[i]['WarehouseCode']);
                        Binrows= [{ id: 23, WarehouseCode: "02", WarehouseName: "Warenannahme Lager Berlin" }];
                        console.log(Binrows);
                          }
                      else{
                        Binrows.push(data[i]);
                      }
                  }
                //}
                  //console.log( Binrows);
                  this.setState({spinner: false});
                  //console.log( data.docItemList[0]);
                // console.log( data.docItemList.replace("/",""));
                //console.log( data.docItemList[0][0]);
                
                
                  this.setState({items: Binrows, Fixeditems:Binrows});
                 
                 

                  //const data2 = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
                //console.log( data.docItemList[0]['Items/ItemWarehouseInfoCollection']);
                //console.log( this.state.items[0]['Items/ItemWarehouseInfoCollection']);



                   //console.log( this.state.itemDet.documentslist_id)
                //this.setState({ docItem: data.document, items: data.docItemList, total_scanned_item: data.total_scanned_item });
               
                
              }
              ).catch(error => {
                
                console.log( error);
                this.setState({spinner: false});
            });
    
      } //if
    
    };

    

    handleSearch = event => {

      //console.log( event.target.value);

      
      //console.log( users);*/
      //console.log( this.state.selectedBinLocQty);
      //console.log( this.state.items);
      let serachTerm = event.target.value.toUpperCase();

      this.highlightItem(serachTerm);

    }; 

    highlightItem(serachTerm) {     
     
      //console.log( selectedBinLoc);
      //setSearchedData(data);
      //const data3 = users.filter(
        //user => user.name.indexOf(event.target.value) !== -1
      //); Fixeditems

      let allItems = this.state.Fixeditems;
  
    if (serachTerm === "") { this.setState({ items: this.state.Fixeditems }); return ; }

    var selectedBinLoc = allItems.filter((item) => {
  
      if (String(item.WarehouseCode).length >0){
        return item.WarehouseCode.toLowerCase().indexOf(serachTerm.toLowerCase()) > -1;
      } 
        //return filterTitle && filterDocNum && filterPolNumber && filterPolskyOpis;
      });

      if(selectedBinLoc.length===0){
        selectedBinLoc = allItems.filter((item) => {
          if (String(item.WarehouseName).length >0){
            return item.WarehouseName.toLowerCase().indexOf(serachTerm.toLowerCase()) > -1;    
            }  
            //return filterTitle && filterDocNum && filterPolNumber && filterPolskyOpis;
          });
         // this.setState({ items: filterPolskyOpis });
        }




      this.setState({ items: selectedBinLoc });


      
    }; 

    
    findItem(refName, e) {
   
      //if (event.charCode === 13) { for enter key
      let eanNum = e.target.value;
      
      //e.preventDefault();
      //loader load
      
      //if(eanNum.length >= 13){
        if(eanNum.length >= 5){


      document.getElementById('item_ean').value='';      
      
      //this.setState({ scanned_ean_code: eanNum });
      //alert('s');
     // this.setState({ scanned_ean_code: null });
     //if(eanNum === ''){
      //eanNum = this.state.scanned_ean_code;
     //}
     console.log(eanNum);

          this.scanItemUpdate(eanNum,0);

        } //if

      };
      
      
     
    componentDidMount() {
      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      //const { studentId } = this.props.match.params;
      //console.log( this.props.match.params);
      //this.refreshList();
      let backUrl = '';
      const pageFrom = this.props.match.params.callingfrom; 
      
      if(String(pageFrom) === "stocktransferOption"){
        //alert(String(pageFrom));
        backUrl = '/stockmovementOption/';
      
      }
      else if(String(pageFrom) === "stockcountinglist"){
        //alert(String(pageFrom));
        backUrl = '/stockcountinglist/';
      
      }
      else if(String(pageFrom) === "stockreportlist" && parseInt(this.props.match.params.id) === 1){
        //alert(String(pageFrom));
        localStorage.setItem('pageFrom','stockreportlist');
        backUrl = '/Stockreportlist/1';
      
      }
      else if(String(pageFrom) === "stockreportlist" && parseInt(this.props.match.params.id) === 2){
        //alert(String(pageFrom));     selectStockReportType    
        //backUrl = '/stockreportlist/2';
        backUrl = '/stockreportOption';
      }
      else if(String(pageFrom) === "stockmovementlist" && parseInt(this.props.match.params.id) === 2){
        //alert(localStorage.getItem('selectStockTransferType'));
        if(parseInt(localStorage.getItem('selectStockTransferType')) === 1 || parseInt(localStorage.getItem('selectStockTransferType')) === 2){
        backUrl = '/Stockmovement';
        }
        else{
        localStorage.setItem('pageFrom','stockmovementitem');
        backUrl = '/Stockmovementitem/2/'+localStorage.getItem('StockTransferNewDocId')+'/'+localStorage.getItem('StockTransferNewDocId');
        }
      }
      /*else if(String(pageFrom) === "stockmovementlist" && parseInt(this.props.match.params.id) === 2){
        //alert(String(pageFrom));
        localStorage.setItem('pageFrom','stockreportlist');
        backUrl = '/stockreportOption/';
      
      }*/
      else if(String(pageFrom) === "stockmovementbinloc" && parseInt(this.props.match.params.id) === 2){
        backUrl = '/stockmovementbinloc/'+this.props.match.params.id+'/0/0/'+localStorage.getItem('StockTransferItemCode');
      
      }
      else if(String(pageFrom) === "stockcountingOption" && parseInt(this.props.match.params.id) === 2){
        //alert(String(pageFrom));
        
        backUrl = '/stockcountingOption/';
      
      }
      else if(String(pageFrom) === "goodsreceive"){
        //alert(String(pageFrom));
        backUrl = '/goodsreceive/';
      
      }

      else{
        backUrl = '/stocktransferOption/';
      
      }
      this.setState({setPrevPage:backUrl});
    
      this.findWareHouse();
      }   
   


        onChangeKeyboard = inputk => {
          //console.log('SB1: '+this.state.input);
          //console.log('SB2: '+inputk);
          
          this.setState({
            input: inputk
          });
          //console.log("Input changed", inputk);
        };
      
        onKeyPress = button => {
          this.setState({ upScanQty: false });
          //console.log("Button pressed", button);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
           if(button === "Close"){
            this.setState({ input: '' });
            this.keyPadClose();
           }
         // if (button === "{shift}" || button === "{lock}") this.handleShift();
            if(button === "{enter}"){
              
              //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
                //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
                //this.findItem.bind(this.state.input, 'DocumentItemFind')
                //this.findItem(this.state.input, 'DocumentItemFind');
                let currKeyEan = this.state.input;
                //console.log('Keypad1: ',this.state.input);
                
                this.setState({ input: '' });
                
                //console.log('Keypad2: ',currKeyEan);
                this.scanItemUpdate(currKeyEan,0);
                this.keyPadClose();
                
                
            }
          
        };
        

       
             
      
        handleShift = () => {
          let layoutName = this.state.layoutName;
      
          this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
          });
        };
      
        onChangeInput = event => {
          let input = event.target.value;
          //alert(input);
          console.log("Button pressed", input);
          this.setState(
            {
              input: input
            },
            
            () => {
              return true;
              //this.keyboard.setInput(input);
              //this.keyboard.setState({ input: input });
            }
          );
        };

        
        onChangeEanInput = (e) => {
          //e.preventDefault();
          this.setState({ hasQty: false });
          this.setState({ upScanQty: false });
          this.setState({keyboardVisibilityQty: false});
          this.setState({keyboardVisibilityScanQty: false});

          if(this.state.keyboardVisibility === false){
            this.setState({keyboardVisibility: true});
          }
          else{
            this.setState({keyboardVisibility: false});
          }
        
      };

       
      
     onItemTap = (e,itemName,WHCode,EnableBinLocations,Inactive) => {
      //const itemID = e   
      
      const callFrom = String(this.props.match.params.callingfrom); 
      const OptionId = parseInt(this.props.match.params.id);
      let redUrl='';
      if(callFrom === 'stocktransferOption' && OptionId === 1){
        localStorage.setItem('UserSelectedDocOption', 1);
        localStorage.setItem('StockTransferItemFromWH',WHCode);
        window.location.replace('/stockmovementreqlist/'+OptionId+'/'+WHCode);
      }
      else if(callFrom === 'stockmovementbinloc' && OptionId === 1){
        localStorage.setItem('StockTransferItemToWH',WHCode);
        if(String(EnableBinLocations) === 'tYES'){
        window.location.replace('/stockmovementbinloc/'+OptionId+'/'+localStorage.getItem('StockTransferItemId')+'/'+localStorage.getItem('StockTransferItemIsPolsky')+'/'+localStorage.getItem('StockTransferItemCode'));
        }
        else{
          redUrl ='/stockdocdetails/1/'+localStorage.getItem('StockTransferItemIsPolsky')+'/'+parseInt(localStorage.getItem('StockTransferItemDocEntry'));
          this.scanItemUpdateWH(WHCode,1,redUrl);
          //window.location.replace('/stockdocdetails/1/'+localStorage.getItem('StockTransferItemIsPolsky')+'/'+parseInt(localStorage.getItem('StockTransferItemDocEntry')));
        }
      }
      //for new doc
      else if(callFrom === 'stockmovementlist' && OptionId === 2){
        //localStorage.setItem('StockTransferItemToWH','');
        if(String(localStorage.getItem('StockTransferItemFromWH')) !==null && parseInt(localStorage.getItem('StockTransferItemToWHWithoutBinStatus')) === 1 && parseInt(localStorage.getItem('selectStockTransferType')) === 1 && OptionId === 2){
          //if(parseInt(localStorage.getItem('StockTransferItemToWHWithoutBinStatus')) === 1  && OptionId === 2){
            redUrl = '/stockdocdetails/'+OptionId+'/'+localStorage.getItem('StockTransferNewDocId')+'/'+localStorage.getItem('StockTransferNewDocId');
          this.scanItemUpdateWH(WHCode,2,redUrl);
          localStorage.setItem('StockTransferItemToWH',WHCode);
          //alert(WHCode);
        }
        else{
          localStorage.setItem('StockTransferItemFromWH',WHCode);
          localStorage.setItem('StockTransferItemToWH','');
        }
        //localStorage.setItem('StockTransferItemFromWH',WHCode);
         // localStorage.setItem('StockTransferItemToWH','');
        // alert(parseInt(localStorage.getItem('StockTransferItemToWHWithoutBinStatus')));
        //alert(localStorage.getItem('StockTransferItemToWH'));
        //alert(localStorage.getItem('StockTransferItemFromWH'));
        
        //alert(localStorage.getItem('StockTransferItemFromWH'));
        window.location.replace('/stockdocdetails/'+OptionId+'/'+localStorage.getItem('StockTransferNewDocId')+'/'+localStorage.getItem('StockTransferNewDocId'));
      }

      if(callFrom === 'stockmovementitem' && parseInt(localStorage.getItem('selectStockTransferType')) === 2 && OptionId === 2){
        localStorage.setItem('StockTransferItemFromWH',WHCode);
        window.location.replace('/stockmovementbinloc/'+OptionId+'/stockmovementitemType2/0/'+localStorage.getItem('StockTransferItemCode'));
      }
      else if((callFrom === 'stockmovementbinloc' || callFrom === 'goodsreceive') && OptionId === 3){
        localStorage.setItem('StockTransferItemFromWH',WHCode);
        //alert(localStorage.getItem('StockTransferNewDocId'));
        window.location.replace('/stockdocdetails/'+OptionId+'/'+localStorage.getItem('StockTransferNewDocId')+'/'+localStorage.getItem('StockTransferNewDocId'));
        //window.location.replace('/Stockmovementitem/'+OptionId+'/'+localStorage.getItem('StockTransferNewDocId')+'/'+localStorage.getItem('StockTransferNewDocId'));
        this.scanItemUpdateWH(WHCode,2,redUrl);
      
      }

      else if(callFrom === 'goodsreceiveFromWH' && OptionId === 3){
        //alert(WHCode);
        localStorage.setItem('StockTransferItemToWH',WHCode);
        window.location.replace('/stockmovementbinloc/'+OptionId+'/goodsreceiveFromWH/0/'+localStorage.getItem('StockTransferItemCode'));
      
      }
      else if(callFrom === 'stockmovementbinloc' && OptionId === 2){
        localStorage.setItem('StockTransferItemToWH',WHCode);
        //alert('2');alert(WHCode);
        if(String(EnableBinLocations) === 'tYES'){
        window.location.replace('/stockmovementbinloc/'+OptionId+'/'+localStorage.getItem('StockTransferItemId')+'/'+localStorage.getItem('StockTransferItemIsPolsky')+'/'+localStorage.getItem('StockTransferItemCode'));
        }
        else{ 
          redUrl ='/stockdocdetails/2/'+localStorage.getItem('StockTransferItemDocEntry')+'/'+parseInt(localStorage.getItem('StockTransferItemDocEntry'));
          this.scanItemUpdateWH(WHCode,2,redUrl);
          //window.location.replace('/stockdocdetails/2/'+localStorage.getItem('StockTransferItemDocEntry')+'/'+parseInt(localStorage.getItem('StockTransferItemDocEntry')));
        }
      }
      else if(callFrom === 'stockcountinglist'){
        localStorage.setItem('StockTransferItemFromWH',WHCode);
        //alert('/StockMovementBinLoc/'+OptionId+'/stockcounting/'+localStorage.getItem('polskyNum')+'/'+localStorage.getItem('StockTransferItemCode')); // 3 is default ItemId in this case
        window.location.replace('/stockmovementbinloc/'+OptionId+'/stockcounting/'+localStorage.getItem('polskyNum')+'/'+localStorage.getItem('StockTransferItemCode'));
      
      }
      else if(callFrom === 'stockreportlist' && OptionId === 1){
        localStorage.setItem('StockTransferItemFromWH',WHCode);
        //alert('/stockmovementbinloc/'+OptionId+'/stockcounting/'+localStorage.getItem('polskyNum')+'/'+localStorage.getItem('StockTransferItemCode')); // 3 is default ItemId in this case
        window.location.replace('/StockReportQtyBinLoc/'+OptionId+'/'+localStorage.getItem('polskyNum')+'/'+localStorage.getItem('StockTransferItemCode'));
      
      }
      else if(callFrom === 'stockreportlist' && OptionId === 2){
        localStorage.setItem('StockTransferItemFromWH',WHCode);
        //alert('/StockMovementBinLoc/'+OptionId+'/stockcounting/'+localStorage.getItem('polskyNum')+'/'+localStorage.getItem('StockTransferItemCode')); // 3 is default ItemId in this case
       window.location.replace('/stockreportlist/'+OptionId);
      
      }

      else if(callFrom === "stockcountingOption"  && OptionId === 2){
        //alert(String(pageFrom));
        localStorage.setItem('UserSelectedStockCountingOption', 2);
        localStorage.setItem('StockTransferItemFromWH',WHCode);
        window.location.replace('/stockcountinglist');
      
      }
        
    };   
  
    

    scanItemUpdateWH(WH,optionId,rdurl) {
   
      let itemCode = localStorage.getItem('StockTransferItemCode');
      let fromWarehouse = localStorage.getItem('StockTransferItemFromWH');
      let DocEntry = parseInt(localStorage.getItem('StockTransferItemDocEntry'));

      let itemDet = localStorage.getItem('StockTransferItemDet');
      let fromBin = localStorage.getItem('StockTransferItemFromBin');
      let itemQty = parseInt(localStorage.getItem('StockTransferItemQty'))>0?localStorage.getItem('StockTransferItemQty') : localStorage.getItem('StockTransferItemQtyForNewDoc');
      //let toWarehouse = localStorage.getItem('StockTransferItemToWH');
      let batchNumbers = localStorage.getItem('StockTransferBinBatch')?localStorage.getItem('StockTransferBinBatch'):localStorage.getItem('selectedFromBinBatch')
      const isBatchEnabled = "N";
 

      const jsonbody =  {'user_id': localStorage.getItem('userId'),
                      'StockTransferItemId': localStorage.getItem('StockTransferItemId'),'itemDet':itemDet,
                    'OptionId':optionId,'itemCode':itemCode,'batchNumbers':batchNumbers,'itemQty':itemQty,
                  'fromWarehouse':fromWarehouse,'fromBin':fromBin,'isBatchEnabled':isBatchEnabled,
                  'toWarehouse':WH, 'docEntry':DocEntry};
                  
      
                  
                  console.log( jsonbody);    
      
      this.setState({spinner: true});
      if(itemCode.length > 0){
                fetch('/api/v1/stockmovment/ItemUpdateNonBinWH', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                  },
                  body:JSON.stringify(jsonbody)
                })
                  .then(res => res.json())
                  .then(data => {
                    console.log( data);
                    //localStorage.setItem('StockTransferItemFromWH','');
                    this.setState({spinner: false});
                    localStorage.setItem('StockTransferItemCode','');
                    localStorage.setItem('StockTransferItemDet','');
                    localStorage.setItem('StockTransferItemQty','');
                    localStorage.setItem('StockTransferItemQtyForNewDoc','');
                    localStorage.setItem('StockTransferBinBatch','');
                    localStorage.setItem('StockTransferItemToWH','');
                    if( optionId === 3){
                      localStorage.setItem('StockTransferItemFromWH','');


                    }
                    window.location.replace(rdurl);
                     //console.log( this.state.itemDet.documentslist_id)
                  //this.setState({ docItem: data.document, items: data.docItemList, total_scanned_item: data.total_scanned_item });
                 
                  
                }
                ).catch(error => {
                  
                  console.log( error);
                  this.setState({spinner: false});
              });
      
        } //if
      
      };

  render() {      
    
    


      let webcamHandleChange = (e, result)=>{
        //console.log(result);
    
        //alert(result);
        if (result) {      
          this.setState({webcamResult: result.text});
          this.setState({setStopStream: true});        
          this.setState({modalShow2: false});
          //console.log(this.state.items); 
          let formttdString = result.text;
          //console.log(formttdString);
          //this.scanItemUpdate(formttdString);
        //localStorage.setItem('sannedValues',result.text);
        this.highlightItem(formttdString.toUpperCase());
        
        }
        else{
          console.log("Not Found");
          //this.setState({webcamResult: "Not Found"});
        }
       }  

    

    function MydModalWithGridSCan(props) {
      //this.callBinlocations();
  //alert(props.onHide)
  //console.log( props.onHide);
      return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
          
          <Modal.Body className="show-grid">         
           
          <BarcodeScannerComponent
        width={450}
        height={500}
        stopStream={props.StreamStatus}
        onUpdate={webcamHandleChange}
      />
           
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Cancel</Button>           
  
          </Modal.Footer>
        </Modal>
      );
    }
    if (this.state.spinner) return ( <div className="mb-4 scanblock-div"><h1>Loading wait.... </h1></div>);
    if (this.state.items.length <=0) return ( <div><div className="mb-4 scanblock-div"><h1>There is no Bin Location(s) found for this item!
      </h1></div>           <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to={"/docdetails/"+this.state.itemDet.documentslist_id} >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>&nbsp; </div></div>
    );
    return (

      <div>
        <MydModalWithGridSCan show={this.state.modalShow2} onHide={() => this.setState({modalShow2: false})} itemId={this.props.match.params.id} StreamStatus={this.state.setStopStream}/>
            {this.props.match.params.itemcode && <div className="mb-4 scanblock-div">
              <h1>{ this.props.match.params.itemcode}</h1>
            </div>  }       
            
            <div className="mb-4 scanblock-div">
            { !localStorage.getItem('StockTransferItemFromWH') && !localStorage.getItem('StockTransferItemToWH') &&
              <h1>Select From WareHouse</h1>    
              }
              { localStorage.getItem('StockTransferItemFromWH') && !localStorage.getItem('StockTransferItemToWH') &&
              <h1>Select To WareHouse</h1>    
              }
            </div>           
            

            <div  className="scanblock-active">
                 {/* {this.state.hasQty === false && */}
                    <div className="scanblock">
                          <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[83]}</h1>
                              <div className="mb-4">
                              {/*<Link to='#'  onClick={() =>{this.tapToscan()}}>
                              <i className="fa fa-barcode fa-2" aria-hidden="true"></i></Link>
    &nbsp; &nbsp;*/}
                                    <input
                                  name='item_ean'
                                  id='item_ean'
                                  type='text'
                                  placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[84]}
                                  //onKeyUp={this.state.handlefindItem}
                                // readOnly={true}
                                 // ref={(item_ean) => {item_ean && item_ean.focus() }} 
                                  //value={this.state.scanned_ean_code}
                                  //value={this.state.input}
                                  
                                  //onChange={item_ean => this.onChangeInput(item_ean)}
                                  onChange={binloc => this.handleSearch(binloc)}
                                />   
                                {/*<button onClick={() =>{this.onChangeEanInput()}} >KeyPad</button>*/}
                                
                          </div>
                          {this.state.keyboardVisibility && (
                          <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            theme={"hg-theme-default myTheme1"}
                            layoutName={this.state.layoutName}
                            onChange={input => this.onChangeKeyboard(input)}
                            onKeyPress={button => this.onKeyPress(button)}
                            //onBlur={clse => this.keyPadClose(clse)}
                           // min={13}
                           buttonTheme={[
                            {
                              class: "hg-red",
                              buttons: "Close"
                            },
                            {
                              class: "hg-highlight",
                              buttons:  "Close"
                            }
                          ]}
                            layout={{
                              default: [
                                "Close",
                                "1 2 3 4 5 6 7 8 9 0 - {bksp}",
                                "Q W E R T Y U I O P",
                                "A S D F G H J K L {enter}",
                                "Z X C V B N M {space}"
                              ],
                              shift: [
                                "Q W E R T Y U I O P - {bksp}",
                                'A S D F G H J K L {enter}',
                                "Z X C V B N M {shift}"
                              ]
                            }}
                          />
                          )}
                    </div>
                  {/* }*/}
                                
          
        

              
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    //next={this.refreshList}
                    //hasMore={this.state.hasMore}
                    //loader={<h4>.</h4>}
                    height={275}

                  >
                            
                                 
                       {this.state.searchedData.length <=0 && this.state.items.map(i => (                          
                               <div >        
                                <Link to='#'  onClick={input => this.onItemTap(input,i.WarehouseName, i.WarehouseCode, i.EnableBinLocations, i.Inactive)}>
                                <div className={i.WarehouseName ?  `itemTap`+i.WarehouseName : ""} id={i.WarehouseCode} style={i.status ?  scanned_items : scanned_pending}   >                  
                                
                                
                                <div className="items-det" >
                                      <span className={`todo-title mr-2`} title={i}>{i.WarehouseCode}</span>                                     
                                </div>
                                  <div className="scanned-pending-items">                                      
                                      
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={`todo-title mr-2`} title={i.WarehouseName}>
                                    {i.WarehouseName}
                                    </span>
                                      </div>

                                  </div>
                                </Link> 
                                </div>
                                ))
                                
                                }                             
                               
                  </InfiniteScroll>
       
                  <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to={this.state.setPrevPage}>
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>
                </div>
            </div>

        
      </div>
      
    );

  }

}


export default WareHouse;