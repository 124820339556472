import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import authCheck from "../auth/Authcheck";

import EnglishDlogo from '../../ingold/images/english.png';
import GermanDlogo from '../../ingold/images/german.png';
import PolishDlogo from '../../ingold/images/polish.png';



class Changesetting extends Component {

  state = {   
    ParsedData:[],
    TableRows:[],
    Values:[],
    loading:true,
    //handlefindItem: this.tranLang.bind(this, 'LangTransFind'),
    
  };
 

 
  tranLang = (lang) => {
    authCheck(lang);
    setTimeout(() => {
      window.location.reload();                   
    }, 10);
    
}
render() {   
  return (
    <div>
      {this.state.loading === true && (
        <Fragment>
          <ul className="dash_items">   
            <li><Link to='#' onClick={() =>{this.tranLang('en')}} ><img src={EnglishDlogo} alt="Pick List" />English </Link></li>
            <li><Link to='#' onClick={() =>{this.tranLang('de')}} ><img src={GermanDlogo} alt="Goods received note" />German </Link></li>
            <li><Link to='#' onClick={() =>{this.tranLang('pl')}} ><img src={PolishDlogo} alt="Settings" />Polish</Link></li>
          </ul>
        </Fragment>
        
      )}
    </div>
  );
}

}

export default Changesetting;
