import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BrideNowlogo from '../../ingold/images/main_logo_131X133.png';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const handleClick = (event) => {
    setOpen(!isOpen);
  }
  const handleBlur = (event) => {
    console.log('event.relatedTarget', event.relatedTarget);
    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (isOpen) {
        setOpen(false);
      }
    }
  }

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      window.location.replace('http://localhost:3000/dashboard');
    } else {
      setLoading(false);
    }
    
  }, []);

  const onSubmit = e => {
    e.preventDefault();

    const user = {
      username: username,
      password: password
    };

    fetch('http://127.0.0.1:8000/api/v1/users/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    })
      .then(res => res.json())
      .then(data => {
        //console.log(data.Userdata);
        if (data.token) {
          localStorage.clear();
          localStorage.setItem('userId', data.user.id);
          localStorage.setItem('token', data.token);
          localStorage.setItem('username', data.user.username)
          localStorage.setItem('card_number', data.user_account.card_number);
          localStorage.setItem('user_code', data.user_account.user_code);
          
          window.location.replace('http://localhost:3000/dashboard');
        } else {
          setUsername('');
          setPassword('');
          localStorage.clear();
          setErrors(true);
        }
      });
  };

  return (
    <div>
      <header className="login_header">
      <img src={BrideNowlogo} alt="BrideNowlogo" />
      </header>
      {errors === true && <h3 className="alert alert-success alert-dismissible fade show">Cannot log in with provided credentials</h3>}
      {loading === false && (
        <form onSubmit={onSubmit}>
          
          <div className="login_form">
        <div className="login_input">
          <input
          name='username'
          type='text'
          value={username}
          placeholder='Username'
          onChange={e => setUsername(e.target.value)}
           onBlur={handleBlur}
           onClick={handleClick}
          required
        /> </div>
          {' '}
          <br /><br />
          <div className="login_input password">
          <input
          name='password'
          type='password'
          value={password}
          placeholder='Password'
          onChange={e => setPassword(e.target.value)}
          required
        /></div>
        {' '}
          <br /><br />
          <div className="text-center">
          <button type="submit" className="submit_btn">Submit</button>
          <br /><br />
          <Link to='/cardlogin' className="submit_btn">&nbsp;Login using card</Link>
          </div>
          
          </div>
          
        </form>
      )}
    </div>
  );
};

export default Login;
