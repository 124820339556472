import React,{ Component } from "react";
import { Link  } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
//import NumericInput from 'react-numeric-input';
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";
//import IncDecCounter from "../../components/IncDecCounter";

import Button from 'react-bootstrap/Button';
//import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

import BarcodeScannerComponent from "react-qr-barcode-scanner";
  
const scanned_items = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"green"
};

const scanned_pending = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"rgb(132, 126, 126)",
  color:"#ffffff"
};
//const pageFrom = this.props.match.params.callingfrom; 
//const OptionId = this.props.match.params.id;

class ItemWiseWareHouseStock extends Component {

  state = {
    items: [],
    Fixeditems: [],
    itemsShow:{ display: 'block' },
    filterItemsShow:{ display: 'none' },
    itemDet: [],
    spinner:false,
    hasQty: false,
    upScanQty: false,
    totalOrderQty: 0,
    fullScrenButton: 'Full Screen',
    //handlefindItemInput:this.findItem.bind(this, 'DocumentItemFindInput'),
    total_scanned_item:0,
    scanned_ean_code:null,
    hasFullScrn: false,
    visibility: "block",
    layoutName: "default",
    input: "",
    input3:"",
    keyboardVisibility:false,
    keyboardVisibilityQty:false,
    keyboardVisibilityScanQty:false,
    itemSelectedPrevId:0,
    itemSelectedPrevQty:0,
    itemSelected:false,
    itemSelectedAvailQty:0,
    itemSelectedBgColor:'tapping_bg_color',
    modalShow:false,
    incnum: 0,
    searchedData:[],
    selectedDistNumbers:[],
    selectedActBinLoc:[],
    selectedBinData:[],
    selectedActDistNum:[],
    webcamResult:"Not Found",
    setStopStream:false,
    setPrevPage:"",
    totalBinQty:0,
  };

  findWareHouse() {
   
    //if (event.charCode === 13) { for enter key
    const itemCode = localStorage.getItem('StockTransferItemCode');   
    const callFrom = String(this.props.match.params.callingfrom); 
    const postData =  {'itemCode':itemCode,'pageFrom':callFrom};
    //alert(callFrom)
    
    this.setState({spinner: true});
    if(itemCode.length > 0){
              fetch('/api/v1/fetchItemWareHouseStock', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(postData)
              })
                .then(res => res.json())
                .then(data => {
                  //console.log( data.length);
                  let BinQty = 0;
                  const Binrows = [];
                  //console.log( data);
                  for (let i = 0; i < data.length; i++) {
                    console.log( data[i]['Items/ItemWarehouseInfoCollection']['InStock']);
                    if(data[i]['Items/ItemWarehouseInfoCollection']['InStock'] !==null){
                    BinQty = BinQty+parseFloat(data[i]['Items/ItemWarehouseInfoCollection']['InStock']);
                    }
                    Binrows.push(data[i]);
                  }
                  console.log( Binrows);
                  this.setState({spinner: false});
                  
                  this.setState({items: Binrows, Fixeditems:Binrows,totalBinQty: BinQty});
               
                
              }
              ).catch(error => {
                
                console.log( error);
                this.setState({spinner: false});
            });
    
      } //if
    
    };

    

    handleSearch = event => {

      //console.log( event.target.value);

      
      //console.log( users);*/
      //console.log( this.state.selectedBinLocQty);
      //console.log( this.state.items);
      let serachTerm = event.target.value.toUpperCase();

      this.highlightItem(serachTerm);

    }; 

    highlightItem(serachTerm) {
      
      let allItems = this.state.Fixeditems;

      //console.log( allItems);

      const selectedBinLoc = allItems.filter(
        whCode => whCode.WarehouseCode.indexOf(serachTerm) !== -1
      );
      //console.log( selectedBinLoc);
      //setSearchedData(data);
      //const data3 = users.filter(
        //user => user.name.indexOf(event.target.value) !== -1
      //); Fixeditems
      this.setState({ searchedData: selectedBinLoc });


      
    }; 

    
        
     
    componentDidMount() {
      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      //const { studentId } = this.props.match.params;
      //console.log( this.props.match.params);
      //this.refreshList();
      let backUrl = '';
      const pageFrom = this.props.match.params.callingfrom; 
      
       if(String(pageFrom) === "stockcountinglist"){
        //alert(String(pageFrom));
        backUrl = '/stockcountinglist/';
      
      }
      else if(String(pageFrom) === "stockreportlist" && parseInt(this.props.match.params.id) === 1){
        //alert(String(pageFrom));
        localStorage.setItem('pageFrom','stockreportlist');
        backUrl = '/Stockreportlist/1';
      
      }
      else if(String(pageFrom) === "stockreportlist" && parseInt(this.props.match.params.id) === 2){
        //alert(String(pageFrom));        
        backUrl = '/stockreportlist/2';
      }     
      
      
      else{
        backUrl = '/stocktransferOption/';
      
      }
      this.setState({setPrevPage:backUrl});
    
      this.findWareHouse();
      }   
                 
      
     onItemTap = (e,WHCode,Inactive,EnableBinLocations) => {
      //const itemID = e   
      //alert(WHCode);
      //alert(EnableBinLocations);
      if(EnableBinLocations === 'N'){
        return false;
      }
      const callFrom = String(this.props.match.params.callingfrom); 
      const OptionId = parseInt(this.props.match.params.id);
       if(callFrom === 'stockcountinglist'){
        localStorage.setItem('StockTransferItemFromWH',WHCode);
        //alert('/StockMovementBinLoc/'+OptionId+'/stockcounting/'+localStorage.getItem('polskyNum')+'/'+localStorage.getItem('StockTransferItemCode')); // 3 is default ItemId in this case
        window.location.replace('/stockmovementbinloc/'+OptionId+'/stockcounting/'+localStorage.getItem('polskyNum')+'/'+localStorage.getItem('StockTransferItemCode'));
      
      }
      else if(callFrom === 'stockreportlist' && OptionId === 1){
        localStorage.setItem('StockTransferItemFromWH',WHCode);
        //alert('/stockmovementbinloc/'+OptionId+'/stockcounting/'+localStorage.getItem('polskyNum')+'/'+localStorage.getItem('StockTransferItemCode')); // 3 is default ItemId in this case
        window.location.replace('/StockReportQtyBinLoc/'+OptionId+'/'+localStorage.getItem('polskyNum')+'/'+localStorage.getItem('StockTransferItemCode'));
      
      }
      else if(callFrom === 'stockreportlist' && OptionId === 2){
        localStorage.setItem('StockTransferItemFromWH',WHCode);
        //alert('/StockMovementBinLoc/'+OptionId+'/stockcounting/'+localStorage.getItem('polskyNum')+'/'+localStorage.getItem('StockTransferItemCode')); // 3 is default ItemId in this case
       window.location.replace('/stockreportlist/'+OptionId);
      
      }
        
    };   
    

  render() {      
    
    
    
    if (this.state.spinner) return ( <div className="mb-4 scanblock-div"><h1>Loading wait.... </h1></div>);
    if (this.state.items.length <=0) return ( <div><div className="mb-4 scanblock-div"><h1>There is no Warehouse found for this item!
      </h1></div>           <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to={"/docdetails/"+this.state.itemDet.documentslist_id} >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>&nbsp; </div></div>
    );
    return (

      <div>
            {localStorage.getItem('StockTransferItemCode') && <div className="mb-4 scanblock-div">
              <h1>{ localStorage.getItem('StockTransferItemCode')}</h1>
              <h1>QTY: { this.state.totalBinQty}</h1>
            </div>  }                  
            

            <div  className="scanblock-active">
                 {/* {this.state.hasQty === false && */}
                    <div className="scanblock">
                          <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[83]}</h1>
                              <div className="mb-4">                             
                                    <input
                                  name='item_ean'
                                  id='item_ean'
                                  type='text'
                                  placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[84]}                                  
                                  
                                  //onChange={item_ean => this.onChangeInput(item_ean)}
                                  onChange={binloc => this.handleSearch(binloc)}
                                />   
                                
                          </div>
                          
                    </div>
                  {/* }*/}
                                
          
        <hr />

              
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    
                    height={400}

                  >
                            
                                 
                       {this.state.items.map(i => (                          
                               <div >        
                                <Link to='#'  onClick={input => this.onItemTap(input,i['Items/ItemWarehouseInfoCollection']['WarehouseCode'],i.Warehouses.Inactive,i.Warehouses.EnableBinLocations)}>
                                <div className={i.WarehouseName ?  `itemTap`+i.WarehouseName : ""} id={i.WarehouseCode} style={i.status ?  scanned_items : scanned_pending}   >                  
                                
                                
                                <div className="items-det" >
                                      <span className={`todo-title mr-2`} title={i}>{i.Warehouses.WarehouseName}&nbsp;{i['Items/ItemWarehouseInfoCollection']['WarehouseCode']} ( {i['Items/ItemWarehouseInfoCollection']['InStock']} )</span>                                     
                                </div>
                                 

                                  </div>
                                </Link> 
                                </div>
                                ))}                             
                            
                  </InfiniteScroll>
        <br />
                  <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to={this.state.setPrevPage}>
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>
                </div>
            </div>

        
      </div>
      
    );

  }

}


export default ItemWiseWareHouseStock;