import React,{ Component } from "react";
import { Link } from 'react-router-dom';

const stockTrans_option = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#FFA500"
};

class StockreportOption extends Component {

  state = {
    layoutName: "default",
    input: "",
    selectedOption: "",
    cardName: 'NA',
  };     

  componentDidMount() {
      
    localStorage.setItem('StockTransferItemFromWH','');
    localStorage.setItem('StockTransferItemToWH','');

    }
    
  onItemTap = (e,SelOption) => {
    //alert(SelOption);
    
    this.setState({selectedOption: SelOption});
    
   
  };


        

  render() {
    
    return (

      <div>
            <div className="mb-4" >          
              <div className="mb-12 text-center jumbotron-icon">
              <button onClick={input => window.location.replace('/stockreportlist/1')}>Item Wise Stock Report</button>
             {/*<span style={stockTrans_option}> <Link to='/warehouse/stocktransferOption/1' >Item Wise Stock Report</Link> </span>*/}
                </div>  &nbsp;
                  <div className="mb-12 text-center jumbotron-icon">
                  <button onClick={input => window.location.replace('/warehouse/stockreportlist/2')}>Warehouse Wise Stock Report</button>
                  </div>  &nbsp;
                  <div>
                    <br/><br/><br/>
                <div className="mb-12 text-center jumbotron-icon">
                <span style={stockTrans_option}> <Link to='/dashboard' >Back </Link> </span>   
                </div>
                </div>
              </div>

             
       
      </div>

    );

  }

}


export default StockreportOption;