import React, { useState } from "react";
//import Doctab from "./Doctab";
//import Doclist from "../../components/AllTabs/Doclist";

import Doclist from "../../views/app/Doclist";
import Mydoclist from "../../components/AllTabs/Mydoclist";
import MydocSyncRuninglist from "../../components/AllTabs/MydocSyncRuninglist";
import MyFailedSyncList from "../../components/AllTabs/MyFailedSyncList";
import MySyncList from "../../components/AllTabs/MySyncList";

const Doctabs = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  //const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) => activeIndex === index ? className : "";



  const handleClick = (index) => {    
     setActiveIndex(index);
     //alert(index);
     //console.log(localStorage.getItem('token'));
     //const doctab = {
      //tabId: index
    //};
    

   };


  return (
    
    <>
      <div className="tabs">
        <button
          className={`tab ${checkActive(1, "active")}`}
          onClick={() => handleClick(1)}
        >
          <i className="fa fa-edit" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActive(2, "active")}`}
          onClick={() => handleClick(2)}
        >
          <i className="fa fa-user-o" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActive(3, "active")}`}
          onClick={() => handleClick(3)}
        >
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActive(4, "active")}`}
          onClick={() => handleClick(4)}
        >
          <i className="fa fa-warning" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActive(5, "active")}`}
          onClick={() => handleClick(5)}
        >
          <i className="fa fa-check" aria-hidden="true"></i>
        </button>
      </div>
      <div className="panels">
        <div className={`panel ${checkActive(1, "active")}`}>       
          <Doclist> </Doclist>         
        </div>
        <div className={`panel ${checkActive(2, "active")}`}>
        <Mydoclist> </Mydoclist>
        </div>
        <div className={`panel ${checkActive(3, "active")}`}>
        <MydocSyncRuninglist> </MydocSyncRuninglist>
        </div>
        <div className={`panel ${checkActive(4, "active")}`}>
        <MyFailedSyncList> </MyFailedSyncList>
        </div>
        <div className={`panel ${checkActive(5, "active")}`}>
        <MySyncList> </MySyncList>
        </div>
      </div>
    </>
  );
};

export default Doctabs;