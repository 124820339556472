import React,{ Component } from "react";
import { Link } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";


const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color: "#fff",
  background:"#cfcfcf"
};

const scan_inprogress = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#FFA500"
};


class Goodsreceiptlist extends Component {

  state = {
    items: [],
    hasMore: true,
    spinner:false,
    layoutName: "default",
    input: "",
    keyboardVisibility:false,
    //handlefindDocument: this.findDocument.bind(this, 'DocumentFind')
  };


  findDocument(refName, e) {
    //alert(e.target.value.length);
    //if (event.charCode === 13) { for enter key
    const docId = e.target.value;
    if(docId.length >= 3){
              fetch('/api/v1/goodsreceipt/docfind/'+localStorage.getItem('UserReceiptOption')+'/'+docId, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                //console.log( data.id);
                //this.setState({ todoList: data })
                if(data.id > 0 ){
                window.location.replace('/goodsreceiptdetails/'+data.DocNum);
                //console.log( data.id);
                }
                else{
                  this.errors = true;
                  //console.log( data.status);
                  alert('Invalid Document number!');
                }
              }
              );
    
      } //if
    
    };
    componentDidMount() {

      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      if (localStorage.getItem('UserReceiptOption') === 0) {
        window.location.replace('/goodsreceiptOption');
      } 
      this.refreshList();


      }
     
    refreshList = () => {
      this.setState({spinner: true});
//alert(localStorage.getItem('UserReceiptOption'));
//alert(localStorage.getItem('GoodsReceiptVendorCardCode'));
    fetch('/api/v1/goodsreceipt/doclist/'+localStorage.getItem('UserReceiptOption')+'/'+localStorage.getItem('GoodsReceiptVendorCardCode'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}` //3006dc6ed948ee06cb55119eb2323288975548a8f93ecc099cd66786b9740993
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({spinner: false});
        console.log( data);
                    if (this.state.items.length >= 500) {
                      this.setState({ hasMore: false });
                      return;
                    }
                    // a fake async api call like which sends
                    // 20 more records in .5 secs
                    setTimeout(() => {
                      this.setState({ items: data })                     
                    }, 500);

                  })
            .catch(error => {
              this.setState({spinner: false});
              console.log( error);
            });       
        }; 
  
    onChangeEanInput = (e) => {
      //e.preventDefault();
      if(this.state.keyboardVisibility === false){
        this.setState({keyboardVisibility: true});
      }
      else{
        this.setState({keyboardVisibility: false});
      }
    
  };     


  onChange = input => {
    this.setState({
      input: input
    });
    //console.log("Input changed", input);
  };

  onKeyPress = button => {
    //console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    //alert(button);
     if(button === "Close"){
      this.keyPadClose();
     }
   // if (button === "{shift}" || button === "{lock}") this.handleShift();
      if(button === "{enter}"){
        //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
          //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
          //this.findItem.bind(this.state.input, 'DocumentItemFind')
          //this.findItem(this.state.input, 'DocumentItemFind');
          let currKeyEan = this.state.input;
          this.setState({ input: '' });
         // console.log('dddd',currKeyEan);
          this.scanItemUpdate(currKeyEan);
          
          
      }
    
  };
  keyPadClose = () => {
    // alert('s');
     this.setState({keyboardVisibility: false});
     this.setState({keyboardVisibilityQty: false});
     
     const focusField = document.querySelector(
      `input[name=doc_number]`
    );
      focusField.focus();
   
 };

  scanItemUpdate(docId) { 
    this.setState({ input: '' });  

    setTimeout(() => {
               

      fetch('/api/v1/goodsreceipt/docfind/'+localStorage.getItem('UserReceiptOption')+'/'+docId, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
              }
            })
              .then(res => res.json())
              .then(data => {            
                if(data.id > 0 ){
                  window.location.replace('/goodsreceiptdetails/'+data.DocNum);
                  //console.log( data.id);
                  }
                  else{
                    this.errors = true;
                    //console.log( data.status);
                    alert(JSON.parse(sessionStorage.getItem('currentLang'))[33]);
                  }
            }
            )
            .catch(error => {
              alert(JSON.parse(sessionStorage.getItem('currentLang'))[33]);
          });

  

  }, 500);

  }
  render() {
    async function removeItem(id,DocEntry, userId) {
      const currentUser = localStorage.getItem('userId');

      //if(window.confirm('The document is currently edited by another user. Do you want to continue?')){

      if(userId === null || userId === parseInt(currentUser)){
      window.location.replace('goodsreceiptdetails/'+id+'/'+DocEntry);
    }
    else{
      alert(JSON.parse(sessionStorage.getItem('currentLang'))[32]);
    }

  }


  if (this.state.spinner) return ( <div className="mb-4 scanblock-div"><h1>Loading wait.... </h1></div>);
    if (this.state.items.length <=0) return ( <div><div className="mb-4 scanblock-div"><h1>There is no item(s) found for this item!
      </h1></div>           <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to={"/goodsreceiptOption"} >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>&nbsp; </div></div>
    );

    return (

      <div>
        <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[9]}</h1>
            <div className="mb-4">
            <input
          name='doc_number'
          type='text'
          placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[10]}
          onKeyUp={this.state.handlefindDocument}
          ref={(DocumentFind) => {DocumentFind && DocumentFind.focus() }} 
        />   
         <button onClick={() =>{this.onChangeEanInput()}} >{ JSON.parse(sessionStorage.getItem('currentLang'))[11]}</button>
              </div>

              {this.state.keyboardVisibility && (
                          <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            theme={"hg-theme-default myTheme1"}
                            layoutName={this.state.layoutName}
                            onChange={input => this.onChange(input)}
                            onKeyPress={button => this.onKeyPress(button)}
                            //onBlur={clse => this.keyPadClose(clse)}
                           // min={13}
                           buttonTheme={[
                            {
                              class: "hg-red",
                              buttons: "Close"
                            },
                            {
                              class: "hg-highlight",
                              buttons: "Close"
                            }
                          ]}
                            layout={{
                              default: [
                                "Close",
                                "1 2 3 4 5 6 7 8 9 0 - {bksp}",
                                "q w e r t y u i o p",
                                "a s d f g h j k l {enter}",
                                "z x c v b n m {shift}"
                              ],
                              shift: [
                                "Q W E R T Y U I O P - {bksp}",
                                'A S D F G H J K L {enter}',
                                "Z X C V B N M {shift}"
                              ]
                            }}
                          />
                          )}     
        <hr />
        <InfiniteScroll
          dataLength={this.state.items.length}
          //next={this.refreshList}
          //hasMore={this.state.hasMore}
          //loader={<h4>.</h4>}
          height={400}

        >
          {this.state.items.map((i, index) => (
            
            <Link to='#' onClick={ () => { removeItem(i.DocNum,i.DocEntry, i.user_id) }}>
            <div style={i.user_id ?  scan_inprogress : scanned_items} key={index}>
             
              <span className={`todo-title mr-2`} title={i.DocNum}>
              { JSON.parse(sessionStorage.getItem('currentLang'))[12]}:  {i.DocNum}
              </span><br />

              <span className="`todo-title mr-2`">
              <i className="fa fa-calendar" aria-hidden="true"></i>
                {i.DocDate}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<i className="fa fa-user-o" aria-hidden="true"></i>
                &nbsp;{i.user_code}
                </span>
                <br />
                {i.comments !==null && 
                <span className="`todo-title mr-2`" >
                <i className="fa fa-info-circle" aria-hidden="true"></i>
                &nbsp;{i.comments.substring(0, 20)} (...)
              </span>}
              
            </div>
            </Link>
          ))}
        </InfiniteScroll>
        <br />
       
        <div className="mb-12 text-center jumbotron-icon panel_footer">
        
                
        <Link to={'/GoodsReceiptOption'} >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>                    
                    </button>
                    </Link>
          &nbsp;&nbsp;&nbsp;
        
        <button onClick={this.refreshList}>
        <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
        </button>
        </div>
      </div>

    );

  }

}


export default Goodsreceiptlist;