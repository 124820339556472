import React,{ Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from 'react-router-dom';

const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"green"
};



class GoodsreceiptMyFailedSyncList extends Component {

  state = {
    items: [],
    hasMore: true,
  };


  
    componentDidMount() {
      //alert(1);
      //this.refreshList4();


      }
     
      refreshList4 = () => {

    fetch('/api/v1/goodsreceipt/myFailedSyncList/'+localStorage.getItem('userId'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        //console.log( data);
                //alert('f');    
                    // a fake async api call like which sends
                    // 20 more records in .5 secs
                    setTimeout(() => {
                      this.setState({ items: data })                     
                    }, 500);

                  });     
        }; 
       
  render() {
    
    async function removeItem(id, userId) {
      const currentUserId = localStorage.getItem('userId');
      if(userId === null || parseInt(currentUserId) === userId){
      window.location.replace('/goodsreceiptlist');
    }
    else{
      alert('Another user already scanning!');
    }

  }


    return (

      <div>
       
        
        <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.refreshList4}
          hasMore={this.state.hasMore}
          loader={<h4>.</h4>}
          height={400}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
           {this.state.items.length <=0 && (
            <div >
              <span className="`todo-title mr-2`">Not found</span>
              </div>
           )}
          {this.state.items.length >0 && (
          this.state.items.map((i, index) => (
            <Link to='#' onClick={ () => { removeItem(i.id, i.user_id) }}>
            <div style={scanned_items} key={index}>
             
              <span className={`todo-title mr-2`} title={i.DocNum}>
              Document no:  {i.DocNum}
              </span><br />

              <span className="`todo-title mr-2`">
              <i className="fa fa-calendar" aria-hidden="true"></i>
                {i.DocDate}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<i className="fa fa-user-o" aria-hidden="true"></i>
                &nbsp;{i.DocEntry}               
                </span><br />
                {i.Comments !==null && 
                <span className="`todo-title mr-2`" >
                <i className="fa fa-info-circle" aria-hidden="true"></i>
                &nbsp;{i.Comments.substring(0, 10)} (...)
              </span>}

              
            </div>
            </Link>
          ))
          )}
        </InfiniteScroll>
        <br />
        <div className="mb-12 text-center jumbotron-icon">
        <button onClick={this.refreshList4}>
        <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
        </button>
        </div>
      </div>

    );

  }

}


export default GoodsreceiptMyFailedSyncList;