import React, { useState, Fragment } from 'react';
import Goodsreceipttabs from "../../components/AllTabs/GoodsReceipt/Goodsreceipttabs";

const Goodsreceipt = () => {
  const [loading] = useState(true);
  return (
    <div>
      {loading === true && (
        <Fragment>
          <Goodsreceipttabs>
            
          </Goodsreceipttabs>
        </Fragment>
        
      )}
    </div>
  );
};

export default Goodsreceipt;
