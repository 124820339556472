import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BrideNowlogo from '../../ingold/images/main_logo_131X133.png';
import authCheck from "./Authcheck";

const Cardlogin = () => {
  const [card_number, setCardnumber] = useState('');  
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(true);

  const [isOpen, setOpen] = useState(false);
  const handleClick = (event) => {
    setOpen(!isOpen);
  }
  const handleBlur = (event) => {
    console.log('event.relatedTarget', event.relatedTarget);
    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (isOpen) {
        setOpen(false);
      }
    }
  }

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      window.location.replace('/dashboard');
    } else {
      setLoading(false);
    }
  }, []);

  const onSubmit = e => {
    e.preventDefault();

    const user = {
      card_number: card_number,
    };

    fetch('/api/v1/users/auth/cardlogin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    })
      .then(res => res.json())
      .then(data => {
        //alert(data.user.id);
        if (data.token) {
          localStorage.clear();
          localStorage.setItem('userId', data.user.id);
          localStorage.setItem('token', data.token);
          localStorage.setItem('username', data.user.username);
          localStorage.setItem('card_number', data.user_account.card_number);
          localStorage.setItem('user_code', data.user_account.user_code);
          authCheck('en');
          window.location.replace('/dashboard');
          
        } else {
          setCardnumber('');
          localStorage.clear();
          setErrors(true);
        }
      });
  };

  return (
    <div>
      
      <header className="login_header">
      <img src={BrideNowlogo} alt="WMS Poland" />
      </header>
{loading === false && <h1>.</h1>}
      {errors === true && <h3 className="alert alert-success alert-dismissible fade show">Invalid Card Number</h3>}
<form onSubmit={onSubmit}>
<div className="login_form">
        <div className="login_input">
        <input
          name='card_number'
          type='password'
          value={card_number}
          placeholder='Card Number'
          onChange={e => setCardnumber(e.target.value)}
          onBlur={handleBlur}
          onClick={handleClick}          
          required
        />   
        <button type="submit"><i className="fa fa-angle-right" aria-hidden="true"></i></button>        
        <br /><br />
          <Link to='/login' className="submit_btn">Login using login and password</Link>
        <br />     
            <div className="row mb-4">
            <div className="col d-flex justify-content-center">
            </div>
              
            </div>
        </div>
        </div>
      </form>
     
    </div>
  );
};

export default Cardlogin;
