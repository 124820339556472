import React,{ Component } from "react";
import { Link } from 'react-router-dom';


const stockTrans_option = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#FFA500"
};

class StockmovementOption extends Component {

  state = {
    layoutName: "default",
    input: "",
    cardCode: "",
    cardName: 'NA',
    optionType: 0,
  };

     
    componentDidMount() {
      localStorage.setItem('UserSelectedDocOption', 0);

      localStorage.setItem('StockTransferItemId','');
      localStorage.setItem('StockTransferItemIsPolsky','');
      localStorage.setItem('StockTransferItemCode','');
      localStorage.setItem('StockTransferItemQty',0);
      localStorage.setItem('StockTransferItemFromBin','');
      localStorage.setItem('StockTransferItemFromWH','');
      localStorage.setItem('StockTransferItemToWH','');
      localStorage.setItem('StockTransferItemToBin','');

      }

      backStockTransferType = e => {
        this.setState({ optionType: 0 });
        //console.log("Input changed", input);
      }; 

      selectStockTransferType = e => {
        this.setState({ optionType: e });
        localStorage.setItem('selectStockTransferType',e);    // to Identify the option: Warehousewise:1 or Itemwise:2
        //console.log("Input changed", input);
      }; 

      ReqlistConfirm = e => {
        if(this.state.optionType === 1){
          window.location.replace('/warehouse/stocktransferOption/1');
        }
        else{
        window.location.replace('/stockmovementreqlist/1/All');
        }

      }; 

     
    readCommentConfirm = () => {
      localStorage.setItem('UserSelectedDocOption', 2);
      localStorage.setItem('StockTransferItemFromWH','All');
      window.location.replace('/Stockmovement');

          };       

  render() {
    
    return (

      <div>
       
            <div className="mb-4" >     

            { parseInt(this.state.optionType) ===0 &&
             <span>
            <div className="mb-12 text-center jumbotron-icon">
              
             <span style={stockTrans_option}> <Link to='#' onClick={() =>{this.selectStockTransferType(1)}}  >Warehouse Wise</Link> </span>
                </div>  &nbsp;
                  <div className="mb-12 text-center jumbotron-icon">
                  <button  onClick={() =>{this.selectStockTransferType(2)}} >Item Wise</button>
                  </div>  &nbsp;
                  <div>
                    <br/><br/><br/>
                <div className="mb-12 text-center jumbotron-icon">
                <span style={stockTrans_option}> <Link to='/dashboard' >Back </Link> </span>   
                </div>
                </div>
              </span>
            }


             { this.state.optionType >0 &&
             <span>
              <div className="mb-12 text-center jumbotron-icon">
              
             <span style={stockTrans_option}> <Link to='/warehouse/stocktransferOption/1' onClick={this.ReqlistConfirm}>Based on Stock Transfer Request</Link> </span>
                </div>  &nbsp;
                  <div className="mb-12 text-center jumbotron-icon">
                  <button onClick={this.readCommentConfirm}>New Document </button>
                  </div>  &nbsp;
                  <div>
                    <br/><br/><br/>
                <div className="mb-12 text-center jumbotron-icon">
                <span style={stockTrans_option}> <Link to='#'  onClick={() =>{this.backStockTransferType()}}>Back </Link> </span>   
                </div>
                </div>
                </span>
        }
              </div>
            

             
       
      </div>

    );

  }

}


export default StockmovementOption;