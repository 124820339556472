import React, { useState } from "react";
//import Doctab from "./Doctab";
//import Doclist from "../../components/AllTabs/Doclist";

import Goodsreceivelist from "../../../views/app/Goodsreceivelist";
import GoodsMydoclist from "../../../components/AllTabs/GoodsReceived/GoodsMydoclist";
import GoodsMydocSyncRuninglist from "../../../components/AllTabs/GoodsReceived/GoodsMydocSyncRuninglist";
import GoodsMyFailedSyncList from "../../../components/AllTabs/GoodsReceived/GoodsMyFailedSyncList";
import GoodsMySyncList from "../../../components/AllTabs/GoodsReceived/GoodsMySyncList";
//import GoodsActiveVendorList from "../../../components/AllTabs/GoodsReceived/GoodsActiveVendorList";

const Goodstabs = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  //const handleClick = (index) => setActiveIndex(index);
  const checkActiveGoods = (index, className) => activeIndex === index ? className : "";



  const handleClickGoods = (index) => {    
     setActiveIndex(index);
     //alert(index);
     //console.log(localStorage.getItem('token'));
     //const doctab = {
      //tabId: index
    //};
    

   };


  return (
    
    <>
      <div className="tabs">

        <button
          className={`tab ${checkActiveGoods(1, "active")}`}
          onClick={() => handleClickGoods(1)}
        >
          <i className="fa fa-edit" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActiveGoods(2, "active")}`}
          onClick={() => handleClickGoods(2)}
        >
          <i className="fa fa-user-o" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActiveGoods(3, "active")}`}
          onClick={() => handleClickGoods(3)}
        >
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActiveGoods(4, "active")}`}
          onClick={() => handleClickGoods(4)}
        >
          <i className="fa fa-warning" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActiveGoods(5, "active")}`}
          onClick={() => handleClickGoods(5)}
        >
          <i className="fa fa-check" aria-hidden="true"></i>
        </button>
      </div>
      <div className="panels">
        <div className={`panel ${checkActiveGoods(1, "active")}`}>       
        <Goodsreceivelist> </Goodsreceivelist>    
        </div>
        <div className={`panel ${checkActiveGoods(2, "active")}`}>
        <GoodsMydoclist> </GoodsMydoclist>
        </div>
        <div className={`panel ${checkActiveGoods(3, "active")}`}>
        <GoodsMydocSyncRuninglist> </GoodsMydocSyncRuninglist>
        </div>
        <div className={`panel ${checkActiveGoods(4, "active")}`}>
        <GoodsMyFailedSyncList> </GoodsMyFailedSyncList>
        </div>
        <div className={`panel ${checkActiveGoods(5, "active")}`}>
        <GoodsMySyncList> </GoodsMySyncList>
        </div>

       
      </div>
    </>
  );
};

export default Goodstabs;