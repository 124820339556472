import React,{ Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"green"
};



class MySyncList extends Component {

  state = {
    items: [],
    hasMore: true,
  };


  
    componentDidMount() {
      //alert(1);
      this.refreshList4();


      }
     
      refreshList4 = () => {

    fetch('/api/v1/documents/mySyncList/'+localStorage.getItem('username'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        //console.log( data);
                //alert('f');    
                    // a fake async api call like which sends
                    // 20 more records in .5 secs
                    setTimeout(() => {
                      this.setState({ items: data })                     
                    }, 500);

                  });     
        }; 
       
  render() {
    
    


    return (

      <div>
       
        
        <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.refreshList4}
          hasMore={this.state.hasMore}
          loader={<h4>.</h4>}
          height={400}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
           {this.state.items.length <=0 && (
            <div >
              <span className="`todo-title mr-2`">Not found</span>
              </div>
           )}
          {this.state.items.length >0 && (
          this.state.items.map((i, index) => (
            <div style={scanned_items} key={index}>
             
             <span className={`todo-title mr-2`} title={i.description}>
              {/*{ JSON.parse(sessionStorage.getItem('currentLang'))[12]}: */}
               {i.picklist_id}
              </span><br />

              <span className="`todo-title mr-2`">
              <i className="fa fa-calendar" aria-hidden="true"></i>
                {i.picklist_date}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<i className="fa fa-user-o" aria-hidden="true"></i>
                &nbsp;{i.pickListname}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<i className="fa fa-shopping-bag" aria-hidden="true"></i>
                &nbsp;{i.cardcode}
                </span><br />
                {i.Comments !==null && 
                <span className="`todo-title mr-2`" >
                <i className="fa fa-info-circle" aria-hidden="true"></i>
                &nbsp;{i.Comments.substring(0, 10)} (...)
              </span>}

              
            </div>
          ))
          )}
        </InfiniteScroll>
        <br />
        <div className="mb-12 text-center jumbotron-icon">
        <button onClick={this.refreshList4}>
        <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
        </button>
        </div>
      </div>

    );

  }

}


export default MySyncList;