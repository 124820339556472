import React,{ Component } from "react";
import { Link } from 'react-router-dom';
class CommentScreen extends Component {

  state = {
    allSeries: [],
    SeriesNo:'',
    //referenceNo: '',
    POremarks: '',
    defaultSeries:0,
    messageShow: '',
    setPrevPage:""
  };

  findStockItems() {
   
    //if (event.charCode === 13) { for enter key   
   const optionId = 1;  
    const itemId = localStorage.getItem('StockTransferDocNum'); 
    const user_id = localStorage.getItem('userId');    
    
    
    var ajaxUrl = '/api/v1/FetchDefaultSeries/'+optionId+'/StockCounting';
    
    //alert(localStorage.getItem('StockTransferDocNum'));
    this.setState({spinner: true});
              fetch(ajaxUrl, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                  //console.log( data.stockItems.length);       
                  this.setState({
                    SeriesNo: data.defaultSeries[0]
                  });            
                  this.setState({allSeries:data.allSeries,defaultSeries: data.defaultSeries});
                  
                  this.setState({spinner: false});
                  //console.log( Binrows);
                 //alert(this.state.items[0]['Items']['ItemCode']);
                 //alert(this.state.items.length);    
                
              }
              ).catch(error => {
                
                console.log( error);
                this.setState({spinner: false});
            });
    
     
    
    };
  componentDidMount() {
    /*var backUrl = "/StockMovementBinLoc/3/"+localStorage.getItem('StockTransferItemId')+'/'+localStorage.getItem('StockTransferItemIsPolsky')+'/'+localStorage.getItem('StockTransferItemCode');
    if(parseInt(localStorage.getItem('UserSelectedStockCountingOption')) === 2 ){
      backUrl = "/stockmovementbinloc/2/stockcounting/"+localStorage.getItem('StockTransferItemIsPolsky')+'/'+localStorage.getItem('StockTransferItemCode');
    }*/
    var backUrl = "/stockcountingdocdetails/"+this.props.match.params.optionId;
    this.setState({setPrevPage: backUrl});

    
    this.findStockItems();
    
      } //if
    
      updateSeriesNo = (e) => {        
        //console.log(e.target);
        if (e.target!== '') {
          this.setState({
            SeriesNo: e.target.value
          });      
        } 
        else{
          this.setState({
            SeriesNo: this.state.defaultSeries[0]
          });   
        }    
    
      }

      updateReferenceNo = (e) => {        
        //console.log(e.target);
        if (e.target!== '') {
          this.setState({
            referenceNo: e.target.value
          });      
        }  
           
    
      }

      updatePOremarks = (e) => {        
        //console.log(e.target);
        if (e.target!== '') {
          this.setState({
            POremarks: e.target.value
          });      
        }     
    
      }


    findPurchaseOrder = () => {
    //alert(localStorage.getItem('StockTransferItemToWH'));
    let POremarks = this.state.POremarks.trim();
    this.setState({ spinner: true }); 
    if(POremarks.length >0 ){     
      this.setState({ messageShow: "Your data is sending, Please wait...." });
      //alert(localStorage.getItem('StockTransferItemDocEntry'));     this.state.defaultSeries
      
      
      const postData = {
        user_id: localStorage.getItem('userId'),
        StockTransferOptionId: this.props.match.params.optionId,
        StockTransferItemId: localStorage.getItem('StockTransferItemId'),
        StockTransferItemIsPolsky: localStorage.getItem('StockTransferItemIsPolsky'),
        StockTransferItemCode: localStorage.getItem('StockTransferItemCode'),
        StockTransferItemQty: localStorage.getItem('StockTransferItemQty'),
        StockTransferItemFromWH: localStorage.getItem('StockTransferItemFromWH'),
        StockTransferItemFromBin: localStorage.getItem('StockTransferItemFromBin'),
        StockTransferItemToWH: localStorage.getItem('StockTransferItemToWH'),
        StockTransferItemToBin: localStorage.getItem('StockTransferItemToBin'),
        StockTransferItemDocEntry: localStorage.getItem('StockTransferItemDocEntry'),
        StockTransferBinBatch: localStorage.getItem('StockTransferBinBatch'),
        StockItemBinQty: localStorage.getItem('StockItemBinQty'),
        SeriesNo: this.state.SeriesNo,
        //referenceNo: this.state.referenceNo,
        POremarks: this.state.POremarks
      };
      console.log( postData);  
      fetch('/api/v1/stockcounting/sapSyncConfirm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(postData)
      })
        .then(res => res.json())
        .then(data => {
          console.log( data.status);  
          if(parseInt(data.status) === 1){
          this.setState({ messageShow: "Data Successfully Sent To SAP! New DocumentNumber: "+data['docentry'] });
          alert("Data Successfully Sent To SAP! New DocumentNumber: "+data['docnum']);
          //localStorage.setItem('VendorsDocEntry','');
          localStorage.setItem('SeriesNo','');
          window.location.replace('/stockcountinglist');
          }
          else if(parseInt(data.status) === 2){
          this.setState({ messageShow: "Error in Warehouse/Bin location selection: "+ localStorage.getItem('StockTransferItemFromWH')+">"+localStorage.getItem('StockTransferItemFromBin')});
            alert("Error in Warehouse/Bin location selection: "+ localStorage.getItem('StockTransferItemFromWH')+">"+localStorage.getItem('StockTransferItemFromBin'));
          }          
          else{
            this.setState({ messageShow: "Error: "+data['status'] });
            alert("Error: "+data.status );
          }
        
      }
      )
      .catch(error => {
        console.log( error);
        });  


    }
    else{
      alert("Please enter the comments!");
    }
        }



  render() {

    return (

      <div>
        <div className="mb-4" >{this.state.messageShow} {this.state.spinner}</div>
            <div className="mb-4" >  

            {/*<div className="mb-12 text-center jumbotron-icon">
              <div>Selected Item: {localStorage.getItem('StockTransferItemCode')}</div>
              <div>Warehouse and Bin: {localStorage.getItem('StockTransferItemFromWH')} / {localStorage.getItem('StockTransferItemFromBin')}</div>
              <div>Qty: {localStorage.getItem('StockTransferItemQty')}</div>

                <br/>
    </div>  */}       
           
              <div className="mb-12 text-center jumbotron-icon">
              { JSON.parse(sessionStorage.getItem('currentLang'))[50]}&nbsp;
              
              <select name="seriesCode" id="seriesCode"  onChange={this.updateSeriesNo}>    
              {<option value={0}>Select</option>}
              {this.state.allSeries.map((i, index) => (
                parseInt(this.state.defaultSeries[0]) !== parseInt(i.Series)?
              <option value={i.Series}>{i.Name}</option>
               :
              <option value={this.state.defaultSeries[0]} selected>{this.state.defaultSeries[1]}</option>
                
              ))}
              </select>
              </div> 

                
                     &nbsp;
                  <div className="mb-12 text-center jumbotron-icon">
                  { JSON.parse(sessionStorage.getItem('currentLang'))[52]}&nbsp; 
                      <textarea name="POremarks" id="POremarks" cols={40} rows="4"  onChange={this.updatePOremarks}></textarea>
                  </div>  &nbsp;
                  <div>
                <div className="mb-12 text-center jumbotron-icon">
                <Link to={ this.state.setPrevPage }>
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>                    
                    </button>
                    </Link>&nbsp;   
                <button onClick={this.findPurchaseOrder}>
                <i className="fa fa-save fa-2" aria-hidden="true"></i>      
                </button>
               
                </div>
                </div>
              </div>

             
       
      </div>

    );

  }

}


export default CommentScreen;