import React,{ Component } from "react";
import { Link } from 'react-router-dom';
const scanned_items = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color: "#fff",
  background:"#a1eba1"
};


class CommentScreen extends Component {
  


  state = {
    items: [],
    allSeries: [],
    SeriesNo:'',
    spinner:false,
    //referenceNo: '',
    POremarks: '',
    messageShow: '',
    setPrevPage: "",
    defaultSeries:0,
    optionId: parseInt(this.props.match.params.optionId)
  };


  findStockItems() {
   
    //if (event.charCode === 13) { for enter key   
   //const optionId = this.props.match.params.optionId;  
    const itemId = localStorage.getItem('StockTransferDocNum')? localStorage.getItem('StockTransferDocNum'):localStorage.getItem('StockTransferItemDocEntry'); 
    const user_id = localStorage.getItem('userId');    
    
    console.log( localStorage.getItem('StockTransferItemFromBin'));   
    var ajaxUrl = '/api/v1/stockmovment/fetchStockTransferItems/'+this.state.optionId+'/'+itemId+'/'+user_id;
    
    //alert(localStorage.getItem('StockTransferDocNum'));
    this.setState({spinner: true});
              fetch(ajaxUrl, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                  console.log( data);
                  //console.log( data.stockItems.length);          
                  this.setState({
                    SeriesNo: data.defaultSeries[0]
                  });        
                  this.setState({items: data.stockItems, allSeries:data.allSeries,defaultSeries: data.defaultSeries});
                  
                  this.setState({spinner: false});
                  //console.log( Binrows);
                 //alert(this.state.items[0]['Items']['ItemCode']);
                 //alert(this.state.items.length);    
                
              }
              ).catch(error => {
                
                console.log( error);
                this.setState({spinner: false});
            });
    
     
    
    };


  componentDidMount() {
    this.findStockItems();
    let docID=0;
    let DocEntry = localStorage.getItem('StockTransferItemDocEntry');
            if(parseInt(this.props.match.params.optionId) === 1){
              //docID = localStorage.getItem('StockTransferDocNum');
              docID = 0;
              DocEntry = localStorage.getItem('StockTransferMultipleDocEntry');
            }
            else{
              docID = localStorage.getItem('StockTransferItemDocEntry');
              DocEntry = localStorage.getItem('StockTransferItemDocEntry');
            }

            if(localStorage.getItem('pageFrom') === 'stockdocdetails'){
              this.setState({ setPrevPage: "/stockdocdetails/"+this.props.match.params.optionId+"/"+docID+"/"+DocEntry});
            }
            else if(localStorage.getItem('pageFrom') === 'goodsreceive'){
              this.setState({ setPrevPage: "/stockdocdetails/"+this.props.match.params.optionId+"/"+DocEntry+"/"+DocEntry});
            }
            else{
              this.setState({ setPrevPage: "/stockMovementBinLoc/"+this.props.match.params.optionId+'/'+localStorage.getItem('StockTransferItemId')+'/'+localStorage.getItem('StockTransferItemIsPolsky')+'/'+localStorage.getItem('StockTransferItemCode')});
            }
    //alert(localStorage.getItem('StockTransferItemDocEntry'));
    
    
      } //if
    
      

      updatePOremarks = (e) => {        
        //console.log(e.target);
        if (e.target!== '') {
          this.setState({
            POremarks: e.target.value
          });      
        }     
    
      }

    findPurchaseOrder = () => {
    //alert(localStorage.getItem('StockTransferItemToBin'));
    let POremarks = this.state.POremarks.trim();
    if(POremarks.length >0 ){      
      this.setState({spinner: true});
      //alert(localStorage.getItem('StockTransferBinBatch'));     
      let DocEntry = localStorage.getItem('StockTransferItemDocEntry');
      if(parseInt(this.props.match.params.optionId) === 1){
      DocEntry = localStorage.getItem('StockTransferMultipleDocEntry');
            }
      
      const postData = {
        user_id: localStorage.getItem('userId'),
        StockTransferOptionId: this.props.match.params.optionId,
        StockTransferItemId: localStorage.getItem('StockTransferItemId'),
        StockTransferItemIsPolsky: localStorage.getItem('StockTransferItemIsPolsky'),
        StockTransferItemCode: localStorage.getItem('StockTransferItemCode'),
        StockTransferItemQty: localStorage.getItem('StockTransferItemQty'),
        StockTransferItemFromWH: localStorage.getItem('StockTransferItemFromWH'),
        StockTransferItemFromBin: localStorage.getItem('StockTransferItemFromBin'),
        StockTransferItemToWH: localStorage.getItem('StockTransferItemToWH'),
        StockTransferItemToBin: localStorage.getItem('StockTransferItemToBin'),
        StockTransferItemDocEntry: DocEntry,
        StockTransferBinBatch: localStorage.getItem('StockTransferBinBatch'),
        SeriesNo: this.state.SeriesNo,
        //referenceNo: this.state.referenceNo,
        POremarks: this.state.POremarks
      };
      console.log( postData);  
      fetch('/api/v1/stockmovment/sapSyncConfirm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(postData)
      })
        .then(res => res.json())
        .then(data => {
          this.setState({spinner: false});
          //alert(data.status)
          //return false;
          console.log( data);  
          if(parseInt(data.status) >= 1){
          localStorage.setItem('pageFrom','');
          localStorage.setItem('StockTransferDocNum','');
          localStorage.setItem('StockTransferItemId','');
          localStorage.setItem('StockTransferItemIsPolsky','');
          localStorage.setItem('StockTransferItemCode','');
          localStorage.setItem('StockTransferItemQty','');
          //localStorage.setItem('StockTransferItemFromWH','');
          localStorage.setItem('StockTransferItemFromBin','');
          localStorage.setItem('StockTransferItemToWH','');
          localStorage.setItem('StockTransferItemToBin','');
          localStorage.setItem('StockTransferItemDocEntry','');
          localStorage.setItem('StockTransferBinBatch','');
          localStorage.setItem('SeriesNo','');
          if(parseInt(data.status) === 1){
          this.setState({ messageShow: "Data Successfully Sent To SAP! New DocEntry: "+data.docentry});
          alert("Data Successfully Sent To SAP! New DocEntry: "+data.docentry);
          }
          else{
            this.setState({ messageShow: "Error: "+data.status});
            alert("Error: "+data.status );
          }
          //localStorage.setItem('VendorsDocEntry','');localStorage.getItem('selectStockTransferType')
          if(parseInt(this.props.match.params.optionId) === 1 && String(localStorage.getItem('StockTransferItemFromWH')) === 'All'){
          window.location.replace('/stockmovementreqlist/1/All');
          }
          else if(parseInt(this.props.match.params.optionId) === 1 && parseInt(localStorage.getItem('StockTransferItemFromWH')) !== 'All'){
            window.location.replace('/stockmovementreqlist/1/'+localStorage.getItem('StockTransferItemFromWH'));
            }
          else if(parseInt(this.props.match.params.optionId) === 3){
            window.location.replace('/goodsreceive');
            }

          else{
            window.location.replace('/Stockmovement');
          }
          
          }
          else{
            this.setState({ messageShow: "Error: "+data.status });
            alert("Error: "+data.status );
          }
          
        
      }
      )
      .catch(error => {
        this.setState({spinner: false});
        console.log( error.message.value);
        alert("1470000519 - 'From Bin Locations' must be different from 'To Bin Locations'; specify different bin locations in document row 1")
        });  


    }
    else{
      alert("Please enter the comments!");
    }
        }

        updateSeriesNo = (e) => {        
          //console.log(e.target);
          if (e.target!== '') {
            this.setState({
              SeriesNo: e.target.value
            });      
          } 
          else{
            this.setState({
              SeriesNo: this.state.defaultSeries[0]
            });   
          }    
      
        }


  render() {
    if (this.state.spinner) return ( <div className="mb-4 scanblock-div"><h1>Processing wait.... </h1></div>);
    return (

      <div>
        <div className="mb-4" >{this.state.messageShow} </div>
            <div className="mb-4" >          
           
              <div className="mb-12 text-center jumbotron-icon">
              { JSON.parse(sessionStorage.getItem('currentLang'))[50]}&nbsp;
              
              <select name="seriesCode" id="seriesCode"  onChange={this.updateSeriesNo}>   
              {<option value={0}>Select</option>}
              {this.state.allSeries.map((i, index) => (
               parseInt(this.state.defaultSeries[0]) !== parseInt(i.Series)?
               <option value={i.Series}>{i.Name}</option>
                :
               <option value={this.state.defaultSeries[0]} selected>{this.state.defaultSeries[1]}</option>

              ))}
              </select>
                </div> 

                
                     &nbsp;
                  <div className="mb-12 text-center jumbotron-icon">
                  { JSON.parse(sessionStorage.getItem('currentLang'))[52]}&nbsp; 
                      <textarea name="POremarks" id="POremarks" cols={40} rows="4"  onChange={this.updatePOremarks}></textarea>
                  </div>  &nbsp;
                  <div>
                <div className="mb-12 text-center jumbotron-icon">
                <Link to={this.state.setPrevPage} >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>                    
                    </button>
                    </Link>&nbsp;   
                <button onClick={this.findPurchaseOrder}>
                <i className="fa fa-save fa-2" aria-hidden="true"></i>      
                </button>
               
                </div>
                </div>
              </div>

              {(this.state.optionId === 2 || this.state.optionId === 3) && this.state.items.length >0 &&

                    <div>   
                      {this.state.items.map((i, index) => (
                                        <div style={scanned_items}>
                                        <div className="items-det">
                      <span className={`todo-title mr-2`} title={i.ItemCode}>
                      {i.ItemCode}
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span className={`todo-title mr-2`} title={i.Quantity}>
                      {i.Quantity}
                      </span><br />
                      
                      <span className={`todo-title mr-2`} title={i.ItemCode}>
                      From: {i.FromWarehouseCode} / {i.U_FromBinCode}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      To: {i.WarehouseCode} / {i.U_ToBinCode}
                      </span>
                    </div>   </div>    
                     ))}
                    </div>           
                    }


            
             
       
      </div>

    );

  }

}


export default CommentScreen;