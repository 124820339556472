import React,{ Component } from "react";
import { Link } from 'react-router-dom';
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";


const scanned_items = {
  height: 30,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color: "#fff",
  background:"#cfcfcf"
};

const scan_inprogress = {
  height: 30,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color:"#ffffff",
  background:"#FFA500"
};
const scan_failed = {
  height: 30,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color:"#ffffff",
  background:"rgb(255, 0, 120)"
};
const scan_partial = {
  height: 30,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color:"#ffffff",
  background:"rgb(144, 90, 110)"
};

class Stockmovementreqlist extends Component {

  state = {
    items: [],
    multipleDocitems: [],
    multipleDocEntry: [],
    hasMore: false,
    layoutName: "default",
    input: "",
    keyboardVisibility:false,
    spinner:false,
    setPrevPage:"",
    handlefindDocument: this.findDocument.bind(this, 'DocumentFind')
  };


  findDocument(refName, e) {
    //alert(e.target.value.length);
    //if (event.charCode === 13) { for enter key
    const docId = e.target.value;
    if(docId.length >= 3){
              fetch('/api/v1/stockmovment/docfind/'+docId, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                //console.log( data.id);
                //this.setState({ todoList: data })
                if(data.id > 0 ){
                window.location.replace('/stockdocdetails/'+data.DocNum);
                //console.log( data.id);
                }
                else{
                  this.errors = true;
                  //console.log( data.status);
                  alert('Invalid Document number!');
                }
              }
              );
    
      } //if
    
    };
    componentDidMount() {

      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      localStorage.setItem('StockTransferMultipleDocEntry',"");
      let backUrl ='';

      if(String(this.props.match.params.warehousecode) === 'NA' ){
    
        //console.log( objectForNewDoc);
         
        backUrl ='/stockmovementOption';         }
         else{
        backUrl ='/warehouse/stocktransferOption/'+this.props.match.params.id;
         }
        this.setState({setPrevPage: backUrl});
         
      this.refreshList();


      }


      
     
    refreshList = () => {
      const warehousecode = this.props.match.params.warehousecode; 
      const OptionId = this.props.match.params.id;
//alert(warehousecode);

this.setState({items:[], spinner: true});
    fetch('/api/v1/stockmovment/doclist/'+OptionId+'/'+localStorage.getItem('userId')+'/'+warehousecode, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}` //3006dc6ed948ee06cb55119eb2323288975548a8f93ecc099cd66786b9740993
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({spinner: false});
        console.log( data);
                    if (this.state.items.length >= 500) {
                      this.setState({ hasMore: false });
                      return;
                    }
                    // a fake async api call like which sends
                    // 20 more records in .5 secs
                    setTimeout(() => {
                      this.setState({ items: data })                     
                    }, 500);

                  })
            .catch(error => {
              this.setState({spinner: false});
              console.log( error);
            });       
        }; 
  
    onChangeEanInput = (e) => {
      //e.preventDefault();
      if(this.state.keyboardVisibility === false){
        this.setState({keyboardVisibility: true});
      }
      else{
        this.setState({keyboardVisibility: false});
      }
    
  };     


  onChange = input => {
    this.setState({
      input: input
    });
    //console.log("Input changed", input);
  };

  onKeyPress = button => {
    //console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    //alert(button);
     if(button === "Close"){
      this.keyPadClose();
     }
   // if (button === "{shift}" || button === "{lock}") this.handleShift();
      if(button === "{enter}"){
        //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
          //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
          //this.findItem.bind(this.state.input, 'DocumentItemFind')
          //this.findItem(this.state.input, 'DocumentItemFind');
          let currKeyEan = this.state.input;
          this.setState({ input: '' });
         // console.log('dddd',currKeyEan);
          this.scanItemUpdate(currKeyEan);
          
          
      }
    
  };
  keyPadClose = () => {
    // alert('s');
     this.setState({keyboardVisibility: false});
     this.setState({keyboardVisibilityQty: false});
     
     const focusField = document.querySelector(
      `input[name=doc_number]`
    );
      focusField.focus();
   
 };

  scanItemUpdate(docId) { 
    this.setState({ input: '' });  

    setTimeout(() => {
               

      fetch('/api/v1/stockmovment/docfind/'+docId, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
              }
            })
              .then(res => res.json())
              .then(data => {            
                if(data.id > 0 ){
                  window.location.replace('/stockdocdetails/'+data.DocNum);
                  //console.log( data.id);
                  }
                  else{
                    this.errors = true;
                    //console.log( data.status);
                    alert(JSON.parse(sessionStorage.getItem('currentLang'))[33]);
                  }
            }
            )
            .catch(error => {
              alert(JSON.parse(sessionStorage.getItem('currentLang'))[33]);
          });

  

  }, 500);

  }

  itemDetails = (id,DocEntry,itemStyle) => {
    const currentUser = localStorage.getItem('userId');

    //const OptionId = parseInt(this.props.match.params.id);

    //if(window.confirm('The document is currently edited by another user. Do you want to continue?')){

    /*if(userId === null || userId === parseInt(currentUser)){
      localStorage.setItem('ifCheckCurrentStockItems',1);
    
    window.location.replace('/stockdocdetails/'+OptionId+'/'+id+'/'+DocEntry);
  }
  else{
    alert(JSON.parse(sessionStorage.getItem('currentLang'))[32]);
  }*/


  var multipleDocitemsIndex = this.state.multipleDocitems.indexOf(id);
    //console.log(selectedBinLocQtyIndex);
    
      if (multipleDocitemsIndex > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
        //console.log("QTY Index: "+selectedBinLocQtyIndex);

        this.state.multipleDocitems.splice(multipleDocitemsIndex, 1);   
        this.state.multipleDocEntry.splice(multipleDocitemsIndex, 1);     
        document.getElementById('itemTap'+id).removeAttribute("style");  
        if(itemStyle === 1){
          document.getElementById('itemTap'+id).setAttribute("style","color:#ffffff;height: 30px; border: 1px solid green; margin: 3px; padding: 3px; background: #FFA500 none repeat scroll 0% 0%;");
        }
        else{
        document.getElementById('itemTap'+id).setAttribute("style","color:#ffffff;height: 30px; border: 1px solid green; margin: 3px; padding: 3px; background: #cfcfcf none repeat scroll 0% 0%;");
        }
        this.setState({itemSelected: false});
        //console.log(newScanQty);         

      }
      else{
    this.state.multipleDocitems.push(id);  
    this.state.multipleDocEntry.push(DocEntry);       
    this.setState({itemSelected: true});
    //console.log("tett");
      }    

    console.log(this.state.multipleDocitems);
    console.log(this.state.multipleDocEntry);

    if(this.state.multipleDocitems.length >0){
      for (var i=0; i < this.state.multipleDocitems.length; i++) {
        if(document.getElementById('itemTap'+this.state.multipleDocitems[i]) != null){  
        document.getElementById('itemTap'+this.state.multipleDocitems[i]).setAttribute("style","color:#ffffff;height: 30px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
      }
      
    }
  }

  }

  checkedMulDoc = () => {
    const OptionId = this.props.match.params.id;
    if(this.state.multipleDocitems.length >0){
      localStorage.setItem('ifCheckCurrentStockItems',1);
    let DocEntry = this.state.multipleDocEntry.join("+");
      window.location.replace('/stockdocdetails/'+OptionId+'/0/'+DocEntry);
    }
    else{
      alert("Please select document(s)");
      
    }
    //this.refreshList();

  }
  backToPrev = (e) => {
    e.preventDefault();
    //this.refreshList();
    localStorage.setItem('StockTransferItemFromWH','');
    localStorage.setItem('StockTransferItemToWH','');
    window.location.href=this.state.setPrevPage;

  }


  
  render() {
     


    return (

      <div>          
  
        
        <div >
             
              <span className={`todo-title mr-2`} >
              { JSON.parse(sessionStorage.getItem('currentLang'))[76]} 
              </span>
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; 
              <span className={`todo-title mr-2`} >
              { JSON.parse(sessionStorage.getItem('currentLang'))[77]} 
              </span>
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; 
              <span className="`todo-title mr-2`">
              
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; 
                &nbsp;{ JSON.parse(sessionStorage.getItem('currentLang'))[78]}: 
                </span>

              
            </div>
            <hr />
            {this.state.spinner && <div className="mb-4 scanblock-div"><h1>Loading wait.... </h1></div>}

            {this.state.items.length <=0 && !this.state.spinner && <div className="mb-4 scanblock-div"><h1>No records found!</h1></div> }
            
        <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.refreshList}
          hasMore={this.state.hasMore}
          loader={<h4>.</h4>}
          height={400}
          endMessage={""}
        >
          {this.state.items.length >0 && this.state.items.map((i, index) => (
            
            <Link to='#' onClick={input => this.itemDetails(i.DocNum,i.DocEntry,i.user_id ?  1 : 0)}>
            <div className={`itemTap`+i.DocNum} id={`itemTap`+i.DocNum} style={i.user_id && i.confirm_to_sap ===0?  scan_inprogress : i.confirm_to_sap ===4?  scan_failed : scan_partial ===5?scan_partial:scanned_items}  key={i.DocNum}   >                  
             
              <span className={`todo-title mr-2`} title={i.DocNum}>
               {i.DocNum}
              </span>
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; 
              <span className="`todo-title mr-2`">
              
              &nbsp;{i.DocDate}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; 
               {i.FromWarehouse}
                </span>

              
            </div>
            </Link>
          ))}
        </InfiniteScroll>
        <br />
       
        <div className="mb-12 text-center jumbotron-icon panel_footer">
        
        <button onClick={this.backToPrev}>
        <i className={`fa fa-arrow-left fa-2`} aria-hidden="true"></i> 
        </button>
        &nbsp;
        

        
        {localStorage.getItem('UserSelectedDocOption') === '2' && 
         <button onClick={() =>{this.tapToRemoveScannedItemQty()}} >
        <i className="fa fa-plus fa-2" aria-hidden="true"></i>
          
        </button>       }      
          &nbsp;
        
        <button onClick={this.refreshList}>
        <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
        </button>
        &nbsp;
        <button onClick={this.checkedMulDoc}>
        <i className={`fa fa-arrow-right fa-2`} aria-hidden="true"></i> 
        </button>
        </div>
      </div>

    );

  }

}


export default Stockmovementreqlist;