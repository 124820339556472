import React, { useState, Fragment } from 'react';
import Stocktabs from "../../components/AllTabs/StockMovement/Stockstabs";

const Stockmovement = () => {
  const [loading] = useState(true);
  return (
    <div>
      {loading === true && (
        <Fragment>
          <Stocktabs>
            
          </Stocktabs>
        </Fragment>
        
      )}
    </div>
  );
};

export default Stockmovement;
