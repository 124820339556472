import React,{ Component } from "react";
import { Link } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";

//import Button from 'react-bootstrap/Button';
//import BarcodeScannerComponent from "react-qr-barcode-scanner";
//import Modal from 'react-bootstrap/Modal';


const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#cfcfcf"
};

const scan_inprogress = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#FFA500"
};

/*const scan_highlight = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"blue"
};*/

class Doclist extends Component {

  state = {
    items: [],
    Fixeditems: [],
    hasMore: true,
    layoutName: "default",
    input: "",
    OlddocId: "",
    OldConfirm_to_sap: "",
    Oldusername: "",
    keyboardVisibility:false,
    handlefindDocument: this.findDocument.bind(this, 'DocumentFind'),
    webcamResult:"Not Found",
    setStopStream:false,
    spinner:false,
  };


  findDocument(refName, e) {
    //alert(e.target.value.length);
    //if (event.charCode === 13) { for enter key
    const docId = e.target.value;
    if(docId.length >= 6){
              fetch('/api/v1/documents/docfind/'+docId, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                  
                //this.setState({ todoList: data })
                if(data.id > 0 ){
                //window.location.replace('/docdetails/'+data.id);
                  if(this.state.OlddocId > 0 && this.state.OldConfirm_to_sap <= 2 && this.state.Oldusername !== null){
                document.getElementById("scanSearch_"+this.state.OlddocId).removeAttribute("style");  
                document.getElementById("scanSearch_"+this.state.OlddocId).setAttribute("style", "height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: #FFA500 none repeat scroll 0% 0%;");
                  }

                  if(this.state.OlddocId > 0 && this.state.OldConfirm_to_sap <= 2 && this.state.Oldusername === null){
                    document.getElementById("scanSearch_"+this.state.OlddocId).removeAttribute("style");  
                    document.getElementById("scanSearch_"+this.state.OlddocId).setAttribute("style", "height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: #cfcfcf none repeat scroll 0% 0%;");
                      }
                
                document.getElementById("scanSearch_"+docId).style["background-color"] = "#804000";
                document.getElementById("doc_number").value='';
                //console.log( data.id);
                this.setState({OlddocId: docId,OldConfirm_to_sap: data.confirm_to_sap,Oldusername: data.username});

                }
                else{
                  this.errors = true;
                  //console.log( data.status);
                  document.getElementById("doc_number").value='';
                  alert('Invalid Document number!');
                }
              } )
              .catch(error => {
                console.log( error);
                document.getElementById("doc_number").value='';
                  alert('Invalid Document number!');
              });  
    
      } //if
    
    };
    componentDidMount() {

      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      
      this.refreshList(0);


      }
     
    refreshList = (loadStatus) => {
      this.setState({spinner: true});
    fetch('/api/v1/documents/doclist/'+loadStatus, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({spinner: false});
        //console.log( data);
                    if (this.state.items.length >= 500) {
                      this.setState({ hasMore: false });
                      return;
                    }
                    const Binrows = [];
                  for (let i = 0; i < data.length; i++) {
                      // note: we are adding a key prop here to allow react to uniquely identify each
                      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
                      Binrows.push(data[i]);
                  }

                    // a fake async api call like which sends
                    // 20 more records in .5 secs
                    setTimeout(() => {
                      this.setState({ items: Binrows,Fixeditems:Binrows });
                                           
                    }, 500);

                  })
            .catch(error => {
              this.setState({spinner: false});
              console.log( error);
            });       
        }; 
  
    onChangeEanInput = (e) => {
      //e.preventDefault();
      if(this.state.keyboardVisibility === false){
        this.setState({keyboardVisibility: true});
      }
      else{
        this.setState({keyboardVisibility: false});
      }
    
  };     


  onChange = input => {
    this.setState({
      input: input
    });
    //console.log("Input changed", input);
  };

  onKeyPress = button => {
    //console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    //alert(button);
     if(button === "Close"){
      this.keyPadClose();
     }
   // if (button === "{shift}" || button === "{lock}") this.handleShift();
      if(button === "{enter}"){
        //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
          //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
          //this.findItem.bind(this.state.input, 'DocumentItemFind')
          //this.findItem(this.state.input, 'DocumentItemFind');
          let currKeyEan = this.state.input;
          this.setState({ input: '' });
         // console.log('dddd',currKeyEan);

         
          this.scanItemUpdate(currKeyEan);
          
          
      }
    
  };
  keyPadClose = () => {
    // alert('s');
     this.setState({keyboardVisibility: false});
     this.setState({keyboardVisibilityQty: false});
     
     const focusField = document.querySelector(
      `input[name=doc_number]`
    );
      focusField.focus();
   
 };

  scanItemUpdate(docId) { 
    this.setState({ input: '' });  

    setTimeout(() => {
               

      fetch('/api/v1/documents/docfind/'+docId, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
              }
            })
              .then(res => res.json())
              .then(data => {            
                if(data.id > 0 ){
                  //window.location.replace('/docdetails/'+data.id);
                  //console.log( data.id);

                  if(this.state.OlddocId > 0 && this.state.OldConfirm_to_sap <= 2 && this.state.Oldusername !== null){
                    document.getElementById("scanSearch_"+this.state.OlddocId).removeAttribute("style");  
                    document.getElementById("scanSearch_"+this.state.OlddocId).setAttribute("style", "height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: #FFA500 none repeat scroll 0% 0%;");
                      }
    
                      if(this.state.OlddocId > 0 && this.state.OldConfirm_to_sap <= 2 && this.state.Oldusername === null){
                        document.getElementById("scanSearch_"+this.state.OlddocId).removeAttribute("style");  
                        document.getElementById("scanSearch_"+this.state.OlddocId).setAttribute("style", "height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: #cfcfcf none repeat scroll 0% 0%;");
                          }
                    
                    document.getElementById("scanSearch_"+docId).style["background-color"] = "#804000";
                    document.getElementById("doc_number").value='';
                  }
                  else{
                    this.errors = true;
                    //console.log( data.status);
                    document.getElementById("doc_number").value='';
                    alert(JSON.parse(sessionStorage.getItem('currentLang'))[33]);
                  }
            }
            )
            .catch(error => {
              document.getElementById("doc_number").value='';
              alert(JSON.parse(sessionStorage.getItem('currentLang'))[33]);
          });

  

  }, 500);

  }

  tapToscan = () => {     

        
    this.setState({modalShow2: true});        


  };

  handleSearch = event => {

    //console.log( event.target.value);
  
    
    //console.log( users);*/
    //console.log( this.state.selectedBinLocQty);
    //console.log( this.state.items);
    let serachTerm = event.target.value.toUpperCase();
  
    this.highlightItem(serachTerm);
  
  }; 
  
  highlightItem(serachTerm) {
    
    let allItems = this.state.Fixeditems;
  
    if (serachTerm === "") { this.setState({ items: this.state.Fixeditems }); return ; } 
    
   

  console.log( allItems);
  
  
  var filterBySearch = allItems.filter((item) => {
  
    if (String(item.order_docnum).length >0){
      return String(item.order_docnum).indexOf(serachTerm) > -1;
    } 
    });
    
   if(filterBySearch.length===0){
        filterBySearch = allItems.filter((item) => {
          if (String(item.order_docentry).length >0){
            return String(item.order_docentry).indexOf(serachTerm) > -1;    
            }  
          });
        }        
    if(filterBySearch.length===0){
        filterBySearch = allItems.filter((item) => {
          console.log( item.picklist_id);
          if (String(item.picklist_id).length >0){
            return String(item.picklist_id).indexOf(serachTerm) > -1;    
            }  
          });
        } 
  


  console.log( filterBySearch);
  this.setState({ items: filterBySearch });
  
  
    
  }; 


  
  render() {
    async function docDetails(id, userId) {
      const currentUser = localStorage.getItem('username');

      //if(window.confirm('The document is currently edited by another user. Do you want to continue?')){

      //if(userId === null || userId === currentUser){
        if(userId === null || userId === currentUser){
      window.location.replace('/docdetails/'+id);
        }
         else if (window.confirm("The document is currently edited by another user. Do you want to continue?")) {
      window.location.replace('/docdetails/'+id);
        }
    /*}
    else{
      alert(JSON.parse(sessionStorage.getItem('currentLang'))[32]);
    }*/

  }   
  if (this.state.spinner) return ( <div className="mb-4 scanblock-div"><h1>Loading wait.... </h1></div>);

    return (
      

      <div>

        <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[9]}</h1>
            <div className="mb-4">
            <input
          name='doc_number'
          id='doc_number'
          type='text'
          placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[10]}
          //onKeyUp={this.state.handlefindDocument}
          onChange={doc_number => this.handleSearch(doc_number)}
          ref={(DocumentFind) => {DocumentFind && DocumentFind.focus() }} 
        />   
         {/*<button onClick={() =>{this.onChangeEanInput()}} >{ JSON.parse(sessionStorage.getItem('currentLang'))[11]}</button>*/}
              </div>

              {this.state.items.length <=0 && <div className="mb-4 scanblock-div"><h1>Loading wait.... </h1></div>}

              {this.state.keyboardVisibility && (
                          <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            theme={"hg-theme-default myTheme1"}
                            layoutName={this.state.layoutName}
                            onChange={input => this.onChange(input)}
                            onKeyPress={button => this.onKeyPress(button)}
                            //onBlur={clse => this.keyPadClose(clse)}
                           // min={13}
                           buttonTheme={[
                            {
                              class: "hg-red",
                              buttons: "Close"
                            },
                            {
                              class: "hg-highlight",
                              buttons: "Close"
                            }
                          ]}
                            layout={{
                              default: [
                                "Close",
                                "1 2 3 4 5 6 7 8 9 0 - {bksp}",
                                "Q W E R T Y U I O P",
                                "A S D F G H J K L {enter}",
                                "Z X C V B N M {space}"
                              ],
                              shift: [
                                "Q W E R T Y U I O P - {bksp}",
                                'A S D F G H J K L {enter}',
                                "Z X C V B N M {shift}"
                              ]
                            }}
                          />
                          )}     
        <hr />
        <InfiniteScroll
          dataLength={this.state.items.length}
         
          height={400}
         
        >
          {this.state.items.map((i, index) => (
            
            <Link to='#' onClick={ () => { docDetails(i.id, i.username) }}>
            <div id={"scanSearch_"+i.order_docnum} style={i.username ?  scan_inprogress : scanned_items} >
             
              <span className={`todo-title mr-2`} title={i.description}>
              {/*{ JSON.parse(sessionStorage.getItem('currentLang'))[12]}: */}
               {i.picklist_id} &nbsp;{i.username !==null && <span> <i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> {i.username}</span>}
              </span><br />

              <span className="`todo-title mr-2`">
              <i className="fa fa-calendar" aria-hidden="true"></i>
                {i.picklist_date}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<i className="fa fa-user-o" aria-hidden="true"></i>
                &nbsp;{i.pickListname}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<i className="fa fa-shopping-bag" aria-hidden="true"></i>
                &nbsp;{i.cardcode}
                </span><br />
                {i.Comments !==null && 
                <span className="`todo-title mr-2`" >
                <i className="fa fa-info-circle" aria-hidden="true"></i>
                &nbsp;{i.Comments.substring(0, 10)} (...)
              </span>}

              
            </div>
            </Link>
          ))}
        </InfiniteScroll>
        <br />
        <div className="mb-12 text-center jumbotron-icon">
        <button onClick={() =>{this.refreshList(1)}}>
        <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
        </button>
        </div>
      </div>

    );

  }

}


export default Doclist;