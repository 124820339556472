import React,{ Component } from "react";
import { Link } from 'react-router-dom';


const stockTrans_option = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#FFA500"
};

class StockcountingOption extends Component {

  state = {
    layoutName: "default",
    input: "",
    cardCode: "",
    cardName: 'NA',
  };

     
    componentDidMount() {
      
      localStorage.setItem('UserSelectedStockCountingOption', 2);//0
      localStorage.setItem('StockTransferItemFromWH','');
      localStorage.setItem('StockTransferItemToWH','');

      }


    readCommentConfirm = () => {
      localStorage.setItem('UserSelectedStockCountingOption', 1);
      window.location.href="/stockcountinglist";

          };       

  render() {
    
    return (

      <div>
        
            <div className="mb-4" >          
              <div className="mb-12 text-center jumbotron-icon">
              
             <span style={stockTrans_option}> <Link to='/warehouse/stockcountingOption/2' >Warehouse Wise</Link> </span>
                </div>  &nbsp;
                  <div className="mb-12 text-center jumbotron-icon">
                  <button onClick={this.readCommentConfirm}>Item Wise</button>
                  </div>  &nbsp;
                  <div>
                    <br/><br/><br/>
                <div className="mb-12 text-center jumbotron-icon">
                <span style={stockTrans_option}> <Link to='/dashboard' >Back </Link> </span>   
                </div>
                </div>
              </div>

             
       
      </div>

    );

  }

}


export default StockcountingOption;