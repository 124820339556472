//import React from 'react';
import Papa from "papaparse";

import LangEnTrans from '../../ingold/csv/en.csv';
import LangDeTrans from '../../ingold/csv/de.csv';
import LangPlTrans from '../../ingold/csv/pl.csv';

const authCheck = (lang) => {
  let LangTrans=LangEnTrans;
  if(lang === 'de'){
    LangTrans=LangDeTrans;
  }
  else if(lang === 'pl'){
    LangTrans=LangPlTrans;
  }
else{
  LangTrans=LangEnTrans;
}
 GetData(LangTrans);

// console.log(JSON.stringify( valuesArray));
//sessionStorage.setItem('currentLang', JSON.stringify( csvData));
//https://stackoverflow.com/questions/61419710/how-to-import-a-csv-file-in-reactjs
/*Papa.parse(LangTrans, {
    download: true,
    delimiter: '\t',
    complete: function(results) {     
      //console.log(results.data);
      
      //const rowsArray = [];
      const valuesArray = [];

      // Iterating data to get column name and their values
      //const decoder = new TextDecoder('ISO-8859-1');
      //let csvOP = decoder.decode(results.data);
      
      results.data.map((d) => (
       // rowsArray.push(Object.keys(d));
      
        valuesArray.push(Object.values(d))        
      ));
      console.log(valuesArray);

     
     sessionStorage.setItem('currentLang', JSON.stringify( valuesArray));
     
    console.log(localStorage.getItem('currentLang'));
    }
  });*/

    async function GetData(LangTrans) {
    //console.log(LangTrans);
    const res = Papa.parse(await fetchCsv(LangTrans));
    console.log(res.data);
    sessionStorage.setItem('currentLang', JSON.stringify( res.data));
    
    //console.log(JSON.parse(sessionStorage.getItem('currentLang')));
    return res;
}

  async function fetchCsv(LangTrans) {
   // const valuesArray = [];

    const response = await fetch(LangTrans);
    const reader = response.body.getReader();
    const result = await reader.read();
    const decoder = new TextDecoder('UTF-8');//iso-8859-1
    const res = await decoder.decode(result.value);

    //res.data.map((d) => (
      // rowsArray.push(Object.keys(d));
       //valuesArray.push(Object.values(d))        
     //));

    //console.log('csv', res);
    return res;
}

  
  
  }

  
  
  export default authCheck;