import React,{ Component } from "react";
import { Link } from 'react-router-dom';
class CommentScreen extends Component {

  state = {
    allSeries: [],
    SeriesNo:'',
    referenceNo: '',
    POremarks: '',
    messageShow: ''
  };

  componentDidMount() {
    //alert(localStorage.getItem('VendorCardCode'));
    //const optionId = this.props.match.params.optionId;

    //if (event.charCode === 13) { for enter key
              fetch('/api/v1/goodsreceived/getSeriesList', { // used the master grpo list
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                //console.log( data);
                //this.setState({ todoList: data })
                if(data){
                  this.setState({ allSeries: data })
                //window.location.replace('/docdetails/'+data.id);
                }
                
              }
              ).catch(error => {
                this.setState({ cardName: 'Invalid Series number!' })
            });  
    
      } //if
    
      updateSeriesNo = (e) => {        
        //console.log(e.target);
        if (e.target!== '') {
          this.setState({
            SeriesNo: e.target.value
          });      
        }     
    
      }

      updateReferenceNo = (e) => {        
        //console.log(e.target);
        if (e.target!== '') {
          this.setState({
            referenceNo: e.target.value
          });      
        }     
    
      }

      updatePOremarks = (e) => {        
        //console.log(e.target);
        if (e.target!== '') {
          this.setState({
            POremarks: e.target.value
          });      
        }     
    
      }


    findPurchaseOrder = () => {
    //alert(this.state.referenceNo);
    if(this.state.referenceNo !== ''){      
      //alert(localStorage.getItem('VendorCardCode'));    
      const optionId = this.props.match.params.optionId; 
      const postData = {
        user_id: localStorage.getItem('userId'),
        vendorcardcode: localStorage.getItem('GoodsReceiptVendorCardCode'),
        Vendorsdocentry: localStorage.getItem('GoodsReceiptDocNumber'),
        UserReceiptOption: localStorage.getItem('UserReceiptOption'),
        SeriesNo: this.state.SeriesNo,
        //referenceNo: this.state.referenceNo,
        POremarks: this.state.POremarks,
        DocNum: localStorage.getItem('DocNum'), 
        DocEntry: localStorage.getItem('DocEntry')
      };
      alert(postData);
      fetch('/api/v1/goodsreceipt/grpoPosting', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(postData)
      })
        .then(res => res.json())
        .then(data => {
          //console.log( data);  
          this.setState({ messageShow: "GRPO successfully sent to SAP!" });
          localStorage.setItem('GoodsReceiptDocNumber','');
          localStorage.setItem('UserReceiptOption','');
          localStorage.setItem('SeriesNo','');
          localStorage.setItem('DocNum','');  
          localStorage.setItem('DocEntry','');
          localStorage.setItem('ItemQTY','');  
          if(parseInt(optionId) === 2){
          window.location.replace('/Goodsreceipt');
          }
        
      }
      )
      .catch(error => {
        console.log( error);
        });  


    }
        }




  render() {
    
    return (

      <div>
        <div className="mb-4" >{this.state.messageShow} </div>
            <div className="mb-4" >          
           
              <div className="mb-12 text-center jumbotron-icon">
              { JSON.parse(sessionStorage.getItem('currentLang'))[50]}&nbsp;
              
              <select name="seriesCode" id="seriesCode"  onChange={this.updateSeriesNo}>    
              <option value={0}>Select</option>
              {this.state.allSeries.map((i, index) => (
              <option value={i.Series}>{i.Series}</option>
              ))}
              </select>
                </div>  &nbsp;


                  {/*<div className="mb-12 text-center jumbotron-icon">
                  { JSON.parse(sessionStorage.getItem('currentLang'))[51]}&nbsp;                   
                  <textarea name="referenceNo" id="referenceNo" cols={40} rows="1"  onChange={this.updateReferenceNo}></textarea>
              </div>  &nbsp;*/}
                  <div className="mb-12 text-center jumbotron-icon">
                  { JSON.parse(sessionStorage.getItem('currentLang'))[52]}&nbsp; 
                      <textarea name="POremarks" id="POremarks" cols={40} rows="4"  onChange={this.updatePOremarks}></textarea>
                  </div>  &nbsp;
                  <div>
                <div className="mb-12 text-center jumbotron-icon">
                <button onClick={this.findPurchaseOrder}>
                { JSON.parse(sessionStorage.getItem('currentLang'))[53]}
                </button>
                &nbsp;
                <Link to={location => ({ ...location, pathname: "/goodsreceiptdetails/"+localStorage.getItem('DocNum')+'/'+localStorage.getItem('DocEntry') })}>
                <button >
                { JSON.parse(sessionStorage.getItem('currentLang'))[54]}
                </button>
                </Link> 
                </div>
                </div>
              </div>

             
       
      </div>

    );

  }

}


export default CommentScreen;