import React,{ Component } from "react";
import { Link } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";


const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#fff"
};

const scan_inprogress = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#FFA500"
};

class GoodsreceiptMydoclist extends Component {

  state = {
    items: [],
    hasMore: true,
  };


  
    componentDidMount() {
      //alert(1);
      //this.refreshList2();


      }
     
    refreshList2 = () => {

    fetch('/api/v1/goodsreceipt/mylist/'+localStorage.getItem('userId'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        console.log( data);
                //alert('f');    
                    // a fake async api call like which sends
                    // 20 more records in .5 secs
                    setTimeout(() => {
                      this.setState({ items: data })                     
                    }, 500);

                  });     
        }; 
       
  render() {
    async function removeItem(id, userId) {
      const currentUser = localStorage.getItem('userId');

      //if(window.confirm('The document is currently edited by another user. Do you want to continue?')){

      if(userId === null || userId === parseInt(currentUser)){
      window.location.replace('goodsreceiptdetails/'+id);
    }
    else{
      alert(JSON.parse(sessionStorage.getItem('currentLang'))[32]);
    }

  }




    return (

      <div>
       
        
       <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.refreshList}
          hasMore={this.state.hasMore}
          loader={<h4>.</h4>}
          height={400}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {this.state.items.map((i, index) => (
            
            <Link to='#' onClick={ () => { removeItem(i.DocNum, i.user_id) }}>
            <div style={i.user_id ?  scan_inprogress : scanned_items} key={index}>
             
              <span className={`todo-title mr-2`} title={i.DocNum}>
              { JSON.parse(sessionStorage.getItem('currentLang'))[12]}:  {i.DocNum}
              </span><br />

              <span className="`todo-title mr-2`">
              <i className="fa fa-calendar" aria-hidden="true"></i>
                {i.DocDate}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<i className="fa fa-user-o" aria-hidden="true"></i>
                &nbsp;{i.DocEntry}
                </span>

              
            </div>
            </Link>
          ))}
        </InfiniteScroll>
        <br />
        <div className="mb-12 text-center jumbotron-icon">
        <button onClick={this.refreshList2}>
        <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
        </button>
        </div>
      </div>

    );

  }

}


export default GoodsreceiptMydoclist;