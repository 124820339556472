import React,{ Component } from "react";
import { Link } from 'react-router-dom';


const stockTrans_option = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#FFA500"
};

class GoodsReceiptOption extends Component {

  state = {
    layoutName: "default",
    input: "",
    cardCode: "",
    cardName: 'NA',
  };

     
    componentDidMount() {
      localStorage.setItem('UserReceiptOption', 0);


      }


    readCommentConfirm = (returnType) => {
      //alert(returnType);
      if (returnType > 0) {
        
        localStorage.setItem('UserReceiptOption', returnType);

        window.location.replace('/goodsReceiptVendor');

          }

          };       

  render() {
    
    return (

      <div>
        
            <div className="mb-4" >          
            <div className="mb-12 text-center jumbotron-icon">
                  <span style={stockTrans_option}>
                  <Link to='#' onClick={() =>{this.readCommentConfirm(2)}}>
                  { JSON.parse(sessionStorage.getItem('currentLang'))[85]} </Link>
                  </span>  
                  </div>  &nbsp;
              <div className="mb-12 text-center jumbotron-icon">
              
             <span style={stockTrans_option}> <Link  onClick={() =>{this.readCommentConfirm(1)}} >{ JSON.parse(sessionStorage.getItem('currentLang'))[86]} </Link> </span>
                </div>  &nbsp;
                  
                  <div>
                    <br/><br/><br/>
                <div className="mb-12 text-center jumbotron-icon">
                <span style={stockTrans_option}> <Link to='/dashboard' >Back </Link> </span>   
                </div>
                </div>
              </div>

             
       
      </div>

    );

  }

}


export default GoodsReceiptOption;