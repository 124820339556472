import React, { useState, Fragment } from 'react';
//import { Link } from 'react-router-dom';

import Doctabs from "../../components/AllTabs/Doctabs";

const Pickandpack = () => {
const [loading] = useState(true);

  

  return (
    <div>
      {loading === true && (
        <Fragment>
          
          <Doctabs>
            
          </Doctabs>
        
        </Fragment>
        
      )}
    </div>
  );
};

export default Pickandpack;
