import React, { useState, Fragment } from 'react';
import Goodstabs from "../../components/AllTabs/GoodsReceived/Goodstabs";

const Goodsreceive = () => {
  const [loading] = useState(true);
  return (
    <div>
      {loading === true && (
        <Fragment>
          <Goodstabs>
            
          </Goodstabs>
        </Fragment>
        
      )}
    </div>
  );
};

export default Goodsreceive;
