import React,{ Component } from "react";
import { Link } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color: "#fff",
  background:"green"
};



class GoodsMySyncList extends Component {

  state = {
    items: [],
    Fixeditems: [],
    hasMore: true,
    modalShow:false,
    DocNum:'',
    Docitems: [],
  };


  
    componentDidMount() {
      //alert(1);
      this.refreshList4();


      }
     
      refreshList4 = () => {

    fetch('/api/v1/goodsreceipt/mySyncList/'+localStorage.getItem('userId')+'/3', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        //console.log( data);
                //alert('f');    
                    // a fake async api call like which sends
                    // 20 more records in .5 secs
                    setTimeout(() => {
                      this.setState({ items: data,Fixeditems:data })                     
                    }, 500);

                  });     
        }; 
       
        handleSearch = event => {
        let serachTerm = event.target.value.toUpperCase();
        if(serachTerm.length >= 3){
          this.highlightItem(serachTerm);
        }
        else{
          this.setState({ items: this.state.Fixeditems });
        }
        
        }; 
        
        highlightItem(serachTerm) {
  
          let allItems = this.state.Fixeditems;
        
          if (serachTerm === "") { this.setState({ items: this.state.Fixeditems }); return ; }
        
          //console.log( allItems);    
          
          let filterBySearch = allItems.filter((item) => {
            if (item.DocNum != null && item.DocNum.toString().includes(serachTerm)) { return item; }
             
                
        })      
        
       
        
        //console.log( filterBySearch);
        this.setState({ items: filterBySearch });
        
        
          
        }; 

        onItemTap = (e,idSeltd,docNum,docEntry) => { 
          this.setState({ DocNum: docNum })   

          fetch('/api/v1/goodsreceipt/showItems/'+docNum, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          })
            .then(res => res.json())
            .then(data => {
              //console.log( data);

              this.setState({Docitems: data}); 

                      this.setState({modalShow: true}); 
                          
      
                        }); 

         
        };

        render() {
    
          function MydModalWithGridGRCPItems(props) {
            //this.callBinlocations();
        //alert(props.onHide)
        console.log( props.DocItems);
            return (
              <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                
                <Modal.Body className={`show-grid`}>         
                <h1>WMS ID: { props.DocNum}</h1>
                
                <div className="col-xl-12">
                  <ul>
                  <li><b>Item Code &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bin(QTY) </b>
                    
                    </li>  
                 { props.DocItems.map(item => (
                    <li>{item.ItemCode}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.U_ToBinCode} &nbsp;(<b>{item.initial_Quantity}</b>)
                    
                    </li>  
                    ))}
                    </ul>
                </div>
                 
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={props.onHide}>Cancel</Button>           
        
                </Modal.Footer>
              </Modal>
            );
          }


    return (

      <div>

<MydModalWithGridGRCPItems show={this.state.modalShow} onHide={() => this.setState({modalShow: false})} DocNum={this.state.DocNum} DocItems={this.state.Docitems}/>

                              <div className="mb-2">
                              {/*<a href="# "  onClick={() =>{this.tapToscan()}} ><i className="fa fa-barcode fa-2" aria-hidden="true"></i> </a>
                              &nbsp; &nbsp;*/}
                                    <input
                                  name='item_ean'
                                  id='item_ean'
                                  type='text'
                                  placeholder='Search Doc Number'
                                // readOnly={true}
                                  ref={(item_ean) => {item_ean && item_ean.focus() }} 
                                  //value={this.state.scanned_ean_code}
                                  //value={this.state.input}
                                  
                                  //onChange={item_ean => this.onChangeInput(item_ean)}
                                  onChange={item_ean => this.handleSearch(item_ean)}
                                />   
                                
                          </div>
       
        
        <InfiniteScroll
          dataLength={this.state.items.length}
         
          height={400}
          
        >
           {this.state.items.length <=0 && (
            <div >
              <span className="`todo-title mr-2`">Not found</span>
              </div>
           )}
          {this.state.items.length >0 && (
          this.state.items.map((i, index) => (

            <Link to='#'  onClick={input => this.onItemTap(input, i.id, i.DocNum, i.DocEntry)}>

            <div style={scanned_items} key={index}>
             
             <span className={`todo-title mr-2`} title={i.DocNum}>
              { JSON.parse(sessionStorage.getItem('currentLang'))[12]}:  {i.DocNum}
              </span><br />

              <span className="`todo-title mr-2`">
              <i className="fa fa-calendar" aria-hidden="true"></i>
                {i.DocDate}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<i className="fa fa-user-o" aria-hidden="true"></i>
                &nbsp;{i.username}
                </span>

              
            </div>
            </Link> 
          ))
          )}
        </InfiniteScroll>
        <br />
        <div className="mb-12 text-center jumbotron-icon">
        <button onClick={this.refreshList4}>
        <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
        </button>
        </div>
      </div>

    );

  }

}


export default GoodsMySyncList;