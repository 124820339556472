import React,{ Component } from "react";
import { Link  } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
//import NumericInput from 'react-numeric-input';
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";
//import IncDecCounter from "../../components/IncDecCounter";

import Button from 'react-bootstrap/Button';
//import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

//import BarcodeScannerComponent from "react-qr-barcode-scanner";
  
const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"green"
};

const scanned_pending = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"rgb(132, 126, 126)",
  color:"#ffffff"
};





class StockQtyBinLoc extends Component {

  state = {
    items: [],
    Fixeditems: [],
    itemsShow:{ display: 'block' },
    filterItemsShow:{ display: 'none' },
    itemDet: [],
    spinner:false,
    hasQty: false,
    upScanQty: false,
    totalOrderQty: 0,
    qtyButton: JSON.parse(sessionStorage.getItem('currentLang'))[66],
    fullScrenButton: 'Full Screen',
    handlefindItem: this.findItem.bind(this, 'DocumentItemFind'),
    //handlefindItemInput:this.findItem.bind(this, 'DocumentItemFindInput'),
    total_scanned_item:0,
    scanned_ean_code:null,
    hasFullScrn: false,
    visibility: "block",
    layoutName: "default",
    input: "",
    input3:"",
    qtyInput:1,
    keyboardVisibility:false,
    keyboardVisibilityQty:false,
    keyboardVisibilityScanQty:false,
    itemSelectedPrevId:0,
    itemSelectedPrevQty:0,
    itemSelected:false,
    itemSelectedAvailQty:0,
    itemSelectedBgColor:'tapping_bg_color',
    modalShow:false,
    incnum: 0,
    incnumBin: "",
    incnumBinInputOpen: false, //true after submite QTY
    maxBinQtySelect:0,
    selectedBinLocQty:[],
    searchedData:[],
    selectedDistNumbers:[],
    selectedActBinLoc:[],
    selectedBinData:[],
    selectedActDistNum:[],
    customSelectedBinQty:[],
    selectedBinLocName:"",
    selectedBatchName:"",
    webcamResult:"Not Found",
    setStopStream:false,
    setPrevPage:'',
    pageFrom:String(this.props.match.params.pageFrom),
    isBatchEnabled:this.props.match.params.isPolskyNumber,
  };

  findStockQtyBinLoc() {
   
    //if (event.charCode === 13) { for enter key
    const itemId = this.props.match.params.id;
    //const isPolskyNum = this.props.match.params.isPolskyNumber;
    const itemCode = this.props.match.params.itemcode;   
        

    const postData = {
      itemId: itemId,
      isPolsky: this.state.isBatchEnabled,
      itemCode: itemCode,
      pageFrom:this.state.pageFrom,
      wareHouseCode:null,
      StockTransferItemToWH:null,
      BaseEntry:localStorage.getItem('BaseEntry'),
      AbsEntry:localStorage.getItem('AbsEntry'),
    };
    
    var ajaxUrl = '/api/v1/fetchBinLocQty';
    //alert(ajaxUrl);
    //console.log( postData);
    this.setState({spinner: true});
    if(itemId.length > 0){
              fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(postData)
              })
                .then(res => res.json())
                .then(data => {
                  console.log( data);

                  let Binrows = [];
                  for (let i = 0; i < data.docItemListLength; i++) {
                      // note: we are adding a key prop here to allow react to uniquely identify each
                      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
                      Binrows.push(data.docItemList[i]);
                  }
                  console.log( data.itemDet.documentslist_id);
                  this.setState({spinner: false});
                  //console.log( data.docItemList[0]);
                // console.log( data.docItemList.replace("/",""));
                //console.log( data.docItemList[0][0]);
                  this.setState({totalOrderQty: data.itemDet.quantity});
                  let availQty = data.itemDet.quantity - data.itemDet.scanned_qty;
                  this.setState({itemSelectedAvailQty: availQty});

                  let backUrl = '';
                  if(this.state.pageFrom === "stockTransfer"){
                    //alert(String(pageFrom));
                    backUrl = '/stockdocdetails/'+this.state.isBatchEnabled+'/'+data.itemDet.DocNum+'/'+data.itemDet.DocEntry
                  
                  }
                  else{
                    backUrl = '/docdetails/'+data.itemDet.documentslist_id;
                  
                  }
                  
                  this.setState({itemDet: data.itemDet, items: Binrows, Fixeditems:Binrows,setPrevPage:backUrl});
                 
                 

                  //const data2 = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
                //console.log( data.docItemList[0]['Items/ItemWarehouseInfoCollection']);
                //console.log( this.state.items[0]['Items/ItemWarehouseInfoCollection']);



                   //console.log( this.state.itemDet.documentslist_id)
                //this.setState({ docItem: data.document, items: data.docItemList, total_scanned_item: data.total_scanned_item });
               
                
              }
              ).catch(error => {
                
                console.log( error);
                this.setState({spinner: false});
            });
    
      } //if
    
    };

    

    handleSearch = event => {
      
      let serachTerm = event.target.value.toUpperCase();

      this.highlightItem(serachTerm);

    }; 

    highlightItem(serachTerm) {
      let allItems = this.state.Fixeditems;
      //console.log( allItems);
      let selectedBinLoc = allItems
      .filter(binLoc => binLoc.BinCode.indexOf(serachTerm) !== -1 );
      if(selectedBinLoc.length <=0){
        selectedBinLoc =allItems
        .filter(binLoc => binLoc.DistNumber.indexOf(serachTerm) !== -1 );
      }
      console.log( selectedBinLoc);
      //setSearchedData(data);
      //const data3 = users.filter(
        //user => user.name.indexOf(event.target.value) !== -1
      //); Fixeditems
      this.setState({ searchedData: selectedBinLoc });

      //document.getElementById("item_ean").value='';
      if(selectedBinLoc.length <=0){
        alert("Bin location not found!");
      }
      /*if(this.state.searchedData.length <=0){
      for (var j=0; j < this.state.selectedBinLocQty; j++) {
        if(document.getElementById('itemTap'+this.state.selectedBinLocQty[j]) != null){           
        document.getElementById('itemTap'+this.state.selectedBinLocQty[j]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
        }
        if(document.getElementById('itemTap2-'+this.state.selectedBinLocQty[j]) != null){           
          document.getElementById('itemTap2-'+this.state.selectedBinLocQty[j]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
          }
      }
    }
    else{
      for (var k=0; k < this.state.selectedBinLocQty; k++) {
        if(document.getElementById('itemTap'+this.state.selectedBinLocQty[k]) != null){           
        document.getElementById('itemTap'+this.state.selectedBinLocQty[k]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
        }
        if(document.getElementById('itemTap2-'+this.state.selectedBinLocQty[k]) != null){           
          document.getElementById('itemTap2-'+this.state.selectedBinLocQty[k]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
          }
        
      }      
    }*/

      /*if(selectedBinLoc.length >0){
      this.setState({ items: selectedBinLoc });
      }
      else{
        this.setState({ items: selectedBinLoc });
      }
      const BLOCK = { display: 'block' }
      const NONE= { display: 'none' }

      

      this.setState({ itemsShow: NONE });
      this.setState({ filterItemsShow: BLOCK });*/
      
      //console.log( selectedBinLoc);
      
    }; 

    /*showSearchedData = () => {
      return this.state.searchedData.map(binLoc => (
        <div key={binLoc.BinCode}>

          <p id="mailid">{binLoc.BinCode} </p>
        </div>
      ));
      }*/
      
      tapToQtyEdit = () => {
      
      //this.setState({ scanned_ean_code: eancode })
     // alert(2);
      //if(this.state.scanned_ean_code.length > 8){
        //this.findItem.bind(this.state.scanned_ean_code.length, 'DocumentItemFind');
        //alert(eancode);
        //return 1;
          // }
          this.setState({ upScanQty: false });
          //this.setState({ itemSelected: true });
          this.setState({modalShow: true});        
          
          /*const focusField = document.querySelector(
            `input[name=increamentValue]`
          );      
            focusField.focus();*/

        };

   
  
  tapToUpdateScannedItemQty = () => {    
    this.setState({ hasQty: false });
    if(this.state.itemSelected === true){ 

          this.setState({upScanQty: true});
          //alert(this.state.input3);
          //alert(this.state.totalOrderQty);
        if(this.state.input3 > 0 ){ 
          //if(this.state.input3 <= this.state.totalOrderQty  ){ 
          fetch('/api/v1/documents/ScanItemQtyUpdate/'+this.state.itemSelectedPrevId+'/'+this.state.input3, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          })
          .then(res => res.json())
          .then(data => {
            //console.log( data);
            this.findDocument();                             
                    
                      //window.location.replace('/pickandpack');
                    });  
           //}
           //else{
            //alert( JSON.parse(sessionStorage.getItem('currentLang'))[43]);
           //}      
        }      
      else{
        alert( JSON.parse(sessionStorage.getItem('currentLang'))[42]);
      }
    }
    else{
      alert( JSON.parse(sessionStorage.getItem('currentLang'))[20]);
    }
    //this.findDocument();
  };   
  
  saveBinLocToDB = () => {

      const itemId = this.props.match.params.id;
      //const isPolskyNum = this.props.match.params.isPolskyNumber;
      const itemCode = this.props.match.params.itemcode;      
      let scanndQty = this.state.total_scanned_item < this.state.totalOrderQty ? this.state.total_scanned_item : this.state.totalOrderQty
      
      const postData = {
        itemId: itemId,
        isPolskyNum: this.state.isBatchEnabled,
        itemCode: itemCode,
        selectedBinLoc:this.state.selectedActBinLoc,
        selectedDistNum:this.state.selectedActDistNum,
        selectedBinData: this.state.selectedBinData,
        scanndQty:scanndQty,
      };
      //console.log(this.state.selectedBinData);
      //console.log(this.state.selectedActBinLoc);
      //console.log(this.state.selectedActDistNum);
      
    
      fetch('/api/v1/documents/saveItemBinLoc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(postData)
      })
      .then(res => res.json())
      .then(data => {
        console.log( data);
        //this.findDocument();                             
                  if(data.status === 1){
                    alert( JSON.parse(sessionStorage.getItem('currentLang'))[71]);
                  }
                  window.location.replace('/docdetails/'+this.state.itemDet.documentslist_id);
                })
          .catch(error => {            
            console.log( JSON.parse(sessionStorage.getItem('currentLang'))[72]);
            window.location.replace('/docdetails/'+this.state.itemDet.documentslist_id);
           
        });          

    
    
  };



    
    findItem(refName, e) {
   
      //if (event.charCode === 13) { for enter key
      let eanNum = e.target.value;
      
      //e.preventDefault();
      //loader load
      
      //if(eanNum.length >= 13){
        if(eanNum.length >= 5){


      //document.getElementById('item_ean').value='';      
      
      //this.setState({ scanned_ean_code: eanNum });
      //alert('s');
     // this.setState({ scanned_ean_code: null });
     //if(eanNum === ''){
      //eanNum = this.state.scanned_ean_code;
     //}
     console.log(eanNum);

          this.scanItemUpdate(eanNum,0);

        } //if

      };
      
      scanItemUpdate(eanNum,isQtyFld) { 
        

        setTimeout(() => {
                   
          const docId = this.props.match.params.id;
          let scanQty =this.state.qtyInput;
          //alert(eanNum); //.replaceAll(" ","-")
          let callUrl = '/api/v1/documents/itemfind/'+docId+'/'+eanNum+'/'+scanQty+'/'+parseInt(isQtyFld);
          fetch(callUrl, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                  }
                })
                  .then(res => res.json())
                  .then(data => {
                    //alert(this.state.input);
                 //console.log( data);
                if( this.state.itemSelectedPrevQty > 0 ){
                this.setState({itemSelectedPrevQty: this.state.itemSelectedPrevQty-1});
                }
                  // document.getElementById('item_ean').value='';
                    //document.getElementById('item_qty_input').value='';
                    if(this.state.itemSelectedPrevId >0 && this.state.itemSelectedPrevQty > 0 ){
                    document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");  
                    }
                    //else{
                      //document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: green none repeat scroll 0% 0%;");  
                    //}
                    // this.setState({ scanned_ean_code: null })
                  if(data.id > 0 ){
                   // this.setState({ scanned_ean_code: null });
                    this.findDocument();
                    //console.log( data.id);
                  }
                  else{
                    
                    
                    this.setState({ input: "" });
                    this.setState({ scanned_ean_code: null });

                    alert("EAN Code not exist!");
                    //console.log( data.status);
                    //alert('Invalid EAN!');
                    this.errors = true;
                  }
                  this.setState({ input: "" });
                 // this.setState({ scanned_ean_code: null });
                  this.setState({ itemSelected: false });
                  //alert(this.state.input);
                }
                )
                .catch(error => {
                  this.setState({ input: '' });
                  //alert(this.state.input);
                  this.setState({ scanned_ean_code: null });
                  alert("EAN Code not exist!");
              });  

      

      }, 500);

      }
     
    componentDidMount() {
      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      //const { studentId } = this.props.match.params;
      //console.log( this.props.match.params);
      //this.refreshList();
      this.findStockQtyBinLoc();
      }
     
    refreshList = () => {

    fetch('/api/v1/documents/docitems', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
       // console.log( data);
                    
                    setTimeout(() => {
                      this.setState({ items: data })                     
                    }, 500);

                  });     
        }; 


        onChangeKeyboard = inputk => {
          //console.log('SB1: '+this.state.input);
          //console.log('SB2: '+inputk);
          
          this.setState({
            input: inputk
          });
          //console.log("Input changed", inputk);
        };
      
        onKeyPress = button => {
          this.setState({ upScanQty: false });
          //console.log("Button pressed", button);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
           if(button === "Close"){
            this.setState({ input: '' });
            this.keyPadClose();
           }
         // if (button === "{shift}" || button === "{lock}") this.handleShift();
            if(button === "{enter}"){
              
              //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
                //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
                //this.findItem.bind(this.state.input, 'DocumentItemFind')
                //this.findItem(this.state.input, 'DocumentItemFind');
                let currKeyEan = this.state.input;
                //console.log('Keypad1: ',this.state.input);
                
                this.setState({ input: '' });
                
                //console.log('Keypad2: ',currKeyEan);
                this.scanItemUpdate(currKeyEan,0);
                this.keyPadClose();
                
                
            }
          
        };
        

        onChangeQty = input2 => {
          this.setState({ upScanQty: false });
          
          //alert(input2);
              this.setState({
                input2: input2
              });
              //console.log("Input changed", input2);
        };
      
             
      
        handleShift = () => {
          let layoutName = this.state.layoutName;
      
          this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
          });
        };
      
        onChangeInput = event => {
          let input = event.target.value;
          //alert(input);
          console.log("Button pressed", input);
          this.setState(
            {
              input: input
            },
            
            () => {
              return true;
              //this.keyboard.setInput(input);
              //this.keyboard.setState({ input: input });
            }
          );
        };

        
        onChangeEanInput = (e) => {
          //e.preventDefault();
          this.setState({ hasQty: false });
          this.setState({ upScanQty: false });
          this.setState({keyboardVisibilityQty: false});
          this.setState({keyboardVisibilityScanQty: false});

          if(this.state.keyboardVisibility === false){
            this.setState({keyboardVisibility: true});
          }
          else{
            this.setState({keyboardVisibility: false});
          }
        
      };

        onChangeQtyInput = e => {
         // alert('s');
         this.setState({ upScanQty: false });
         
          this.setState({keyboardVisibility: false});
          this.setState({keyboardVisibilityScanQty: false});
          if(this.state.keyboardVisibilityQty === false){
            this.setState({keyboardVisibilityQty: true});
           }
           else{
            this.setState({keyboardVisibilityQty: false});
           }
        
      };
      
      keyPadClose = () => {
        // alert('s');
         this.setState({keyboardVisibility: false});
         this.setState({keyboardVisibilityQty: false});
         this.setState({keyboardVisibilityScanQty: false});
         
         const focusField = document.querySelector(
          `input[name=item_ean]`
        );
          focusField.focus();
       
     };
        

    onItemTap = (totalQty,binLoc,actualBinCode,binQty,Indx) => {
      //const itemID = e;      
      if(parseInt(this.state.isBatchEnabled) === 0 && this.state.incnumBinInputOpen === true && this.state.selectedActBinLoc.indexOf(actualBinCode) === -1){
        return false;
       }
       if(parseInt(this.state.isBatchEnabled) === 1 && this.state.incnumBinInputOpen === true && this.state.selectedActDistNum.indexOf(binLoc) === -1){
        return false;
       }
       
      //var OldselectedBinData = this.state.selectedBinData;
       //console.log(OldselectedBinData);
      this.setState({keyboardVisibility: false});
      this.setState({totalOrderQty: totalQty});
      //const itemEAN = this.state.itemDet.id;

      this.setState({scanned_ean_code: this.state.itemDet.id });

      this.setState({selectedBinLocName: actualBinCode });
      this.setState({selectedBatchName: binLoc });


      //let availQty = totalQty-scanQty;  
      let newScanQty = 0;
      


      //alert(binLoc);
      //console.log(binLoc);

      //console.log(this.state.customSelectedBinQty);
      
      //BIN LOCATION /ADD OR REMOVE START
      var selectedBinLocQtyIndex = this.state.selectedBinLocQty.indexOf(binLoc);
      //console.log(selectedBinLocQtyIndex);
      
        if (selectedBinLocQtyIndex > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
          //console.log("QTY Index: "+selectedBinLocQtyIndex);

          //console.log(this.state.customSelectedBinQty[selectedBinLocQtyIndex]);
          //console.log(parseInt(this.state.total_scanned_item));
          //console.log(this.state.selectedBinLocQty.splice(selectedBinLocQtyIndex, 1));
          this.state.selectedDistNumbers.splice(selectedBinLocQtyIndex, 1);  
          this.state.selectedBinLocQty.splice(selectedBinLocQtyIndex, 1);        
          newScanQty = parseInt(this.state.total_scanned_item) - parseInt(this.state.customSelectedBinQty[selectedBinLocQtyIndex]);           
          this.setState({itemSelected: false});
          this.setState({incnumBinInputOpen: false});
          //console.log(newScanQty);
          //this.setState({total_scanned_item: parseInt(newScanQty)});
         //this.setState({itemSelectedAvailQty: parseInt(availQty)});

        }
        else{
      //this.state.selectedBinLocQty.push(binLoc); 
      /*if(this.state.customSelectedBinQty.length <=0){
        this.setState({total_scanned_item: 0});
      }*/
      newScanQty = parseInt(this.state.total_scanned_item) + parseInt(binQty);
      this.setState({itemSelected: true});
      this.setState({incnumBinInputOpen: true});
      //console.log("tett");
        }     

        
      //console.log(this.state.selectedBinLocQty);
      let availQty = totalQty-parseInt(newScanQty); //always check for current bin qty cot check DB scanned qty
      
        let updateReleaseQTY = 0;
        //alert(totalQty);
        //alert(newScanQty);
        //alert(availQty);
        if(availQty > 0){
          //updateReleaseQTY = newScanQty;
          updateReleaseQTY = binQty;
        }
        else{
          updateReleaseQTY = parseInt(binQty) + parseInt(availQty);
          //updateReleaseQTY = this.state.itemSelectedAvailQty;
        }
        this.setState({maxBinQtySelect: parseInt(updateReleaseQTY)?parseInt(updateReleaseQTY):0});
        this.setState({incnumBin: updateReleaseQTY?parseInt(updateReleaseQTY):0}); //Set initial QTY 
        //console.log("availQty:"+availQty);
        //console.log("SelectedAvailQty:"+this.state.itemSelectedAvailQty);  //Next time checking
        //console.log("SelectedAvailQty:"+this.state.updateReleaseQTY);  //Next time checking
        //alert("ReqTY "+updateReleaseQTY);
         //alert(this.state.itemSelectedAvailQty);
         //console.log( actualBinCode);
         //console.log( this.state.selectedActBinLoc);

         //console.log( binLoc);
         //console.log( this.state.selectedActDistNum);
         
         //console.log( this.state.selectedActBinLoc.indexOf(actualBinCode));
        /* if(parseInt(this.state.isBatchEnabled) === 0 && parseInt(this.state.itemSelectedAvailQty) <= 0 && this.state.selectedActBinLoc.indexOf(actualBinCode) === -1){
          return false;
         }
         if(parseInt(this.state.isBatchEnabled) === 1 && parseInt(this.state.itemSelectedAvailQty) <= 0 && this.state.selectedActDistNum.indexOf(binLoc) === -1){
          return false;
         }*/
         /*if(parseInt(newScanQty) >0){
         this.setState({total_scanned_item: parseInt(newScanQty)});
         this.setState({itemSelectedAvailQty: parseInt(availQty)});
         }
         else{
          this.setState({total_scanned_item: 0});
         this.setState({itemSelectedAvailQty: parseInt(newScanQty) + parseInt(availQty)});
         }*/

         if(parseInt(updateReleaseQTY) <= 0){
          this.setState({itemSelected: false});
          this.setState({incnumBinInputOpen: false});
          return false;
         }
         

         //console.log("Current Available QTY:"+ updateReleaseQTY);
         const IfBatchEnabled = this.state.isBatchEnabled;

         if (selectedBinLocQtyIndex > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
          if(Indx === 1){
          document.getElementById('itemTap'+binLoc).removeAttribute("style");  
          document.getElementById('itemTap'+binLoc).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
          }
          else{
            document.getElementById('itemTap2-'+binLoc).removeAttribute("style");  
            document.getElementById('itemTap2-'+binLoc).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
            }  

          }
          else{
            
            if(parseInt(IfBatchEnabled) === 1){
              this.state.selectedDistNumbers.push({"actBinLoc":actualBinCode,"distOrBin":binLoc,"binLocQty":updateReleaseQTY}); 
            }
            else{
              this.state.selectedDistNumbers.push({"actBinLoc":actualBinCode,"distOrBin":"", "binLocQty":updateReleaseQTY}); 
            }
            this.state.selectedBinLocQty.push(binLoc); 
          }

        
        //alert(isPolskyNum);

/*if(OldselectedBinData.length >0){
  if(parseInt(IfBatchEnabled) === 1){
    OldselectedBinData.push({"actBinLoc":actualBinCode,"distOrBin":binLoc,"binLocQty":updateReleaseQTY}); 
  }
  else{
    OldselectedBinData.push({"actBinLoc":actualBinCode,"distOrBin":"", "binLocQty":updateReleaseQTY}); 
  }
  this.setState({selectedDistNumbers:OldselectedBinData});
}
else{*/
        /*if(parseInt(IfBatchEnabled) === 1){
          this.state.selectedDistNumbers.push({"actBinLoc":actualBinCode,"distOrBin":binLoc,"binLocQty":updateReleaseQTY}); 
        }
        else{
          this.state.selectedDistNumbers.push({"actBinLoc":actualBinCode,"distOrBin":"", "binLocQty":updateReleaseQTY}); 
        }*/
      //}

      //console.log(OldselectedBinData);
        //console.log(this.state.selectedDistNumbers);        

    //console.log(removeusingSet(this.state.selectedDistNumbers));

       // var BinMap = new Object();
        var newArry =[];
        var newArry2 =[];
        var newArry3 =[];
        var newArry4 =[];
        let currntTotalUpdatedQty = 0;

        this.state.selectedDistNumbers.forEach(x=>{       
          //if(isPolskyNum === 1){
            var selectedDistNumberIndex = newArry3.indexOf(x.actBinLoc);
            if(parseInt(IfBatchEnabled) === 1){
             selectedDistNumberIndex = newArry2.indexOf(x.distOrBin);


            }
            //else{
              //var selectedDistNumberIndex = newArry3.indexOf(x.actBinLoc);
            //}
            //console.log(selectedDistNumberIndex);
              if (selectedDistNumberIndex > -1) {
              //newArry.push({"actBinLoc":x.actBinLoc,"distOrBin":x.distOrBin,"binLocQty":x.binLocQty});
              newArry.splice(selectedDistNumberIndex, 1);
              newArry2.splice(selectedDistNumberIndex, 1);
              newArry3.splice(selectedDistNumberIndex, 1);
              newArry4.splice(selectedDistNumberIndex, 1);

              //console.log(newArry.splice(selectedDistNumberIndex, 1));
              currntTotalUpdatedQty = currntTotalUpdatedQty - parseInt(this.state.customSelectedBinQty[selectedBinLocQtyIndex]);
              
            }
              else{
                //console.log(IfBatchEnabled);
                newArry2.push(x.distOrBin);
                newArry3.push(x.actBinLoc);
                if(parseInt(IfBatchEnabled) === 1){
                  newArry4.push(x.distOrBin);                  
                  newArry.push({"actBinLoc":x.actBinLoc,"distOrBin":x.distOrBin,"binLocQty":x.binLocQty});
                }
                else{
                  newArry.push({"actBinLoc":x.actBinLoc,"distOrBin":"","binLocQty":x.binLocQty});
                }
                currntTotalUpdatedQty = currntTotalUpdatedQty +x.binLocQty;
              }
        });      
        console.log(newArry);
        //console.log(newArry3);
        //console.log(newArry4);
        this.setState({selectedBinData:newArry});
        this.setState({selectedActBinLoc:newArry3});
        this.setState({selectedActDistNum:newArry4});

        
         this.setState({total_scanned_item: parseInt(currntTotalUpdatedQty)});
        this.setState({itemSelectedAvailQty: parseInt(totalQty) - parseInt(currntTotalUpdatedQty)});
        

       

          if(this.state.selectedBinLocQty.length >0){
              for (var i=0; i < this.state.selectedBinLocQty.length; i++) {
                if(document.getElementById('itemTap'+this.state.selectedBinLocQty[i]) != null){  
                document.getElementById('itemTap'+this.state.selectedBinLocQty[i]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
              }
              if(document.getElementById('itemTap2-'+this.state.selectedBinLocQty[i]) != null){  
                document.getElementById('itemTap2-'+this.state.selectedBinLocQty[i]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
              }
            }
          }        

      

    
       
    };

    updateSeltdItemBinQty = ()=> {

      const IfBatchEnabled = this.state.isBatchEnabled;
      let currntQty = parseInt(this.state.incnumBin);
      let totalQty = parseInt(this.state.totalOrderQty);
      
      this.state.customSelectedBinQty.push(currntQty);

      
        //this.setState({maxBinQtySelect: parseInt(updateReleaseQTY)});
        //this.setState({incnumBin: updateReleaseQTY}); //Set initial QTY 
      var newArryBin =[];
        //var newArry2Bin =[];
        //var newArry3Bin =[];
        //var newArry4 =[];
      let currntTotalUpdatedQty = 0;
      //console.log("Before QTY update:");
      //console.log(this.state.selectedDistNumbers);
      this.state.selectedDistNumbers.forEach(y=>{       
        //if(isPolskyNum === 1){
          /*var selectedDistNumberIndex = newArry3Bin.indexOf(y.actBinLoc);
          if(parseInt(IfBatchEnabled) === 1){
           selectedDistNumberIndex = newArry2Bin.indexOf(y.distOrBin);
          } */         
          //console.log(selectedDistNumberIndex);
            /*if (selectedDistNumberIndex > -1) {
              newArryBin.splice(selectedDistNumberIndex, 1);
            
            }
            else{*/
              //console.log(y.actBinLoc);        
              //console.log(y.distOrBin); 
              
              //console.log(this.state.selectedBinLocName);        
              //console.log(this.state.selectedBatchName);
              
              if(parseInt(IfBatchEnabled) === 1){
                if(y.distOrBin === this.state.selectedBatchName){
                  currntTotalUpdatedQty = currntTotalUpdatedQty +currntQty;
                  newArryBin.push({"actBinLoc":y.actBinLoc,"distOrBin":y.distOrBin,"binLocQty":currntQty});
                }
                else{
                  currntTotalUpdatedQty = currntTotalUpdatedQty +y.binLocQty;
                  newArryBin.push({"actBinLoc":y.actBinLoc,"distOrBin":y.distOrBin,"binLocQty":y.binLocQty});
                }
              }
              else{
                if(y.actBinLoc === this.state.selectedBinLocName){
                  currntTotalUpdatedQty = currntTotalUpdatedQty +currntQty;
                  newArryBin.push({"actBinLoc":y.actBinLoc,"distOrBin":"","binLocQty":currntQty});
                }
                else{
                  currntTotalUpdatedQty = currntTotalUpdatedQty +y.binLocQty;
                  newArryBin.push({"actBinLoc":y.actBinLoc,"distOrBin":"","binLocQty":y.binLocQty});
                }
              }
            //}

            
      });     
      console.log("After QTY update:"); 
      console.log(newArryBin);
      //console.log(currntTotalUpdatedQty);
      //console.log(newArry4);
      this.setState({selectedBinData:newArryBin});
      this.setState({selectedDistNumbers:newArryBin});


      
        
        this.setState({total_scanned_item: parseInt(currntTotalUpdatedQty)});
        this.setState({itemSelectedAvailQty: parseInt(totalQty) - parseInt(currntTotalUpdatedQty)});

        //console.log("total_scanned_item:"+ this.state.total_scanned_item); 
        //console.log("itemSelectedAvailQty:"+ this.state.itemSelectedAvailQty); 
      
        //console.log(parseInt(totalQty) - parseInt(currntTotalUpdatedQty));
      this.setState({itemSelected: false});
      this.setState({incnumBinInputOpen: false});
    }

    

    tapToscan = () => {     

        
      this.setState({modalShow2: true});        
  
  
    };

    incNumBin =()=>{

      if(parseInt(this.state.maxBinQtySelect) >= Number(this.state.incnumBin)+1)
      {
      this.setState({incnumBin: Number(this.state.incnumBin)+1}); 
      
      }
      else{
        this.setState({incnumBin: this.state.incnumBin}); 
      }
    };
     decNumBin = () => {
       if(this.state.incnumBin>0)
       {
        this.setState({incnumBin: Number(this.state.incnumBin)-1}); 
       }
    }
    QtySeltdFromBin = (e)=>{
   //alert(e.target.value)
     console.log(parseInt(this.state.maxBinQtySelect));
     console.log( e.target.value);
     console.log( Number(this.state.incnumBin));
      if(parseInt(this.state.maxBinQtySelect) >= parseInt(e.target.value)){
     this.setState({incnumBin: e.target.value});
      }
      else{
        this.setState({incnumBin: 0});
      }
     
     
    }
    

  render() {    

    let incNum =()=>{

      if(this.state.incnum<this.state.totalOrderQty)
      {
      this.setState({incnum: Number(this.state.incnum)+1}); 
      
      }
    };
    let decNum = () => {
       if(this.state.incnum>0)
       {
        this.setState({incnum: Number(this.state.incnum)-1}); 
       }
    }
   let handleChange = (e)=>{
    
     //console.log( e.target.value);
     if(e.target.value<=this.state.totalOrderQty)
     {
     this.setState({incnum: e.target.value});
     }
     else{
     this.setState({incnum: 0});
     }
     
    }
    let afterUpdateQty =(e)=>{
            
      this.findStockQtyBinLoc();
      this.setState({modalShow: false});

      
    }

    
    

    function updateQty(IncQty,itemId) {
  
        
        //new StockDocdetails().findDocument();
        //alert(localStorage.getItem('selectdBinLocationId'));
  
        
        //this.setState({modalShow: false});  
        //const itemId = this.props.match.params.id;
            
        //alert(IncQty);  
        if(IncQty >0){
          fetch('/api/v1/documents/ScanItemQtyUpdate/'+itemId+'/'+IncQty, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          })
          .then(res => res.json())
          .then(data => {
            console.log( data);
            afterUpdateQty(IncQty);
            //this.findDocument();                             
                    
                      //window.location.replace('/pickandpack');
                    });  
          }
  
      };  



    function MydModalWithGrid(props) {
      //this.callBinlocations();
//alert(props.onHide)
//console.log( props.onHide);
      return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            { JSON.parse(sessionStorage.getItem('currentLang'))[74]}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">         
           
              <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[42]}</h1>
              <div className="col-xl-3">
              <div className="input-group">
              <div className="input-group-prepend">
                <button className="btn btn-outline-primary" type="button" onClick={decNum}>-</button>
              </div>
              <input type="text" id="increamentValue" name="increamentValue" className="form-control" value={props.newIncQtyNum} onChange={handleChange}/>
              <div className="input-group-prepend">
                <button className="btn btn-outline-primary" type="button" onClick={incNum}>+</button>
              </div>
            </div>
              
              </div>
           
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Cancel</Button>           

            <Button onClick={ () => { updateQty(props.newIncQtyNum,props.itemId) }}>Save</Button>
          </Modal.Footer>
        </Modal>
      );
    }

    
    
    if (this.state.spinner) return ( <div className="mb-4 scanblock-div"><h1>Loading wait.... </h1></div>);
    if (this.state.items.length <=0) return ( <div><div className="mb-4 scanblock-div"><h1>There is no Bin Location(s) found for this item!
      </h1></div>           <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to={"/docdetails/"+this.state.itemDet.documentslist_id} >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>&nbsp; </div></div>
    );
    return (

      <div>
        <MydModalWithGrid show={this.state.modalShow} onHide={() => this.setState({modalShow: false})} itemId={this.props.match.params.id} newIncQtyNum={this.state.incnum}/>
            <div className="mb-4 scanblock-div">
              <h1>{ this.props.match.params.itemcode}</h1>
            </div>                    
            

            <div  className="scanblock-active">
                 {/* {this.state.hasQty === false && */}
                    <div className="scanblock">
                          <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[14]}</h1>
                              <div className="mb-4">
                              
                              &nbsp; &nbsp;
                                    <input
                                  name='item_ean'
                                  id='item_ean'
                                  type='text'
                                  placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[75]}
                                  //onKeyUp={this.state.handlefindItem}
                                // readOnly={true}
                                 // ref={(item_ean) => {item_ean && item_ean.focus() }} 
                                  //value={this.state.scanned_ean_code}
                                  //value={this.state.input}
                                  
                                  //onChange={item_ean => this.onChangeInput(item_ean)}
                                  onChange={binloc => this.handleSearch(binloc)}
                                />   
                                {/*<button onClick={() =>{this.onChangeEanInput()}} >KeyPad</button>*/}
                                
                          </div>
                          {this.state.keyboardVisibility && (
                          <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            theme={"hg-theme-default myTheme1"}
                            layoutName={this.state.layoutName}
                            onChange={input => this.onChangeKeyboard(input)}
                            onKeyPress={button => this.onKeyPress(button)}
                            //onBlur={clse => this.keyPadClose(clse)}
                           // min={13}
                           buttonTheme={[
                            {
                              class: "hg-red",
                              buttons: "Close"
                            },
                            {
                              class: "hg-highlight",
                              buttons:  "Close"
                            }
                          ]}
                            layout={{
                              default: [
                                "Close",
                                "1 2 3 4 5 6 7 8 9 0 - {bksp}",
                                "Q W E R T Y U I O P",
                                "A S D F G H J K L {enter}",
                                "Z X C V B N M {space}"
                              ],
                              shift: [
                                "Q W E R T Y U I O P - {bksp}",
                                'A S D F G H J K L {enter}',
                                "Z X C V B N M {shift}"
                              ]
                            }}
                          />
                          )}
                    </div>
                  {/* }*/}
                                
          
        <hr />
        <div className="scanned-total">
                          <div className="mb-4">
                            <h1> {JSON.parse(sessionStorage.getItem('currentLang'))[70]}   {this.state.totalOrderQty} </h1>
                            <h1>{JSON.parse(sessionStorage.getItem('currentLang'))[73]}   {this.state.total_scanned_item < this.state.totalOrderQty ? this.state.total_scanned_item : this.state.totalOrderQty }</h1>
                            <h1>{JSON.parse(sessionStorage.getItem('currentLang'))[69]}   {this.state.itemSelectedAvailQty > 0 ? this.state.itemSelectedAvailQty : 0 }</h1>
                          </div>
                          

              </div>
              
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    //next={this.refreshList}
                    //hasMore={this.state.hasMore}
                    //loader={<h4>.</h4>}
                    height={400}

                  >
                            
                                 
                       {this.state.searchedData.length <=0 && this.state.items.map(i => (                          
                               <div >        
                                <Link to='#'  onClick={input => this.onItemTap(this.state.itemDet.quantity,i.DistNumber ?  i.DistNumber : i.BinCode,i.BinCode, i.OnHandQty,1)}>
                                <div className={i.DistNumber ?  `itemTap`+i.DistNumber : `itemTap`+i.BinCode} id={i.DistNumber ?  `itemTap`+i.DistNumber : `itemTap`+i.BinCode} style={i.status ?  scanned_items : scanned_pending}  key={i.DistNumber ?  i.DistNumber : i.BinCode}   >                  
                                
                                {i.DistNumber && 
                                <div className="items-det" >
                                      <span className={`todo-title mr-2`} title={i}>{i.DistNumber}</span>                                     
                                </div>
                                  }                                  
                                  
                                  <div className="items-det" >
                                  <span className={`todo-title mr-2`} title={i}>{i.ItemCode}</span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={`todo-title mr-2`} title={i}>
                                  {i.OnHandQty}                                  
                                  </span>                                 
                                  </div>
                                  <div className="scanned-pending-items">                                      
                                      
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={`todo-title mr-2`} title={i.BinCode}>
                                    {i.BinCode}
                                    </span>
                                      </div>

                                  </div>
                                </Link> 
                                </div>
                                ))}        
                      
                      {this.state.searchedData.length >0 && this.state.searchedData.map(i => (                          
                               <div>        
                                <Link to='#'  onClick={input => this.onItemTap(this.state.itemDet.quantity, i.DistNumber ?  i.DistNumber : i.BinCode,i.BinCode, i.OnHandQty,2)}>
                                <div className={i.DistNumber ?  `itemTap2-`+i.DistNumber : `itemTap2-`+i.BinCode} id={i.DistNumber ?  `itemTap2-`+i.DistNumber : `itemTap2-`+i.BinCode} style={i.status ?  scanned_items : scanned_pending}  key={i.DistNumber ?  i.DistNumber : i.BinCode}   >                  
                                {i.DistNumber && 
                                <div className="items-det" >
                                      <span className={`todo-title mr-2`} title={i}>{i.DistNumber}</span>                                     
                                </div>
                                  } 
                                  <div className="items-det" >

                                  <span className={`todo-title mr-2`} title={i}>{i.ItemCode}</span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={`todo-title mr-2`} title={i}>
                                  {i.OnHandQty}                                  
                                  </span>                                 
                                  </div>
                                  <div className="scanned-pending-items">                                      
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={`todo-title mr-2`} title={i.BinCode}>
                                    {i.BinCode}
                                    </span>
                                      </div>

                                  </div>
                                </Link> 
                                </div>
                                ))}    
                            
                                                     
                              

                          
                        
                  </InfiniteScroll>
                  <br/>
                  {this.state.itemSelected && this.state.pageFrom === "delivery" && 
                  <div className="col-xl-3">
              <div class="input-group">
              <div class="input-group-prepend">
                <button className="btn btn-outline-primary" type="button" onClick={() =>{this.decNumBin()}}>-</button>
              </div>
              <input type="text" id="increamentValue2" name="increamentValue2" className="form-control" value={this.state.incnumBin} onChange={(i) =>{this.QtySeltdFromBin(i)}}/>
              <div className="input-group-prepend">
                <button className="btn btn-outline-primary" type="button" onClick={() =>{this.incNumBin()}}>+</button>
              </div>
              <div className="input-group-prepend">
                <button className="btn btn-outline-primary" type="button" onClick={() =>{this.updateSeltdItemBinQty()}}>QTY Save</button>
              </div>
            </div>
              
              </div>}
        <br />
                  <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to={this.state.setPrevPage} >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>&nbsp; 
                    { this.state.pageFrom === "delivery" && parseInt(this.state.isBatchEnabled) ===0 &&
                    
                    <button onClick={() =>{this.tapToQtyEdit()}} >
                    <i className="fa fa-edit fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[30]}  
                    </button>
                                }
                    &nbsp;                
                    <button onClick={() =>{this.saveBinLocToDB()}} >
                    <i className="fa fa-check fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[53]}                
                    </button>
                </div>
            </div>

        
      </div>
      
    );

  }

}


export default StockQtyBinLoc;