import React,{ Component } from "react";
import { Link } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
//import NumericInput from 'react-numeric-input';
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";
  
const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"green"
};

const scanned_pending = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#FFA500"
};





class Openpurchaseorders extends Component {

  state = {
    items: [],
    hasQty: false,
    upScanQty: false,
    totalOrderQty: 0,
    qtyButton: JSON.parse(sessionStorage.getItem('currentLang'))[66],
    fullScrenButton: 'Full Screen',
    handlefindItem: this.findItem.bind(this, 'DocumentItemFind'),
    //handlefindItemInput:this.findItem.bind(this, 'DocumentItemFindInput'),
    docItem: [],
    VendorDet: [],
    total_scanned_item:0,
    scanned_ean_code:null,
    hasFullScrn: false,
    visibility: "block",
    showHideEnale: false,
    layoutName: "default",
    input: "",
    input3:"",
    qtyInput:1,
    keyboardVisibility:false,
    keyboardVisibilityQty:false,
    keyboardVisibilityScanQty:false,
    itemSelectedPrevId:0,
    itemSelectedPrevQty:0,
    itemSelected:false,
    itemSelectedAvailQty:0,
    itemSelectedBgColor:'tapping_bg_color',
  };

  findDocument() {
   
    //if (event.charCode === 13) { for enter key
    //const v = localStorage.getItem('VendorCardCode');
    localStorage.getItem('VendorsDocEntry');

    const postData = {
      user_id: localStorage.getItem('userId'),
      vendorcardcode: localStorage.getItem('VendorCardCode'),
      Vendorsdocentry: localStorage.getItem('VendorsDocEntry')
    };
    //alert(localStorage.getItem('VendorsDocEntry'));
    if(localStorage.getItem('VendorCardCode').length > 0){
              fetch('/api/v1/goodsreceived/purchaseorderitems', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(postData)
              })
                .then(res => res.json())
                .then(data => {
                  console.log( data)
                 
                this.setState({ VendorDet:data.VendorDet, docItem: data.document, items: data.docItemList, total_scanned_item: data.total_scanned_item });
               
                
              }
              );
    
      } //if
      else{
        window.location.replace('/goodsactivevendorlist');
      }
    
    };   
    
    readCommentConfirm = (DocEntry) => {
      const currentUser = localStorage.getItem('userId');
      const postData = {
      user_id: localStorage.getItem('userId'),
      vendorcardcode: localStorage.getItem('VendorCardCode'),
      Vendorsdocentry: localStorage.getItem('VendorsDocEntry')
      };
      if(currentUser){
                    fetch('/api/v1/goodsreceived/readcomentConfirm', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${localStorage.getItem('token')}`
                      },
                      body: JSON.stringify(postData)
                    })
                    .then(res => res.json())
                    .then(data => {
                      console.log( data);
                      this.findDocument();                             
            
                              });     
                  }                
          }; 

    tapToScan = () => {
      
          this.setState({ upScanQty: false });
          if(this.state.itemSelected === true){            
          
                if(this.state.hasQty === false){
                this.setState({ hasQty: true });
                this.setState({ qtyButton:  JSON.parse(sessionStorage.getItem('currentLang'))[66] });
                }
                else{
                  this.setState({ hasQty: false });
                  this.setState({ qtyButton: JSON.parse(sessionStorage.getItem('currentLang'))[66] });
                }

              }
              else{
                alert( JSON.parse(sessionStorage.getItem('currentLang'))[20]);
              }

        };

  tapToRemoveScannedItemQty = () => {
    //alert(this.state.itemSelectedPrevId);
    if(this.state.itemSelected === true){ 

        if(window.confirm("Do you want to remove scanned items?")){
      fetch('/api/v1/goodsreceived/removeScanItem/'+this.state.itemSelectedPrevId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
      .then(res => res.json())
      .then(data => {
        //console.log( data);
        this.findDocument();                             
                 
                  //window.location.replace('/pickandpack');
                });  
       }         
    }
    else{
      alert( JSON.parse(sessionStorage.getItem('currentLang'))[20]);
    }
    //this.findDocument();
  };   
  
  tapToUpdateScannedItemQty = () => {    
    this.setState({ hasQty: false });
    if(this.state.itemSelected === true){ 

          this.setState({upScanQty: true});
          //alert(this.state.input3);
          //alert(this.state.totalOrderQty);
        if(this.state.input3 > 0 ){ 
          //if(this.state.input3 <= this.state.totalOrderQty  ){ 
          fetch('/api/v1/goodsreceived/ScanItemQtyUpdate/'+this.state.itemSelectedPrevId+'/'+this.state.input3, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          })
          .then(res => res.json())
          .then(data => {
            //console.log( data);
            this.findDocument();                             
                    
                      //window.location.replace('/pickandpack');
                    });  
          // }
           //else{
           // alert( JSON.parse(sessionStorage.getItem('currentLang'))[43]);
           //}      
        }      
      else{
        alert( JSON.parse(sessionStorage.getItem('currentLang'))[42]);
      }
    }
    else{
      alert( JSON.parse(sessionStorage.getItem('currentLang'))[20]);
    }
    //this.findDocument();
  };   

  tapToHideShow = () => {
    if(this.state.showHideEnale === false){
    this.setState({ showHideEnale: true });
    }
    else{
      this.setState({ showHideEnale: false });
    }
    this.findDocument();
  };  
  
  ConfirmToSap = () => {
    if(this.state.items.length === this.state.total_scanned_item.length){

      
                  window.location.replace('/grposelection');

    }
    else{

      alert(JSON.parse(sessionStorage.getItem('currentLang'))[21]);

    }
    
  };
tapToFullScreen = () => {

  if(this.state.hasFullScrn === false){
    this.setState({ hasFullScrn: true });
    this.setState({ fullScrenButton: JSON.parse(sessionStorage.getItem('currentLang'))[22] });
  }
    else{
      this.setState({ hasFullScrn: false });
      this.setState({ fullScrenButton: JSON.parse(sessionStorage.getItem('currentLang'))[23] });
    }
  
};


    
    findItem(refName, e) {
   
      //if (event.charCode === 13) { for enter key
      let eanNum = e.target.value;
      
      //e.preventDefault();
      //loader load
      
      //if(eanNum.length >= 13){
        if(eanNum.length >= 5){


      document.getElementById('item_ean').value='';      
      
      //this.setState({ scanned_ean_code: eanNum });
      //alert('s');
     // this.setState({ scanned_ean_code: null });
     //if(eanNum === ''){
      //eanNum = this.state.scanned_ean_code;
     //}
     console.log(eanNum);

          this.scanItemUpdate(eanNum,0);

        } //if

      };
      
      scanItemUpdate(eanNum,isQtyFld) { 
        

        setTimeout(() => {
                   
          const vendorCode = localStorage.getItem('VendorCardCode');
          let scanQty =this.state.qtyInput;
          //alert(scanQty);
          fetch('/api/v1/goodsreceived/itemfind/'+vendorCode+'/'+eanNum.replace("/","_")+'/'+scanQty+'/'+parseInt(isQtyFld), {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                  }
                })
                  .then(res => res.json())
                  .then(data => {
                 console.log( data);
                 this.setState({ scanQty: 1, qtyInput:1 });

                if( this.state.itemSelectedPrevQty > 0 ){
                this.setState({itemSelectedPrevQty: this.state.itemSelectedPrevQty-1});
                }
                  // document.getElementById('item_ean').value='';
                    //document.getElementById('item_qty_input').value='';
                    if(this.state.itemSelectedPrevId >0 && this.state.itemSelectedPrevQty > 0 ){
                    document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");  
                    }
                    //else{
                      //document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: green none repeat scroll 0% 0%;");  
                    //}
                    // this.setState({ scanned_ean_code: null })
                  if(data.id > 0 ){
                   // this.setState({ scanned_ean_code: null });
                    this.findDocument();
                    //console.log( data.id);
                  }
                  else{
                    this.errors = true;
                    alert("Item Code not exist!");
                    //console.log( data.status);
                    //alert('Invalid EAN!');
                  }
                  this.setState({ itemSelected: false });
                }
                )
                .catch(error => {
                  alert("Item Code not exist!");
              });  

      

      }, 500);

      }
     
    componentDidMount() {
      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      //const { studentId } = this.props.match.params;
      //console.log( this.props.match.params);
      //this.refreshList();
      this.findDocument();
      }
     
   


        onChange = input => {
          this.setState({
            input: input
          });
          //console.log("Input changed", input);
        };
      
        onKeyPress = button => {
          this.setState({ upScanQty: false });
          //console.log("Button pressed", button);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
           if(button === "Close"){
            this.keyPadClose();
           }
         // if (button === "{shift}" || button === "{lock}") this.handleShift();
            if(button === "{enter}"){
              //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
                //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
                //this.findItem.bind(this.state.input, 'DocumentItemFind')
                //this.findItem(this.state.input, 'DocumentItemFind');
                let currKeyEan = this.state.input;
                this.setState({ input: '' });
               //console.log('dddd',this.state.input);
                this.scanItemUpdate(currKeyEan,0);
                
                
            }
          
        };

        onChangeQty = input2 => {
          this.setState({ upScanQty: false });
          
          //alert(input2);
              this.setState({
                input2: input2
              });
              //console.log("Input changed", input2);
        };
      
        onKeyPressQty = button2 => {
         // console.log("Button pressed", button2);
         
         this.setState({ upScanQty: false });
          if(button2 === "Close"){
            this.keyPadClose();
           
           }
         // alert(this.state.scanned_ean_code);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
          //if (button2 === "{shift}" || button2 === "{lock}") this.handleShift();
          if(button2 === "{enter}"){
         // this.setState({ input: '' });
           //console.log('dddd',this.state.input2);
          let availQty = this.state.itemSelectedAvailQty;
          let currKeyQtyEan = this.state.scanned_ean_code;
          if(this.state.input2 <= availQty){
            this.keyPadClose();
            
            this.setState({ qtyInput: this.state.input2 });

            
            this.setState({ input2: null });
            this.setState({ scanned_ean_code: null });

            this.scanItemUpdate(currKeyQtyEan,1);
          }
          else{
            alert("Available quantity for this item is:"+ availQty);
          }
          this.setState({ hasQty: false });
          //const docId = this.props.match.params.id;
          //window.location.replace('http://localhost:3000/docdetails/'+docId);
          //window.location.reload(false);
          }

        };

        onChangeQty2 = input3 => {
          //alert(input2);
          this.setState({ hasQty: false });
              this.setState({
                input3: input3
              });
              //console.log("Input changed", input2);
        };
      
        onKeyPressQty2 = button3 => {
          this.setState({ hasQty: false });
         // console.log("Button pressed", button2);
          if(button3 === "Close"){
            this.setState({ upScanQty: false });
            this.keyPadClose();
           
           }
         // alert(this.state.scanned_ean_code);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
          //if (button2 === "{shift}" || button2 === "{lock}") this.handleShift();
          if(button3 === "{enter}"){
         // this.setState({ input: '' });
           //console.log('dddd',this.state.input2);
          //let availQty = this.state.itemSelectedAvailQty;
//alert(this.state.input3);
this.tapToUpdateScannedItemQty();
          this.setState({ upScanQty: false });
          //const docId = this.props.match.params.id;
          //window.location.replace('http://localhost:3000/docdetails/'+docId);
          //window.location.reload(false);
          }

        };
      
        handleShift = () => {
          let layoutName = this.state.layoutName;
      
          this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
          });
        };
      
        onChangeInput = event => {
          let input = event.target.value;
          //alert(input);
          console.log("Button pressed", input);
          this.setState(
            {
              input: input
            },
            
            () => {
              return true;
              //this.keyboard.setInput(input);
              //this.keyboard.setState({ input: input });
            }
          );
        };

        onChangeQtyInput2 = e => {
          let QtyInput = e.target.value;
          //alert(input);
          console.log("Button pressed", QtyInput);
          this.setState(
            {
              qtyInput: QtyInput
            }
          );
        };
        onChangeEanInput = (e) => {
          //e.preventDefault();
          this.setState({ hasQty: false });
          this.setState({ upScanQty: false });
          this.setState({keyboardVisibilityQty: false});
          this.setState({keyboardVisibilityScanQty: false});

          if(this.state.keyboardVisibility === false){
            this.setState({keyboardVisibility: true});
          }
          else{
            this.setState({keyboardVisibility: false});
          }
        
      };

        onChangeQtyInput = e => {
         // alert('s');
         this.setState({ upScanQty: false });
         
          this.setState({keyboardVisibility: false});
          this.setState({keyboardVisibilityScanQty: false});
          if(this.state.keyboardVisibilityQty === false){
            this.setState({keyboardVisibilityQty: true});
           }
           else{
            this.setState({keyboardVisibilityQty: false});
           }
        
      };
      onChangeQtyInput3 = e => {
         //alert('s');
         this.setState({ hasQty: false });
         this.setState({keyboardVisibility: false});
         this.setState({keyboardVisibilityQty: false});
         if(this.state.keyboardVisibilityScanQty === false){
           this.setState({keyboardVisibilityScanQty: true});
          }
          else{
           this.setState({keyboardVisibilityScanQty: false});
          }
       
     };
      keyPadClose = () => {
        // alert('s');
         this.setState({keyboardVisibility: false});
         this.setState({keyboardVisibilityQty: false});
         this.setState({keyboardVisibilityScanQty: false});
         
         const focusField = document.querySelector(
          `input[name=item_ean]`
        );
          focusField.focus();
       
     };
     onItemTap = (e,idSeltd,totalQty,scanQty, itemCode) => {
      //const itemID = e;
      this.findDocument(); //refresh list to clear selected itms bg color
      this.setState({keyboardVisibility: false});
      this.setState({totalOrderQty: totalQty});
      const itemEAN = itemCode;
      //let availQty2 = totalQty-scanQty;
      let availQty = totalQty;
      this.setState({itemSelectedAvailQty: availQty});
      
      //alert(itemEAN);
      //console.log("Button pressed", tapItem);
      //document.addEventListener('onFocus', handleKeyPress);      
     // let currKeyEan = this.state.input;
     //this.setState({ scanned_ean_code: itemCode });
     // this.setState({input: e});
     //{ this.state.itemSelectedId === i.id  && (  
//alert(this.state.itemSelectedPrevId);

//if(this.state.itemSelectedPrevId > 0){ //check current style then set
  //document.getElementById('itemTap'+this.state.itemSelectedPrevId).removeAttribute("style");  
  //document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");
  
//}
//alert(this.state.itemSelectedPrevId);
//alert(this.state.itemSelectedPrevQty);
//alert(this.state.itemSelectedPrevId);
//if(this.state.itemSelectedId === idSeltd){ itemSelectedPrevQty
if(this.state.itemSelectedPrevId >0 && this.state.itemSelectedPrevQty > 0){
  document.getElementById('itemTap'+this.state.itemSelectedPrevId).removeAttribute("style");  
  document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");
  this.setState({itemSelectedPrevId: idSeltd});
this.setState({itemSelectedPrevQty: availQty});
this.setState({itemSelected: false});
}

if(availQty >0){
document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
this.setState({itemSelected: true});
}
else{
   document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
  document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: green none repeat scroll 0% 0%;");
  this.setState({itemSelected: false});
}
this.setState({itemSelectedPrevId: idSeltd});
this.setState({itemSelectedPrevQty: availQty});
//}

//var element = document.getElementById('itemTap'+idSeltd);
//element.setAttribute("style", tapping_bgColor);
//document.addEventListener('style', tapping_bgColor);

//const box = document.getElementById('itemTap'+idSeltd);
//alert(box);
//  Remove all Styles from Element
//box.removeAttribute('style');
//box.addAttribute('style',tapping_bgColor);

//  Remove specific style from Element
//box.style.width = null;

//  Remove all classes from element
// box.removeAttribute('class');





      this.setState(
        {
          scanned_ean_code: itemEAN
        }
      );
     // alert(this.state.scanned_ean_code);
      /*if(this.state.itemSelected === false){
        this.setState({itemSelected: true});
       }
       else{
        this.setState({itemSelected: false});
       }*/

      const focusField = document.querySelector(
        `input[name=item_ean]`
      );

      // If found, focus the next field
      //if (nextfield !== null) {
        focusField.focus();
      //}
    };

  render() {    

    return (

      <div>
              {this.state.hasFullScrn === false && 
                <div className="docinfo pick-list-block-div">
                    <div className="mb-4 scanblock-div">
                      <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[46]}: {this.state.VendorDet.CardCode}</h1>
                    </div>
                    <div className="mb-4">
                    <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[47]}: {this.state.VendorDet.CardName}</h1>

                    </div>
              </div>
              }

          
            {this.state.docItem.user_id ===null &&   
            <div className="mb-4 scanblock-comment" align="center">                
                <button onClick={this.readCommentConfirm}>
                { JSON.parse(sessionStorage.getItem('currentLang'))[16]}
                </button>

            </div> 
            
            }
            
            {this.state.docItem.user_id >0 &&   
            <div  className="scanblock-active">
                
                    <div className="scanblock">
                          <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[14]}</h1>
                              <div className="mb-4">
                                    <input
                                  name='item_ean'
                                  id='item_ean'
                                  type='text'
                                  placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[17]}
                                  onKeyUp={this.state.handlefindItem}
                                // readOnly={true}
                                 // ref={(item_ean) => {item_ean && item_ean.focus() }} 
                                  //value={this.state.scanned_ean_code}
                                  //value={this.state.input}
                                  
                                  onChange={item_ean => this.onChangeInput(item_ean)}
                                />   
                                <button onClick={() =>{this.onChangeEanInput()}} >KeyPad</button>
                                
                          </div>
                          {this.state.keyboardVisibility && (
                          <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            theme={"hg-theme-default myTheme1"}
                            layoutName={this.state.layoutName}
                            onChange={input => this.onChange(input)}
                            onKeyPress={button => this.onKeyPress(button)}
                            //onBlur={clse => this.keyPadClose(clse)}
                           // min={13}
                           buttonTheme={[
                            {
                              class: "hg-red",
                              buttons: "Close"
                            },
                            {
                              class: "hg-highlight",
                              buttons:  "Close"
                            }
                          ]}
                            layout={{
                              default: [
                                "Close {space}",
                                "1 2 3 4 5 6 7 8 9 0 - {bksp}",
                                "q w e r t y u i o p",
                                "a s d f g h j k l {enter}",
                                "z x c v b n m /"
                              ],
                              shift: [
                                "Q W E R T Y U I O P - {bksp}",
                                'A S D F G H J K L {enter}',
                                "{shift} Z X C V B N M {shift}"
                              ]
                            }}
                          />
                          )}
                    </div>
                  {/* }*/}
                  {this.state.upScanQty === true && 
                  <div className="scanblock">
                  <input
                        name='scanned_item_qty_update'
                        type='text'
                        placeholder='Update Quantity'
                        //onKeyUp={this.state.handlefindItemInput}
                        ref="DocumentFind" 
                       //ref={(item_qty_input) => {item_qty_input && item_qty_input.focus() }} 
                        defaultValue=""
                        min='1'
                        max='200'
                        onFocus={() =>{this.onChangeQtyInput3()}} 
                        //onBlur={clse => this.keyPadClose(clse)}
                           />
                          {this.state.keyboardVisibilityScanQty && (
                            <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            theme={"hg-theme-default hg-layout-numeric numeric-theme"}
                            layoutName={this.state.layoutName}
                            onChange={input => this.onChangeQty2(input)}
                            onKeyPress={button => this.onKeyPressQty2(button)}   
                                                   
                            layout={{
                              default: [
                                "Close",
                                "7 8 9 {bksp}",
                                "4 5 6 {enter}",
                                "1 2 3 . 0 clr"
                              ]
                            }}
                            buttonTheme={[
                              {
                                class: "hg-red",
                                buttons: "clr Close"
                              },
                              {
                                class: "hg-highlight",
                                buttons: "clr Close"
                              }
                            ]}
                          />
                          )}
                          </div>
                          }
                
              {this.state.hasQty === true &&     
              <div className="scanblock">
                
                    <div className="mb-4">                         


                        <input
                        name='item_qty_input'
                        type='text'
                        placeholder='Quantity'
                        //onKeyUp={this.state.handlefindItemInput}
                        ref="DocumentFind" 
                       //ref={(item_qty_input) => {item_qty_input && item_qty_input.focus() }} 
                        defaultValue=""
                        min='1'
                        max='2000'
                        onFocus={() =>{this.onChangeQtyInput()}} 
                        //onBlur={clse => this.keyPadClose(clse)}
                           />
                          {this.state.keyboardVisibilityQty && (
                            <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            theme={"hg-theme-default hg-layout-numeric numeric-theme"}
                            layoutName={this.state.layoutName}
                            onChange={input => this.onChangeQty(input)}
                            onKeyPress={button => this.onKeyPressQty(button)}   
                                                   
                            layout={{
                              default: [
                                "Close",
                                "7 8 9 {bksp}",
                                "4 5 6 {enter}",
                                "1 2 3 . 0 clr"
                              ]
                            }}
                            buttonTheme={[
                              {
                                class: "hg-red",
                                buttons: "clr Close"
                              },
                              {
                                class: "hg-highlight",
                                buttons: "clr Close"
                              }
                            ]}
                          />
                          )}

                    </div>
              </div>
              }



          
        <hr />
              <div className="scanned-total">
                          <div className="mb-4">
                            <h1> {JSON.parse(sessionStorage.getItem('currentLang'))[25]}   ::  {JSON.parse(sessionStorage.getItem('currentLang'))[26]}   {this.state.items.length} -  {JSON.parse(sessionStorage.getItem('currentLang'))[27]}   {this.state.total_scanned_item.length}</h1>
                          </div>
                          

              </div>
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    //next={this.refreshList}
                    //hasMore={this.state.hasMore}
                    //loader={<h4>.</h4>}
                    height={400}

                  >
                              {this.state.items.map((i, index) => (

                              <div>
                                {this.state.showHideEnale === true &&
                                   <div >
                                 {!i.status &&
                                  <div >
                                   <Link to='#'  onClick={input => this.onItemTap(input,i.id,i.Quantity, i.scanned_qty, i.ItemCode)}>

                                      <div style={scanned_pending} key={index}>
                                      
                                          <div className="items-det">
                                          <span className={`todo-title mr-2`} title={i.DocNum}>
                                          Doc No. {i.DocNum}
                                          </span>
                                         
                                          <span className={`todo-title mr-2`} title={i.DocNum}>
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          Line No. {i.LineNum}
                                          </span>
                                          <br />

                                          <span className="`todo-title mr-2`">
                                        
                                            {i.ItemCode}
                                          
                                            </span>
                                            </div>
                                            <div className="scanned-pending-items">
                                            {i.scanned_qty}/{i.Quantity}
                                            </div>
                                      
                                      </div>
                                      </Link> 
                                  </div>
                                }
                                  </div>
                                }

                                {this.state.showHideEnale === false && (
                                

                                      <Link to='#'  onClick={input => this.onItemTap(input,i.id,i.Quantity, i.scanned_qty, i.ItemCode)}>
                                                                 
                                        <div className={`itemTap`+i.id} id={`itemTap`+i.id} style={i.status ?  scanned_items : scanned_pending}  key={i}
                                        >

                                          <div className="items-det">
                                          <span className={`todo-title mr-2`} title={i.DocNum}>
                                          Doc No. {i.DocNum}
                                          </span>
                                          <span className={`todo-title mr-2`} title={i.DocNum}>
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          Line No. {i.LineNum}
                                          </span>
                                          <br />
                                          <span className="`todo-title mr-2`">                                        
                                            {i.ItemCode}                                          
                                            </span>
                                            </div>
                                            <div className="scanned-pending-items">
                                            {i.scanned_qty}/{i.Quantity}
                                            </div>
                                        </div>
                                       
                                        
                                          
                                          
                                       
                                      
                                      </Link> 
                                      
                                  )}
                                </div>
                              ))}
                  </InfiniteScroll>
        <br />
                  <div className="mb-12 text-center jumbotron-icon panel_footer">
                    <button onClick={() =>{this.ConfirmToSap()}} >
                    <i className="fa fa-check fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[28]}                    
                    </button>
                    &nbsp;
                    <button onClick={() =>{this.tapToUpdateScannedItemQty()}} >
                    <i className="fa fa-barcode fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[30]}  
                    </button>
                    &nbsp;
                    <button onClick={() =>{this.tapToScan()}} >                    
                    <i className="fa fa-edit fa-2" aria-hidden="true"></i>                    

                    <br />
                    {this.state.qtyButton}
                    </button>
                    &nbsp;
                    <button onClick={() =>{this.tapToHideShow()}} >
                    <i className="fa fa-bolt fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[29]}  
                    </button>
                    &nbsp;
                    <button onClick={() =>{this.tapToRemoveScannedItemQty()}} >
                    <i className="fa fa-trash fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[37]}  
                    </button>
                    &nbsp;
                    <button onClick={() =>{this.tapToFullScreen()}} >
                    {this.state.hasFullScrn === false && 
                    <i className="fa fa-arrows-alt fa-2" aria-hidden="true"></i>
                    }

                    {this.state.hasFullScrn === true && 
                    <i className="fa fa-window-minimize fa-2" aria-hidden="true"></i>
                    }
                    <br />
                    {this.state.fullScrenButton}
                    </button>
                </div>
            </div>
            }
         
        
      </div>
      
    );

  }

}


export default Openpurchaseorders;