import React,{ Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";



const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"green"
};


class StockmovementMydocSyncRuninglist extends Component {

  state = {
    items: [],
    hasMore: true,
  };


  
    componentDidMount() {
      //alert(1);
      this.checkStockMovMyRunninglist();


      }
     
      checkStockMovMyRunninglist = () => {

    fetch('/api/v1/stockmovment/mysyncInProgresslist/'+localStorage.getItem('userId')+'/'+localStorage.getItem('selectStockTransferType'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        //console.log( data);
                //alert('f');    
                    // a fake async api call like which sends
                    // 20 more records in .5 secs
                    setTimeout(() => {
                      this.setState({ items: data })                     
                    }, 500);

                  });     
        }; 
       
  render() {
    



    return (

      <div>
       
        {this.state.items.length> 0 &&
       <InfiniteScroll
          dataLength={this.state.items.length}
          
          height={400}
         
        >
          {this.state.items.map((i, index) => (
            
            <div style={scanned_items} key={index}>
             
              <span className={`todo-title mr-2`} title={i.DocNum}>
              { JSON.parse(sessionStorage.getItem('currentLang'))[12]}:  {i.DocNum}
              </span><br />

              <span className="`todo-title mr-2`">
              <i className="fa fa-calendar" aria-hidden="true"></i>
                {i.DocDate}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<i className="fa fa-user-o" aria-hidden="true"></i>
                &nbsp;{i.DocEntry}
                </span>

              
            </div>
          ))}
        </InfiniteScroll>
  }
        <br />
        <div className="mb-12 text-center jumbotron-icon">
        <button onClick={this.checkStockMovMyRunninglist}>
        <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
        </button>
        </div>
      </div>

    );

  }

}


export default StockmovementMydocSyncRuninglist;