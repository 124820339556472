import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { confirm } from "react-confirm-box";

import BrideNowlogo from '../../ingold/images/main_logo_131X133.png';
//import HomeImg from '../../ingold/images/home.png';
//import Settingslogo from '../../ingold/images/settings.png';
//import Picklistlogo from '../../ingold/images/clipboard.png';
//import Goodslogo from '../../ingold/images/box.png';


export default function Navbar() {
  const [isAuth, setIsAuth] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      setIsAuth(true);
    }
  }, []);

  const onClick = async (options) => {
    const result = await confirm("Are you sure?", options);
    if (result) {
     // console.log("You click yes!");
     
    fetch('/api/v1/users/auth/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        localStorage.clear();
        window.location.replace('/cardlogin');
      });
      //return;
    }
    //console.log("You click No!");
  };

  return (
    <nav className="navigation">
      <div>
      <a href="/dashboard">
      <img src={BrideNowlogo} alt="WMS Poland" /></a>
      {localStorage.getItem('username') && (<span className="user_name">  { JSON.parse(sessionStorage.getItem('currentLang'))[5]} {localStorage.getItem('username')}</span> )}
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        {/* icon from Heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      </div>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        
        {isAuth === true && (
        <ul>        
        <li><a href="/pickandpack"> { JSON.parse(sessionStorage.getItem('currentLang'))[1]}</a></li>
        {/*<li><a href="/stockmovementOption">{ JSON.parse(sessionStorage.getItem('currentLang'))[34]}</a></li>*/}
        <li><a href="/goodsreceive">{ JSON.parse(sessionStorage.getItem('currentLang'))[2]}</a></li>
        <li><a href="/stockcountingOption">{ JSON.parse(sessionStorage.getItem('currentLang'))[80]}</a></li>
        <li><a href="/stockreportOption">{ JSON.parse(sessionStorage.getItem('currentLang'))[81]}</a></li>
        
        <li><Link to='#'  className="demo-container"  onClick={onClick}> { JSON.parse(sessionStorage.getItem('currentLang'))[4]}</Link></li>
        
      </ul>
         )}
      
      </div>
    </nav>
  );
}
