import React, { useState } from 'react';
import useScanDetection from 'use-scan-detection';
 
const Scanner = () => {
    const [value, setValue] = useState("");
 
    useScanDetection({
        onComplete: setValue,
        minLength: 5 // EAN13
    });
 
    return (
        <input 
            value={value} 
            type="text"
        />
    );
};
 
export default Scanner