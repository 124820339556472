import React, { useState } from "react";
//import Doctab from "./Doctab";
//import Doclist from "../../components/AllTabs/Doclist";

import Stockmovementlist from "../../../views/app/Stockmovementlist";
import StockmovementMydoclist from "../../../components/AllTabs/StockMovement/StockmovementMydoclist";
import StockmovementMydocSyncRuninglist from "../../../components/AllTabs/StockMovement/StockmovementMydocSyncRuninglist";
import StockmovementMyFailedSyncList from "../../../components/AllTabs/StockMovement/StockmovementMyFailedSyncList";
import StockmovementMySyncList from "../../../components/AllTabs/StockMovement/StockmovementMySyncList";
//import GoodsActiveVendorList from "../../../components/AllTabs/StockMovement/GoodsActiveVendorList";

const Stockstabs = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  //const handleClick = (index) => setActiveIndex(index);
  const checkActiveStockMovement = (index, className) => activeIndex === index ? className : "";



  const handleClickStockMovement = (index) => {    
     setActiveIndex(index);
     //alert(index);
     //console.log(localStorage.getItem('token'));
     //const doctab = {
      //tabId: index
    //};
    

   };


  return (
    
    <>
      <div className="tabs">

        <button
          className={`tab ${checkActiveStockMovement(1, "active")}`}
          onClick={() => handleClickStockMovement(1)}
        >
          <i className="fa fa-edit" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActiveStockMovement(2, "active")}`}
          onClick={() => handleClickStockMovement(2)}
        >
          <i className="fa fa-user-o" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActiveStockMovement(3, "active")}`}
          onClick={() => handleClickStockMovement(3)}
        >
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActiveStockMovement(4, "active")}`}
          onClick={() => handleClickStockMovement(4)}
        >
          <i className="fa fa-warning" aria-hidden="true"></i>
        </button>
        <button
          className={`tab ${checkActiveStockMovement(5, "active")}`}
          onClick={() => handleClickStockMovement(5)}
        >
          <i className="fa fa-check" aria-hidden="true"></i>
        </button>
      </div>
      <div className="panels">
        <div className={`panel ${checkActiveStockMovement(1, "active")}`}>       
        <Stockmovementlist> </Stockmovementlist>    
        </div>
        <div className={`panel ${checkActiveStockMovement(2, "active")}`}>
        <StockmovementMydoclist> </StockmovementMydoclist>
        </div>
        <div className={`panel ${checkActiveStockMovement(3, "active")}`}>
        <StockmovementMydocSyncRuninglist> </StockmovementMydocSyncRuninglist>
        </div>
        <div className={`panel ${checkActiveStockMovement(4, "active")}`}>
        <StockmovementMyFailedSyncList> </StockmovementMyFailedSyncList>
        </div>
        <div className={`panel ${checkActiveStockMovement(5, "active")}`}>
        <StockmovementMySyncList> </StockmovementMySyncList>
        </div>

       
      </div>
    </>
  );
};

export default Stockstabs;