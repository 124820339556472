import React,{ Component } from "react";
import { Link } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";


const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#fff"
};

const scan_inprogress = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#FFA500"
};

class Mydoclist extends Component {

  state = {
    items: [],
    hasMore: true,
  };


  
    componentDidMount() {
      //alert(1);
      this.refreshList2();


      }
     
    refreshList2 = () => {

    fetch('/api/v1/documents/mylist/'+localStorage.getItem('username'), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        //console.log( data);
                //alert('f');    
                    // a fake async api call like which sends
                    // 20 more records in .5 secs
                    setTimeout(() => {
                      this.setState({ items: data })                     
                    }, 500);

                  })
              .catch(error => {
                
            });     
        }; 
       
  render() {
    async function removeItem(id, userId) {
      const currentUser = localStorage.getItem('username');

      if(userId === null || userId === currentUser){
      window.location.replace('docdetails/'+id);
    }
    else{
      alert('Another user already scanning!');
    }

  }




    return (

      <div>
       
        
        <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.refreshList2}
          hasMore={this.state.hasMore}
          loader={<h4>.</h4>}
          height={400}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
           {this.state.items.length <=0 && (
            <div >
              <span className="`todo-title mr-2`">Not found</span>
              </div>
           )}
          {this.state.items.length >0 && (
          this.state.items.map((i, index) => (
            
            <Link to='#' onClick={ () => { removeItem(i.id, i.username) }}>
            <div style={i.username ?  scan_inprogress : scanned_items} key={index}>
             
            <span className={`todo-title mr-2`} title={i.description}>
              {/*{ JSON.parse(sessionStorage.getItem('currentLang'))[12]}: */}
               {i.picklist_id}
              </span><br />

              <span className="`todo-title mr-2`">
              <i className="fa fa-calendar" aria-hidden="true"></i>
                {i.picklist_date}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<i className="fa fa-user-o" aria-hidden="true"></i>
                &nbsp;{i.pickListname}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<i className="fa fa-shopping-bag" aria-hidden="true"></i>
                &nbsp;{i.cardcode}
                </span><br />
                {i.Comments !==null && 
                <span className="`todo-title mr-2`" >
                <i className="fa fa-info-circle" aria-hidden="true"></i>
                &nbsp;{i.Comments.substring(0, 10)} (...)
              </span>}

              
            </div>
            </Link>
          ))
          )}
        </InfiniteScroll>
        <br />
        <div className="mb-12 text-center jumbotron-icon">
        <button onClick={this.refreshList2}>
        <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
        </button>
        </div>
      </div>

    );

  }

}


export default Mydoclist;