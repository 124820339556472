import React,{ Component } from "react";
import { Link  } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
//import NumericInput from 'react-numeric-input';
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";
//import IncDecCounter from "../../components/IncDecCounter";


  
const scanned_items = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"green"
};

const scanned_pending = {
  height: 70,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"rgb(132, 126, 126)",
  color:"#ffffff"
};





class Stockreportlist extends Component {

  state = {
    items: [],
    Fixeditems: [],
    itemsShow:{ display: 'block' },
    filterItemsShow:{ display: 'none' },
    itemDet: [],
    spinner:false,
    hasQty: false,
    upScanQty: false,
    totalOrderQty: 0,
    qtyButton: JSON.parse(sessionStorage.getItem('currentLang'))[66],
    fullScrenButton: 'Full Screen',
    handlefindItem: this.findItem.bind(this, 'DocumentItemFind'),
    //handlefindItemInput:this.findItem.bind(this, 'DocumentItemFindInput'),
    total_scanned_item:0,
    scanned_ean_code:null,
    hasFullScrn: false,
    visibility: "block",
    layoutName: "default",
    input: "",
    input3:"",
    qtyInput:1,
    keyboardVisibility:false,
    keyboardVisibilityQty:false,
    keyboardVisibilityScanQty:false,
    itemSelectedPrevId:0,
    itemSelectedPrevQty:0,
    itemSelected:false,
    itemSelectedAvailQty:0,
    itemSelectedBgColor:'tapping_bg_color',
    modalShow:false,
    incnum: 0,
    selectedBinLocQty:[],
    searchedData:[],
    selectedDistNumbers:[],
    selectedActBinLoc:[],
    selectedBinData:[],
    selectedActDistNum:[],
    webcamResult:"Not Found",
    setStopStream:false,    
    optionId: parseInt(this.props.match.params.optionId),
    setPrevPage:parseInt(this.props.match.params.optionId) === 2?"/warehouse/stockreportlist/2":"/stockreportOption",
  };

  findStockItems() {
   
    //if (event.charCode === 13) { for enter key   
    //const optionId = this.props.match.params.optionId;  
    localStorage.setItem('selectStockReportType',1);
    var ajaxUrl = '/api/v1/fetchStockItems';
    if(this.state.optionId === 2){
      localStorage.setItem('selectStockReportType',2);
      const whcode = localStorage.getItem('StockTransferItemFromWH');
      ajaxUrl = '/api/v1/stockreport/fetchWHWiseItem/'+this.state.optionId+'/'+whcode;
    }
    //alert(optionId);
    this.setState({spinner: true});
              fetch(ajaxUrl, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                  //console.log( data);
                  const Binrows = [];
                  for (let i = 0; i < data.ItemListLength; i++) {
                      // note: we are adding a key prop here to allow react to uniquely identify each
                      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
                      Binrows.push(data.itemList[i]);
                  }
                  
                  this.setState({items: Binrows, Fixeditems:Binrows});
                  this.setState({spinner: false});
                  //console.log( Binrows);
                 //alert(this.state.items[0]['Items']['ItemCode']);
                 //alert(this.state.items.length);    
                
              }
              ).catch(error => {
                
                console.log( error);
                this.setState({spinner: false});
            });
    
     
    
    };

    

    handleSearch = event => {
      
      let serachTerm = event.target.value.toUpperCase();

      this.highlightItem(serachTerm);

    }; 

    highlightItem(serachTerm) {
     /* let allItems = this.state.Fixeditems;
      console.log( allItems);
      let selectedBinLoc = allItems
      .filter(binLoc => binLoc.Items.ItemCode.indexOf(serachTerm) !== -1);
      
      if(parseInt(selectedBinLoc.length)<=0){
      selectedBinLoc = allItems.filter(binLoc => binLoc.Items.ItemName.indexOf(serachTerm) !== -2);
      }
      if(parseInt(selectedBinLoc.length)<=0 && allItems.filter(binLoc => binLoc.Items.U_PLNumer.indexOf(serachTerm) !== -1)){
        selectedBinLoc = allItems.filter(binLoc => binLoc.Items.U_PLNumer.indexOf(serachTerm) !== -3);
        }
        this.setState({ items: Fixeditems });
        */

        let allItems = this.state.items;
      
       if (serachTerm === "") { this.setState({ items: this.state.Fixeditems }); return ; }
        
       let filterBySearch = allItems.filter((item) => {
        if (item.Items.ItemCode != null && item.Items.ItemCode.toLowerCase().includes(serachTerm.toLowerCase())) 
        { console.log( item); return item; }

         else if (item.Items.ItemName != null && item.Items.ItemName.toLowerCase().includes(serachTerm.toLowerCase())) 
         { return item; }   

         else if (item.Items.U_PLNumer != null && item.Items.U_PLNumer.toLowerCase().includes(serachTerm.toLowerCase())) 
         { return item; }
         else if (item.Items.U_PolskiOpis != null && item.Items.U_PolskiOpis.toLowerCase()
         .includes(serachTerm.toLowerCase())) { return item; }
    })
    if(filterBySearch.length <=0){
      filterBySearch = this.state.Fixeditems
    }
    this.setState({ items: filterBySearch });

    console.log( filterBySearch);
    
      
      //console.log( filterBySearch);
      //setSearchedData(data);
      //const data3 = users.filter(
        //user => user.name.indexOf(event.target.value) !== -1
      //); Fixeditems
      //this.setState({ searchedData: selectedBinLoc });
      //console.log( selectedBinLoc);
      /*if(this.state.searchedData.length <=0){
      for (var j=0; j < this.state.selectedBinLocQty; j++) {
        if(document.getElementById('itemTap'+this.state.selectedBinLocQty[j]) != null){           
        document.getElementById('itemTap'+this.state.selectedBinLocQty[j]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
        }
        if(document.getElementById('itemTap2-'+this.state.selectedBinLocQty[j]) != null){           
          document.getElementById('itemTap2-'+this.state.selectedBinLocQty[j]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
          }
      }
    }
    else{
      for (var k=0; k < this.state.selectedBinLocQty; k++) {
        if(document.getElementById('itemTap'+this.state.selectedBinLocQty[k]) != null){           
        document.getElementById('itemTap'+this.state.selectedBinLocQty[k]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
        }
        if(document.getElementById('itemTap2-'+this.state.selectedBinLocQty[k]) != null){           
          document.getElementById('itemTap2-'+this.state.selectedBinLocQty[k]).setAttribute("style","color:#ffffff;height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
          }
        
      }      
    }*/

      
      
      //console.log( selectedBinLoc);
      
    }; 

    /*showSearchedData = () => {
      return this.state.searchedData.map(binLoc => (
        <div key={binLoc.BinCode}>

          <p id="mailid">{binLoc.BinCode} </p>
        </div>
      ));
      }*/
      
      tapToQtyEdit = () => {
      
      //this.setState({ scanned_ean_code: eancode })
     // alert(2);
      //if(this.state.scanned_ean_code.length > 8){
        //this.findItem.bind(this.state.scanned_ean_code.length, 'DocumentItemFind');
        //alert(eancode);
        //return 1;
          // }
          this.setState({ upScanQty: false });
          this.setState({ itemSelected: true });
          this.setState({modalShow: true});        
          
          /*const focusField = document.querySelector(
            `input[name=increamentValue]`
          );      
            focusField.focus();*/

        };

   
  
  tapToUpdateScannedItemQty = () => {    
    this.setState({ hasQty: false });
    if(this.state.itemSelected === true){ 

          this.setState({upScanQty: true});
          //alert(this.state.input3);
          //alert(this.state.totalOrderQty);
        if(this.state.input3 > 0 ){ 
          //if(this.state.input3 <= this.state.totalOrderQty  ){ 
          fetch('/api/v1/documents/ScanItemQtyUpdate/'+this.state.itemSelectedPrevId+'/'+this.state.input3, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          })
          .then(res => res.json())
          .then(data => {
            //console.log( data);
            this.findDocument();                             
                    
                      //window.location.replace('/pickandpack');
                    });  
           //}
           //else{
            //alert( JSON.parse(sessionStorage.getItem('currentLang'))[43]);
           //}      
        }      
      else{
        alert( JSON.parse(sessionStorage.getItem('currentLang'))[42]);
      }
    }
    else{
      alert( JSON.parse(sessionStorage.getItem('currentLang'))[20]);
    }
    //this.findDocument();
  };   
  
  saveBinLocToDB = () => {

      const itemId = this.props.match.params.id;
      const isPolskyNum = this.props.match.params.isPolskyNumber;
      const itemCode = this.props.match.params.itemcode;      
      let scanndQty = this.state.total_scanned_item < this.state.totalOrderQty ? this.state.total_scanned_item : this.state.totalOrderQty
      
      const postData = {
        itemId: itemId,
        isPolskyNum: isPolskyNum,
        itemCode: itemCode,
        selectedBinLoc:this.state.selectedActBinLoc,
        selectedDistNum:this.state.selectedActDistNum,
        selectedBinData: this.state.selectedBinData,
        scanndQty:scanndQty,
      };
      //console.log(this.state.selectedBinData);
      //console.log(this.state.selectedActBinLoc);
      //console.log(this.state.selectedActDistNum);
      
    
      fetch('/api/v1/documents/saveItemBinLoc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(postData)
      })
      .then(res => res.json())
      .then(data => {
        console.log( data);
        //this.findDocument();                             
                  if(data.status === 1){
                    alert( JSON.parse(sessionStorage.getItem('currentLang'))[71]);
                  }
                  window.location.replace('/docdetails/'+this.state.itemDet.documentslist_id);
                })
          .catch(error => {            
            console.log( JSON.parse(sessionStorage.getItem('currentLang'))[72]);
            window.location.replace('/docdetails/'+this.state.itemDet.documentslist_id);
           
        });          

    
    
  };



    
    findItem(refName, e) {
   
      //if (event.charCode === 13) { for enter key
      let eanNum = e.target.value;
      
      //e.preventDefault();
      //loader load
      
      //if(eanNum.length >= 13){
        if(eanNum.length >= 5){


      document.getElementById('item_ean').value='';      
      
      //this.setState({ scanned_ean_code: eanNum });
      //alert('s');
     // this.setState({ scanned_ean_code: null });
     //if(eanNum === ''){
      //eanNum = this.state.scanned_ean_code;
     //}
     console.log(eanNum);

          this.scanItemUpdate(eanNum,0);

        } //if

      };
      
      scanItemUpdate(eanNum,isQtyFld) { 
        

        setTimeout(() => {
                   
          const docId = this.props.match.params.id;
          let scanQty =this.state.qtyInput;
          //alert(eanNum); //.replaceAll(" ","-")
          let callUrl = '/api/v1/documents/itemfind/'+docId+'/'+eanNum+'/'+scanQty+'/'+parseInt(isQtyFld);
          fetch(callUrl, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                  }
                })
                  .then(res => res.json())
                  .then(data => {
                    //alert(this.state.input);
                 //console.log( data);
                if( this.state.itemSelectedPrevQty > 0 ){
                this.setState({itemSelectedPrevQty: this.state.itemSelectedPrevQty-1});
                }
                  // document.getElementById('item_ean').value='';
                    //document.getElementById('item_qty_input').value='';
                    if(this.state.itemSelectedPrevId >0 && this.state.itemSelectedPrevQty > 0 ){
                    document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");  
                    }
                    //else{
                      //document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: green none repeat scroll 0% 0%;");  
                    //}
                    // this.setState({ scanned_ean_code: null })
                  if(data.id > 0 ){
                   // this.setState({ scanned_ean_code: null });
                    this.findDocument();
                    //console.log( data.id);
                  }
                  else{
                    
                    
                    this.setState({ input: "" });
                    this.setState({ scanned_ean_code: null });

                    alert("EAN Code not exist!");
                    //console.log( data.status);
                    //alert('Invalid EAN!');
                    this.errors = true;
                  }
                  this.setState({ input: "" });
                 // this.setState({ scanned_ean_code: null });
                  this.setState({ itemSelected: false });
                  //alert(this.state.input);
                }
                )
                .catch(error => {
                  this.setState({ input: '' });
                  //alert(this.state.input);
                  this.setState({ scanned_ean_code: null });
                  alert("EAN Code not exist!");
              });  

      

      }, 500);

      }
     
    componentDidMount() {
      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      //const { studentId } = this.props.match.params;
      //console.log( this.props.match.params);
      //this.refreshList();
      this.findStockItems();
      }
     
    refreshList = () => {

      this.findStockItems();
        }; 


        onChangeKeyboard = inputk => {
          //console.log('SB1: '+this.state.input);
          //console.log('SB2: '+inputk);
          
          this.setState({
            input: inputk
          });
          //console.log("Input changed", inputk);
        };
      
        onKeyPress = button => {
          this.setState({ upScanQty: false });
          //console.log("Button pressed", button);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
           if(button === "Close"){
            this.setState({ input: '' });
            this.keyPadClose();
           }
         // if (button === "{shift}" || button === "{lock}") this.handleShift();
            if(button === "{enter}"){
              
              //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
                //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
                //this.findItem.bind(this.state.input, 'DocumentItemFind')
                //this.findItem(this.state.input, 'DocumentItemFind');
                let currKeyEan = this.state.input;
                //console.log('Keypad1: ',this.state.input);
                
                this.setState({ input: '' });
                
                //console.log('Keypad2: ',currKeyEan);
                this.scanItemUpdate(currKeyEan,0);
                this.keyPadClose();
                
                
            }
          
        };
        

        onChangeQty = input2 => {
          this.setState({ upScanQty: false });
          
          //alert(input2);
              this.setState({
                input2: input2
              });
              //console.log("Input changed", input2);
        };
      
             
      
        handleShift = () => {
          let layoutName = this.state.layoutName;
      
          this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
          });
        };
      
        onChangeInput = event => {
          let input = event.target.value;
          //alert(input);
          console.log("Button pressed", input);
          this.setState(
            {
              input: input
            },
            
            () => {
              return true;
              //this.keyboard.setInput(input);
              //this.keyboard.setState({ input: input });
            }
          );
        };

        
        onChangeEanInput = (e) => {
          //e.preventDefault();
          this.setState({ hasQty: false });
          this.setState({ upScanQty: false });
          this.setState({keyboardVisibilityQty: false});
          this.setState({keyboardVisibilityScanQty: false});

          if(this.state.keyboardVisibility === false){
            this.setState({keyboardVisibility: true});
          }
          else{
            this.setState({keyboardVisibility: false});
          }
        
      };

        onChangeQtyInput = e => {
         // alert('s');
         this.setState({ upScanQty: false });
         
          this.setState({keyboardVisibility: false});
          this.setState({keyboardVisibilityScanQty: false});
          if(this.state.keyboardVisibilityQty === false){
            this.setState({keyboardVisibilityQty: true});
           }
           else{
            this.setState({keyboardVisibilityQty: false});
           }
        
      };
      
      keyPadClose = () => {
        // alert('s');
         this.setState({keyboardVisibility: false});
         this.setState({keyboardVisibilityQty: false});
         this.setState({keyboardVisibilityScanQty: false});
         
         const focusField = document.querySelector(
          `input[name=item_ean]`
        );
          focusField.focus();
       
     };
     onItemTap = (e,itemCode,polskyNum,Indx) => {
      //const itemID = e;
      
      this.setState({keyboardVisibility: false});
      const itemEAN = itemCode;
      let OptionId = this.state.optionId;
      //let availQty = totalQty-scanQty;    
      localStorage.setItem('StockTransferItemCode',itemCode);
      localStorage.setItem('polskyNum',polskyNum);
      if(OptionId === 1){
      //window.location.replace('/warehouse/stockreportlist/1');  // use 1 is option id for Stock Report Option 1
      window.location.replace('/itemwisewarehousestock/stockreportlist/1');
      }   
      else if(OptionId === 2){
        localStorage.setItem('pageFrom','stockreportlist');
       window.location.replace('/StockReportQtyBinLoc/2/'+polskyNum+'/'+itemCode);  // use 2 is option id for Stock Report Option 2
      }   
      else{
        window.location.replace('/stockreportOption');
      }


      this.setState(
        {
          scanned_ean_code: itemEAN
        }
      );
    


        
    };

    tapToscan = () => {     

        
      this.setState({modalShow2: true});        
  
  
    };
    

  render() {    

    
    if (this.state.spinner) return ( <div className="mb-4 scanblock-div"><h1>Loading wait.... </h1></div>);
    if (this.state.items.length <=0) return ( <div><div className="mb-4 scanblock-div"><h1>There is no Bin Location(s) found for this item!
      </h1></div>           <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to={this.state.setPrevPage} >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>&nbsp; </div></div>
    );
    return (

      <div>
            <div className="mb-4 scanblock-div">
              <h1>{ this.props.match.params.itemcode}</h1>
            </div>                    
            

            <div  className="scanblock-active">
                 {/* {this.state.hasQty === false && */}
                    <div className="scanblock">
                          <h1>Search Item</h1>
                              <div className="mb-4">
                              {/*<Link to='#'  onClick={() =>{this.tapToscan()}}>
                              <i className="fa fa-barcode fa-2" aria-hidden="true"></i></Link>
                              &nbsp; &nbsp;*/}
                                    <input
                                  name='item_ean'
                                  id='item_ean'
                                  type='text'
                                  placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[82]}
                                  //onKeyUp={this.state.handlefindItem}
                                // readOnly={true}
                                  ref={(item_ean) => {item_ean && item_ean.focus() }} 
                                  //value={this.state.scanned_ean_code}
                                  //value={this.state.input}
                                  
                                  //onChange={item_ean => this.onChangeInput(item_ean)}
                                  onChange={binloc => this.handleSearch(binloc)}
                                />   
                                {/*<button onClick={() =>{this.onChangeEanInput()}} >KeyPad</button>*/}
                                
                          </div>
                          {this.state.keyboardVisibility && (
                          <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            theme={"hg-theme-default myTheme1"}
                            layoutName={this.state.layoutName}
                            onChange={input => this.onChangeKeyboard(input)}
                            onKeyPress={button => this.onKeyPress(button)}
                            //onBlur={clse => this.keyPadClose(clse)}
                           // min={13}
                           buttonTheme={[
                            {
                              class: "hg-red",
                              buttons: "Close"
                            },
                            {
                              class: "hg-highlight",
                              buttons:  "Close"
                            }
                          ]}
                            layout={{
                              default: [
                                "Close",
                                "1 2 3 4 5 6 7 8 9 0 - {bksp}",
                                "Q W E R T Y U I O P",
                                "A S D F G H J K L {enter}",
                                "Z X C V B N M {space}"
                              ],
                              shift: [
                                "Q W E R T Y U I O P - {bksp}",
                                'A S D F G H J K L {enter}',
                                "Z X C V B N M {shift}"
                              ]
                            }}
                          />
                          )}
                    </div>
                  {/* }*/}
                                
          
        <hr />
        
              
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    //next={this.refreshList}
                    //hasMore={this.state.hasMore}
                    //loader={<h4>.</h4>}
                    height={400}

                  >
                            
                                 
                       { this.state.items.map(i => (      
                        <div > 
                         {i.Items.ItemCode.indexOf('_conf') === -1 &&
                               <div >        
                                <Link to='#'  onClick={input => this.onItemTap(input,i.Items.ItemCode,i.Items.U_PLNumer? 1:0,1)}>
                                <div className={ `itemTap`+i.Items.ItemCode} id={`itemTap`+i.Items.ItemCode} style={i.status ?  scanned_items : scanned_pending}  key={i}   >                  
                                
                               
                                <div className="items-det" >
                                      <span className={`todo-title mr-2`} title={i}>{i.Items.ItemCode}</span>  

                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={`todo-title mr-2`} title={i}>
                                  {this.state.optionId === 2? i['Items/ItemWarehouseInfoCollection']['InStock']:i.Items.QuantityOnStock}                                  
                                  </span>                                         
                                </div>
                                                                 
                                  
                                  <div className="items-det" >
                                  <span className={`todo-title mr-2`} title={i}>{i.Items.ItemName}</span>
                                  
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {i.Items.U_PLNumer && <span className={`todo-title mr-2`} title={i}>
                                  {i.Items.U_PLNumer}                                  
                                  </span>    }                             
                                  </div>
                                  

                                  </div>
                                </Link> 
                                </div>
                       } </div > 
                                ))}     
                        
                  </InfiniteScroll>
        <br />
                  <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to={this.state.setPrevPage} >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    
                    </button>
                    </Link>&nbsp;&nbsp;
                    <button onClick={this.refreshList}>
                    <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
                    </button>
                </div>
            </div>

        
      </div>
      
    );

  }

}


export default Stockreportlist;