import React,{ Component } from "react";
import { Link } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";


const scanned_items = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color: "#fff",
  background:"#cfcfcf"
};

const scan_inprogress = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  color: "#fff",
  background:"#FFA500"
};

class Stockcountingdocdetails extends Component {

  state = {
    items: [],
    hasMore: true,
    layoutName: "default",
    input: "",
    keyboardVisibility:false,
  };


  
    componentDidMount() {
      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      localStorage.setItem('StockTransferItemDocEntry','');
      this.refreshList();


      }
     
    refreshList = () => {
      
//alert(localStorage.getItem('UserSelectedDocOption'));
    fetch('/api/v1/stockcounting/mylist/'+localStorage.getItem('userId')+'/'+this.props.match.params.optionId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}` //3006dc6ed948ee06cb55119eb2323288975548a8f93ecc099cd66786b9740993
      }
    })
      .then(res => res.json())
      .then(data => {
       // console.log( data);
                    if (this.state.items.length >= 500) {
                      this.setState({ hasMore: false });
                      return;
                    }
                    // a fake async api call like which sends
                    // 20 more records in .5 secs
                    setTimeout(() => {
                      this.setState({ items: data })                     
                    }, 500);

                  })
            .catch(error => {
              console.log( error);
            });       
        }; 
  
    
  tapToAddItem = () => {
    if(parseInt(this.props.match.params.optionId) ===3){
    window.location.replace('/stockcountinglist');
    }
    else{
      window.location.replace('/warehouse/stockcountingOption/2');
    }

        }; 

  selectDestWareHouse = () => {
    window.location.replace('/stockcounting/commentscreen/'+this.props.match.params.optionId);
    };

    onItemTap = (id) => {
      const currentUser = localStorage.getItem('userId');
      //alert(localStorage.getItem('StockTransferNewDocId'));
      if(window.confirm('Are you sure to remove this item?')){
      
        fetch('/api/v1/stockcounting/removeScanItem/'+id, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          }
        })
        .then(res => res.json())
        .then(data => {
          console.log( data);
          this.refreshList();     
          //window.location.reload();
    
              });    
      
    }
    };
  render() {
    


    return (

      <div>
        <h1>Stock Counting Items</h1>
            

               
        <hr />
        <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.refreshList}
          hasMore={this.state.hasMore}
          loader={<h4>.</h4>}
          height={400}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {this.state.items.map((i, index) => (
            
            <Link to='#' onClick={input => this.onItemTap(i.id)} >
            <div style={i.Quantity ?  scan_inprogress : scanned_items} key={index}>
             
              <span className={`todo-title mr-2`} title={i.ItemCode}>
               {i.ItemCode} &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;  {i.Quantity}
              </span><br />

              <span className="`todo-title mr-2`">
                {i.U_FromBinCode}
                
                </span>

                
            </div>
            </Link>
          ))}
        </InfiniteScroll>
        <br />
       
        <div className="mb-12 text-center jumbotron-icon panel_footer">
        
         <button onClick={() =>{this.tapToAddItem()}} >
        <i className="fa fa-plus fa-2" aria-hidden="true"></i>
          
        </button>           
          &nbsp;
        
          <button onClick={() =>{this.selectDestWareHouse()}} >
            <i className={`fa fa-arrow-right fa-2`} aria-hidden="true"></i>                              
            </button>
        </div>
      </div>

    );

  }

}


export default Stockcountingdocdetails;