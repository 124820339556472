import React,{ Component } from "react";
import { Link  } from 'react-router-dom';
import Keyboard from "react-simple-keyboard";
//import NumericInput from 'react-numeric-input';
import "react-simple-keyboard/build/css/index.css";
import InfiniteScroll from "react-infinite-scroll-component";
//import IncDecCounter from "../../components/IncDecCounter";

import Button from 'react-bootstrap/Button';
//import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

import BarcodeScannerComponent from "react-qr-barcode-scanner";
  
const scanned_items = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"green"
};

const scanned_pending = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"rgb(132, 126, 126)",
  color:"#ffffff"
};




class StockReportQtyBinLoc extends Component {

  state = {
    items: [],
    Fixeditems: [],
    itemsShow:{ display: 'block' },
    filterItemsShow:{ display: 'none' },
    itemDet: [],
    spinner:false,
    hasQty: false,
    upScanQty: false,
    totalOrderQty: 0,
    qtyButton: JSON.parse(sessionStorage.getItem('currentLang'))[66],
    fullScrenButton: 'Full Screen',
    handlefindItem: this.findItem.bind(this, 'DocumentItemFind'),
    //handlefindItemInput:this.findItem.bind(this, 'DocumentItemFindInput'),
    total_scanned_item:0,
    scanned_ean_code:null,
    hasFullScrn: false,
    visibility: "block",
    layoutName: "default",
    input: "",
    input3:"",
    qtyInput:1,
    keyboardVisibility:false,
    keyboardVisibilityQty:false,
    keyboardVisibilityScanQty:false,
    itemSelectedPrevId:0,
    itemSelectedPrevQty:0,
    itemSelected:false,
    itemSelectedAvailQty:0,
    itemSelectedBgColor:'tapping_bg_color',
    modalShow:false,
    incnum: 0,
    selectedBinLocQty:[],
    searchedData:[],
    selectedDistNumbers:[],
    selectedActBinLoc:[],
    selectedBinData:[],
    selectedActDistNum:[],
    webcamResult:"Not Found",
    setStopStream:false,
    setPrevPage:'',
    totalBinQty:0,
  };

  findStockQtyBinLoc() {
   
    //if (event.charCode === 13) { for enter key
    const optionId = this.props.match.params.optionId;
    const isPolskyNum = this.props.match.params.isPolskyNumber;
    const itemCode = this.props.match.params.itemcode;   
    const wareHouseCode = localStorage.getItem('StockTransferItemFromWH');  
    const pageFrom = localStorage.getItem('pageFrom');  
    const ajaxUrl = '/api/v1/fetchBinStockQty';
    //alert(optionId);
    const postData = {
      itemCode: itemCode,
      isPolsky: isPolskyNum,
      wareHouseCode: wareHouseCode,
      pageFrom:pageFrom,
      optionId:optionId,
    };
    this.setState({spinner: true});
    console.log( postData);
    if(itemCode !=="" && wareHouseCode !==""){
              fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(postData)
              })
                .then(res => res.json())
                .then(data => {
                  //console.log( data);

                  const Binrows = [];
                  let BinQty = 0;
                  for (let i = 0; i < data.docItemListLength; i++) {
                      // note: we are adding a key prop here to allow react to uniquely identify each
                      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
                      Binrows.push(data.docItemList[i]);
                      BinQty = BinQty+parseFloat(data.docItemList[i]['OnHandQty']);
                      //this.setState({totalBinQty: parseInt(this.state.totalBinQty) + parseInt(data.docItemList[i]['OnHandQty'])})
                  }
                  console.log( BinQty);
                  this.setState({totalBinQty: BinQty});
                  //console.log( this.state.totalBinQty);
                  this.setState({spinner: false});
                  


                  /*let backUrl = '';
                  //alert(localStorage.getItem('pageFrom'));
                  if(localStorage.getItem('pageFrom') === 'stockreportlist'){
                  if(parseInt(optionId) === 1){
                    //alert(String(pageFrom));
                    //backUrl = '/warehouse/stockreportlist/1';
                    backUrl = '/itemwisewarehousestock/stockreportlist/1';
                  
                  }
                  else{
                    backUrl = '/warehouse/stockreportlist/2';
                  
                  }
                }
                else if(localStorage.getItem('pageFrom') === 'goodsreceiptdetails'){
                  if(parseInt(optionId) === 1){
                    //alert(String(pageFrom));
                    backUrl = '/goodsreceiptdetails/stockreportlist/1';
                  
                  }
                  else{
                    backUrl = '/goodsreceiptdetails/'+localStorage.getItem('DocNum')+'/'+localStorage.getItem('DocEntry');
                  
                  }
                }
                else{
                  if(parseInt(optionId) === 1){
                    //alert(String(pageFrom));
                    backUrl = '/goodsreceiptdetails/1/'+localStorage.getItem('DocNum')+'/'+localStorage.getItem('DocEntry');
                  
                  }
                  else{
                    backUrl = '/goodsreceiptdetails/1/'+localStorage.getItem('DocNum')+'/'+localStorage.getItem('DocEntry');
                  
                  }
                }*/
                  
                  this.setState({items: Binrows, Fixeditems:Binrows});
                 
                 

                  //const data2 = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
                //console.log( data.docItemList[0]['Items/ItemWarehouseInfoCollection']);
                //console.log( this.state.items[0]['Items/ItemWarehouseInfoCollection']);



                   //console.log( this.state.itemDet.documentslist_id)
                //this.setState({ docItem: data.document, items: data.docItemList, total_scanned_item: data.total_scanned_item });
               
                
              }
              ).catch(error => {
                
                console.log( error);
                this.setState({spinner: false});
            });
    
      } //if
    
    };

    

    handleSearch = event => {

      //console.log( event.target.value);

      /*const users = [
        {
          name: "abc1-B2-5-2",
          emailid: "abc1@gmail.com",
          address: "23rd main, 2nd street"
        },
        {
          name: "adb2",
          emailid: "abc2@gmail.com",
          address: "23rd main, 2nd street"
        },
        {
          name: "adc3",
          emailid: "abc3@gmail.com",
          address: "23rd main, 2nd street"
        }
      ];
      //console.log( users);*/
     // console.log( this.state.selectedBinLocQty);
      //console.log( this.state.items);
      let serachTerm = event.target.value.toUpperCase();

      this.highlightItem(serachTerm);

    }; 

    highlightItem(serachTerm) {
      /*let allItems = this.state.Fixeditems;
      console.log( this.state.items);
      let selectedBinLoc = allItems.filter(
        binLoc => binLoc.BinCode.indexOf(serachTerm) !== -1
      );
      if(selectedBinLoc.length <=0){
        selectedBinLoc =allItems
        .filter(binLoc => binLoc.DistNumber.indexOf(serachTerm) !== -1 );
      }
      
      console.log( serachTerm);
      //setSearchedData(data);
      //const data3 = users.filter(
        //user => user.name.indexOf(event.target.value) !== -1
      //); Fixeditems
      this.setState({ searchedData: selectedBinLoc });    

      //document.getElementById("item_ean").value='';
      if(selectedBinLoc.length <=0){
        alert("Bin location not found!");
      }*/

      let allItems = this.state.items;
      
      if (serachTerm === "") { this.setState({ items: this.state.Fixeditems }); return ; }
       
      let filterBySearch = allItems.filter((item) => {
       if (item.BinCode.toLowerCase()
           .includes(serachTerm.toLowerCase())) { return item; }
           
   })
   if(filterBySearch.length <=0){
     filterBySearch = this.state.Fixeditems
   }
   this.setState({ items: filterBySearch });

   console.log( filterBySearch);
      
    };    
      

    
    findItem(refName, e) {
   
      //if (event.charCode === 13) { for enter key
      let eanNum = e.target.value;
      
      //e.preventDefault();
      //loader load
      
      //if(eanNum.length >= 13){
        if(eanNum.length >= 5){


      //document.getElementById('item_ean').value='';      
      
      //this.setState({ scanned_ean_code: eanNum });
      //alert('s');
     // this.setState({ scanned_ean_code: null });
     //if(eanNum === ''){
      //eanNum = this.state.scanned_ean_code;
     //}
     console.log(eanNum);

          this.scanItemUpdate(eanNum,0);

        } //if

      };
      
      scanItemUpdate(eanNum,isQtyFld) { 
        

        setTimeout(() => {
                   
          const docId = this.props.match.params.id;
          let scanQty =this.state.qtyInput;
          //alert(eanNum); //.replaceAll(" ","-")
          let callUrl = '/api/v1/documents/itemfind/'+docId+'/'+eanNum+'/'+scanQty+'/'+parseInt(isQtyFld);
          fetch(callUrl, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                  }
                })
                  .then(res => res.json())
                  .then(data => {
                    //alert(this.state.input);
                 //console.log( data);
                if( this.state.itemSelectedPrevQty > 0 ){
                this.setState({itemSelectedPrevQty: this.state.itemSelectedPrevQty-1});
                }
                  // document.getElementById('item_ean').value='';
                    //document.getElementById('item_qty_input').value='';
                    if(this.state.itemSelectedPrevId >0 && this.state.itemSelectedPrevQty > 0 ){
                    document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");  
                    }
                    //else{
                      //document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: green none repeat scroll 0% 0%;");  
                    //}
                    // this.setState({ scanned_ean_code: null })
                  if(data.id > 0 ){
                   // this.setState({ scanned_ean_code: null });
                    this.findDocument();
                    //console.log( data.id);
                  }
                  else{
                    
                    
                    this.setState({ input: "" });
                    this.setState({ scanned_ean_code: null });

                    alert("EAN Code not exist!");
                    //console.log( data.status);
                    //alert('Invalid EAN!');
                    this.errors = true;
                  }
                  this.setState({ input: "" });
                 // this.setState({ scanned_ean_code: null });
                  this.setState({ itemSelected: false });
                  //alert(this.state.input);
                }
                )
                .catch(error => {
                  this.setState({ input: '' });
                  //alert(this.state.input);
                  this.setState({ scanned_ean_code: null });
                  alert("EAN Code not exist!");
              });  

      

      }, 500);

      }
     
    componentDidMount() {
      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      //const { studentId } = this.props.match.params;
      //console.log( this.props.match.params);
      //this.refreshList();
      const optionId = this.props.match.params.optionId;
      let backUrl = '';
                  //alert(localStorage.getItem('pageFrom'));
                  if(localStorage.getItem('pageFrom') === 'stockreportlist'){
                      if(parseInt(optionId) === 1){
                        //alert(String(pageFrom));
                        //backUrl = '/warehouse/stockreportlist/1';
                        backUrl = '/itemwisewarehousestock/stockreportlist/1';
                      
                      }
                      else{
                        //backUrl = '/warehouse/stockreportlist/2';
                        backUrl = '/stockreportlist/2';
                      
                      }
                }
                else if(localStorage.getItem('pageFrom') === 'goodsreceiptdetails'){
                  if(parseInt(optionId) === 1){
                    //alert(String(pageFrom));
                    backUrl = '/goodsreceiptdetails/stockreportlist/1';
                  
                  }
                  else{
                    backUrl = '/goodsreceiptdetails/'+localStorage.getItem('DocNum')+'/'+localStorage.getItem('DocEntry');
                  
                  }
                }
                else{
                  if(parseInt(optionId) === 1){
                    //alert(String(pageFrom));
                    backUrl = '/goodsreceiptdetails/1/'+localStorage.getItem('DocNum')+'/'+localStorage.getItem('DocEntry');
                  
                  }
                  else{
                    backUrl = '/goodsreceiptdetails/1/'+localStorage.getItem('DocNum')+'/'+localStorage.getItem('DocEntry');
                  
                  }
                }

                this.setState({ setPrevPage: backUrl });
      this.findStockQtyBinLoc();
      }    
    

        onChangeKeyboard = inputk => {
          //console.log('SB1: '+this.state.input);
          //console.log('SB2: '+inputk);
          
          this.setState({
            input: inputk
          });
          //console.log("Input changed", inputk);
        };
      
        onKeyPress = button => {
          this.setState({ upScanQty: false });
          //console.log("Button pressed", button);
      
          /**
           * If you want to handle the shift and caps lock buttons
           */
           if(button === "Close"){
            this.setState({ input: '' });
            this.keyPadClose();
           }
         // if (button === "{shift}" || button === "{lock}") this.handleShift();
            if(button === "{enter}"){
              
              //handlefindItem: this.findItem.bind(this, 'DocumentItemFind')
                //this.setState({ handlefindItem: this.findItem.bind(this.state.input, 'DocumentItemFind') });
                //this.findItem.bind(this.state.input, 'DocumentItemFind')
                //this.findItem(this.state.input, 'DocumentItemFind');
                let currKeyEan = this.state.input;
                //console.log('Keypad1: ',this.state.input);
                
                this.setState({ input: '' });
                
                //console.log('Keypad2: ',currKeyEan);
                this.scanItemUpdate(currKeyEan,0);
                this.keyPadClose();
                
                
            }
          
        };
        

        onChangeQty = input2 => {
          this.setState({ upScanQty: false });
          
          //alert(input2);
              this.setState({
                input2: input2
              });
              //console.log("Input changed", input2);
        };
      
             
      
        handleShift = () => {
          let layoutName = this.state.layoutName;
      
          this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
          });
        };
      
        onChangeInput = event => {
          let input = event.target.value;
          //alert(input);
          console.log("Button pressed", input);
          this.setState(
            {
              input: input
            },
            
            () => {
              return true;
              //this.keyboard.setInput(input);
              //this.keyboard.setState({ input: input });
            }
          );
        };

        
        onChangeEanInput = (e) => {
          //e.preventDefault();
          this.setState({ hasQty: false });
          this.setState({ upScanQty: false });
          this.setState({keyboardVisibilityQty: false});
          this.setState({keyboardVisibilityScanQty: false});

          if(this.state.keyboardVisibility === false){
            this.setState({keyboardVisibility: true});
          }
          else{
            this.setState({keyboardVisibility: false});
          }
        
      };

        onChangeQtyInput = e => {
         // alert('s');
         this.setState({ upScanQty: false });
         
          this.setState({keyboardVisibility: false});
          this.setState({keyboardVisibilityScanQty: false});
          if(this.state.keyboardVisibilityQty === false){
            this.setState({keyboardVisibilityQty: true});
           }
           else{
            this.setState({keyboardVisibilityQty: false});
           }
        
      };
      
      keyPadClose = () => {
        // alert('s');
         this.setState({keyboardVisibility: false});
         this.setState({keyboardVisibilityQty: false});
         this.setState({keyboardVisibilityScanQty: false});
         
         const focusField = document.querySelector(
          `input[name=item_ean]`
        );
          focusField.focus();
       
     };
     
     onItemTap = (e,idSeltd,WhsCode, binLoc,binQty,batchNum,Indx) => {
      //const itemID = e;

      if(localStorage.getItem('pageFrom') !== 'goodsreceiptdetails'){
        return false;
      }
      
      this.setState({keyboardVisibility: false});
      this.setState({itemSelectedAvailQty: binQty});
      this.setState({incnum: binQty});
      localStorage.setItem('StockItemBinQty',binQty);
      const itemEAN = binLoc;
      //let availQty = totalQty-scanQty;       
      this.setState({batchNumbers: (batchNum ? batchNum: "")});


      //alert(binLoc);
      console.log(this.state.itemSelectedPrevId);


    /*  if(this.state.itemSelectedPrevId){
        document.getElementById('itemTap'+this.state.itemSelectedPrevId).removeAttribute("style");  
        document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 40px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
        //this.setState({itemSelectedPrevId: idSeltd});
        //document.getElementById('itemTap2-'+this.state.itemSelectedPrevId).removeAttribute("style");  
        //document.getElementById('itemTap2-'+this.state.itemSelectedPrevId).setAttribute("style","height: 40px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
      }       

        
          if(idSeltd !==''){
            document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
            document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
            //document.getElementById('itemTap2-'+idSeltd).removeAttribute("style");  
            //document.getElementById('itemTap2-'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
            
            this.setState({itemSelected: true});
          }
          else{
           document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
          document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
          
          //document.getElementById('itemTap2-'+idSeltd).removeAttribute("style");  
          //document.getElementById('itemTap2-'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
          //this.setState({itemSelected: false});
          }*/
        
          if(this.state.itemSelectedPrevId){
            if(document.getElementById('itemTap'+this.state.itemSelectedPrevId)){
            document.getElementById('itemTap'+this.state.itemSelectedPrevId).removeAttribute("style");  
            document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 40px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
            }
            else{
              if(document.getElementById('itemTap2-'+this.state.itemSelectedPrevId)){
            document.getElementById('itemTap2-'+this.state.itemSelectedPrevId).removeAttribute("style");  
            document.getElementById('itemTap2-'+this.state.itemSelectedPrevId).setAttribute("style","height: 40px; border: 1px solid green; margin: 3px; padding: 3px;color: #fff; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
          }     }  }
    
            
              if(idSeltd !==''){
                if(document.getElementById('itemTap'+idSeltd)){
                  document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
                  document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
                  }
                else{
                document.getElementById('itemTap2-'+idSeltd).removeAttribute("style");  
                document.getElementById('itemTap2-'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
                }
                this.setState({itemSelected: true});
              }
              else{
                if(document.getElementById('itemTap'+idSeltd)){
                  document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
                  document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
    
                }
                else{
              document.getElementById('itemTap2-'+idSeltd).removeAttribute("style");  
              document.getElementById('itemTap2-'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: rgb(132, 126, 126) none repeat scroll 0% 0%;");
              }
            }

        this.setState({itemSelectedPrevId: idSeltd});
         
        
            


      this.setState(
        {
          scanned_ean_code: itemEAN
        }
      );
    
/*
      const focusField = document.querySelector(
        `input[name=item_ean]`
      );      
        focusField.focus();*/


        //if(polskiNumber === null){
          
          
          //this.tapToScan();
        //}
       // else{
          //alert(polskiNumber);
          //this.tapToScan();
          //this.setState({modalShow: true}); 
        //}

        
    };
    tapToscan = () => {     

        
      this.setState({modalShow2: true});        
  
  
    };

    saveBinLocQTY = () => {
      alert(this.state.scanned_ean_code);
      alert(localStorage.getItem('ItemQTY'));
      //localStorage.setItem('GRPOPrimaryBin',this.state.scanned_ean_code);
      // send to dB

      const optionId = this.props.match.params.optionId;
      const isPolskyNum = this.props.match.params.isPolskyNumber;
      const itemCode = this.props.match.params.itemcode;   
      const pageFrom = localStorage.getItem('pageFrom');  
        
      const postData = {
        optionId: optionId,
        isPolskyNum: isPolskyNum,
        itemCode: itemCode,
        selectedBinLoc:this.state.scanned_ean_code,
        pageFrom:pageFrom,
        binQty:localStorage.getItem('ItemQTY'),
        DocNum:localStorage.getItem('DocNum'),
        DocEntry:localStorage.getItem('DocEntry'),
      };
      //console.log(this.state.selectedBinData);
      //console.log(this.state.selectedActBinLoc);
      //console.log(this.state.selectedActDistNum);
      
    
      fetch('/api/v1/goodsreceipt/saveItemBinLoc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(postData)
      })
      .then(res => res.json())
      .then(data => {
        console.log( data);
        //this.findDocument();                             
                  if(data.status === 1){
                    alert( JSON.parse(sessionStorage.getItem('currentLang'))[71]);
                  }
                  window.location.replace(this.state.setPrevPage);
                })
          .catch(error => {            
            console.log( JSON.parse(sessionStorage.getItem('currentLang'))[72]);
           // window.location.replace('/docdetails/'+this.state.itemDet.documentslist_id);
           
        });          

    };
    

  render() {       
   

    


      let webcamHandleChange = (e, result)=>{
        console.log(result);
    
        //alert(result);
        if (result) {      
          this.setState({webcamResult: result.text});
          this.setState({setStopStream: true});        
          this.setState({modalShow2: false});
          //console.log(this.state.items); 
          let formttdString = result.text;
          console.log(formttdString);
          //this.scanItemUpdate(formttdString);
        //localStorage.setItem('sannedValues',result.text);
        this.highlightItem(formttdString.toUpperCase());
        
        }
        else{
          console.log("Not Found");
          //this.setState({webcamResult: "Not Found"});
        }
       }  

    
    function MydModalWithGridSCan(props) {
      //this.callBinlocations();
  //alert(props.onHide)
  //console.log( props.onHide);
      return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
          
          <Modal.Body className="show-grid">         
           
          <BarcodeScannerComponent
        width={450}
        height={500}
        stopStream={props.StreamStatus}
        onUpdate={webcamHandleChange}
      />
           
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Cancel</Button>           
  
          </Modal.Footer>
        </Modal>
      );
    }
    
    if (this.state.spinner) return ( <div className="mb-4 scanblock-div"><h1>Loading wait.... </h1></div>);
    if (this.state.items.length <=0) return ( <div><div className="mb-4 scanblock-div"><h1>There is no Bin Location(s) found for this item!
      </h1></div>           <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to={this.state.setPrevPage} >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>&nbsp; </div></div>
    );
    return (

      <div>
        <MydModalWithGridSCan show={this.state.modalShow2} onHide={() => this.setState({modalShow2: false})} itemId={this.props.match.params.id} StreamStatus={this.state.setStopStream}/>
            <div className="mb-4 scanblock-div">
            <h1>{ this.state.items[0]['ItemCode']}</h1>
            {/*<h1>{ this.props.match.params.itemcode}</h1>*/}
           {parseInt(this.props.match.params.optionId) === 2 && localStorage.getItem('pageFrom') !== 'goodsreceiptdetails' && <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[39]}: {this.state.totalBinQty}</h1>}
           {parseInt(this.props.match.params.optionId) === 2 && localStorage.getItem('pageFrom') === 'goodsreceiptdetails' && <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[39]}: {localStorage.getItem('ItemQTY')}</h1>}
           {parseInt(this.props.match.params.optionId) === 1 && localStorage.getItem('pageFrom') === 'stockreportlist'  && parseInt(this.state.totalBinQty)>0 &&<h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[39]}: {this.state.totalBinQty}</h1>}
            </div>                    
            

            <div  className="scanblock-active">
                 {/* {this.state.hasQty === false && */}
                    <div className="scanblock">
                          <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[83]}</h1>
                              <div className="mb-4">
                              {/*<Link to='#'  onClick={() =>{this.tapToscan()}}>
                              <i className="fa fa-barcode fa-2" aria-hidden="true"></i></Link>
                              &nbsp; &nbsp;*/}
                                    <input
                                  name='item_ean'
                                  id='item_ean'
                                  type='text'
                                  placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[75]}
                                  //onKeyUp={this.state.handlefindItem}
                                // readOnly={true}
                                 // ref={(item_ean) => {item_ean && item_ean.focus() }} 
                                  //value={this.state.scanned_ean_code}
                                  //value={this.state.input}
                                  
                                  //onChange={item_ean => this.onChangeInput(item_ean)}
                                  onChange={binloc => this.handleSearch(binloc)}
                                />   
                                {/*<button onClick={() =>{this.onChangeEanInput()}} >KeyPad</button>*/}
                                
                          </div>
                          {this.state.keyboardVisibility && (
                          <Keyboard
                            keyboardRef={r => (this.keyboard = r)}
                            theme={"hg-theme-default myTheme1"}
                            layoutName={this.state.layoutName}
                            onChange={input => this.onChangeKeyboard(input)}
                            onKeyPress={button => this.onKeyPress(button)}
                            //onBlur={clse => this.keyPadClose(clse)}
                           // min={13}
                           buttonTheme={[
                            {
                              class: "hg-red",
                              buttons: "Close"
                            },
                            {
                              class: "hg-highlight",
                              buttons:  "Close"
                            }
                          ]}
                            layout={{
                              default: [
                                "Close",
                                "1 2 3 4 5 6 7 8 9 0 - {bksp}",
                                "Q W E R T Y U I O P",
                                "A S D F G H J K L {enter}",
                                "Z X C V B N M {space}"
                              ],
                              shift: [
                                "Q W E R T Y U I O P - {bksp}",
                                'A S D F G H J K L {enter}',
                                "Z X C V B N M {shift}"
                              ]
                            }}
                          />
                          )}
                    </div>
                  {/* }*/}
          
        <hr />
                  
              
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    //next={this.refreshList}
                    //hasMore={this.state.hasMore}
                    //loader={<h4>.</h4>}
                    height={400}

                  >
                            
                                 
                       { this.state.items.map(i => (                          
                               <div >      
                                
                                <Link to='#' onClick={input => this.onItemTap(input,i.DistNumber ?  i.DistNumber : i.BinCode,i.WhsCode,i.BinCode,i.OnHandQty,i.DistNumber, 1)}>
                              
                              
                                <div className={i.DistNumber ?  `itemTap`+i.DistNumber : `itemTap`+i.BinCode} id={i.DistNumber ?  `itemTap`+i.DistNumber : `itemTap`+i.BinCode} style={i.status ?  scanned_items : scanned_pending}  key={i}   >                  
                                
                                {i.DistNumber && 
                                <div className="items-det" >
                                      <span className={`todo-title mr-2`} title={i}>{i.DistNumber}</span>                                     
                                </div>
                                  }                                  
                                  
                                  <div className="items-det" >
                                  <span className={`todo-title mr-2`} title={i}>{i.BinCode}</span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={`todo-title mr-2`} title={i}>
                                  {i.OnHandQty}                                  
                                  </span>                                 
                                  </div>                                 

                                  </div>
                                </Link> 
                                </div>
                                ))}      
                          
                        
                  </InfiniteScroll>
        <br />
                  <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to={this.state.setPrevPage} >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>

                    {localStorage.getItem('pageFrom') === 'goodsreceiptdetails' &&
                    <button onClick={() =>{this.saveBinLocQTY()}} >
                    <i className="fa fa-check fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[53]}                
                    </button>
                    
                    }
                </div>
            </div>

        
      </div>
      
    );

  }

}


export default StockReportQtyBinLoc;